import { NextUIProvider } from "@nextui-org/react";
import AlteracaoParceiroCambialPF from "pages/formularios/bancobs2/alteracao_parceiro_cambial/pf/AlteracaoParceiroCambialPF";
import AlteracaoParceiroCambialPJ from "pages/formularios/bancobs2/alteracao_parceiro_cambial/pj/AlteracaoParceiroCambialPJ";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import CadastroBS2PF from "./pages/cadastro/bancobs2/pf/CadastroBS2Pf";
import CadastroBS2PJ from "./pages/cadastro/bancobs2/pj/CadastroBS2Pj";
import DeclaracaoEndereco from "./pages/formularios/bancobs2/declaracao/pf/endereco/DeclaracaoEndereco";
import ManutencaoResidente from "./pages/formularios/bancobs2/declaracao/pf/manutencao_residente/ManutencaoResidente";
import KnowYourClientPF from "./pages/formularios/bancobs2/kyc/pf/KnowYourClientPF";
import KnowYourClientPJ from "./pages/formularios/bancobs2/kyc/pj/KnowYourClientPJ";
import TermoDoacao from "./pages/formularios/bancobs2/termo_doacao/pf/TermoDoacao";
import Home from "./pages/Home";

const RedirectToSuzanCambio = () => {
    React.useEffect(() => {
        window.location.href = "https://suzancambio.com/";
    }, []);

    return null;
};

const App = () => {
    return (
        <NextUIProvider>
            <Router>
                <Routes>
                    {/* Rota base para Home sem parâmetros */}
                    {/* <Route path="/" element={<Home />} /> */}
                    <Route path="/" element={<RedirectToSuzanCambio />} />
                    <Route path="/cadastro/bancobs2/pf" element={<CadastroBS2PF />} />
                    <Route path="/cadastro/bancobs2/pj" element={<CadastroBS2PJ />} />

                    {/* Forms */}
                    <Route
                        path="/formularios/bancobs2/declaracaomr"
                        element={<ManutencaoResidente />}
                    />
                    <Route
                        path="/formularios/bancobs2/declaracaoendereco"
                        element={<DeclaracaoEndereco />}
                    />
                    <Route path="/formularios/bancobs2/pf/kyc" element={<KnowYourClientPF />} />
                    <Route path="/formularios/bancobs2/pj/kyc" element={<KnowYourClientPJ />} />
                    <Route path="/formularios/bancobs2/termo-de-doacao" element={<TermoDoacao />} />
                    <Route
                        path="/formularios/bancobs2/pf/alteracao_parceiro_cambial"
                        element={<AlteracaoParceiroCambialPF />}
                    />
                    <Route
                        path="/formularios/bancobs2/pj/alteracao_parceiro_cambial"
                        element={<AlteracaoParceiroCambialPJ />}
                    />

                    {/* Rota para capturar /parceiro/:codigoParceiro e passar como prop para Home */}
                    <Route path="/parceiro/:codigoParceiro" element={<Home />} />
                </Routes>
            </Router>
        </NextUIProvider>
    );
};

export default App;
