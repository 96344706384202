import { Button } from "@nextui-org/react";
import Cookies from "js-cookie";
import { InputNumber } from "primereact/inputnumber";
import React, { useState } from "react";
import InputMask from "react-input-mask";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import { Loader } from "../../../../components/utils";
import { maskDate, toast } from "../../../../helpers";

import "./FormBS2Pf.css";

console.log("NODE_ENV:", process.env.NODE_ENV);

const defaultFormDataTeste = Cookies.get("formDataCadastroPf")
    ? JSON.parse(Cookies.get("formDataCadastroPf"))
    : {
          nome: "",
          cpf: "",
          datanasccliente: "",
          celular: "",
          email: "",
          nr_documento_identidade: "",
          data_expedicao_doc_ide: "",
          orgao_expedidor: "",
          naturalidade: "",
          nacionalidade: "",
          filiacao: "",
          genero_biologico: "",
          profissao: "",
          cep_residencial: "",
          logradouro_residencial: "",
          numero_residencial: "",
          complemento_residencial: "",
          bairro_residencial: "",
          cidade_residencial: "",
          estado_residencial: "",
          estado_civil: "",
          renda: "",
          nome_conjuge: "",
          cpf_conjuge: "",
          autoriza_terceiro: "",
          nome_pessoa_autorizada: "",
          cpf_pessoa_autorizada: "",
          banco: "",
          conta: "",
          agencia: "",
          telefone_agencia: "",
          ppe: "",
          ppe_cargo: "",
          ppe_orgao_publico: "",
          ppe_cnpj: "",
          data_ppe_inicio_exercicio: "",
          data_ppe_fim_exercicio: "",
          agente_relacionado: "",
          nome_agente_relacionado: "",
          cpf_agente_relacionado: "",
          cargo_agente_relacionado: "",
          tipo_relacao: "",
          pv_bs2: "",
          us_person: ""
      };

const FormBS2Pf = ({ setData, setError }) => {
    const [formErrors, setFormErrors] = useState({});
    const [loading, setLoading] = useState(false);

    //Form
    const [formData, setFormData] = useState(defaultFormDataTeste);

    const validateForm = () => {
        const errors = {};
        if (!formData.nome?.trim()) errors.nome = "Campo obrigatório!";
        if (!formData.cpf?.trim()) errors.cpf = "Campo obrigatório!";
        if (!formData.datanasccliente?.trim()) errors.datanasccliente = "Campo obrigatório!";
        if (!formData.celular?.trim()) errors.celular = "Campo obrigatório!";
        if (!formData.email?.trim()) errors.email = "Campo obrigatório!";
        if (!formData.nr_documento_identidade?.trim())
            errors.nr_documento_identidade = "Campo obrigatório!";
        if (!formData.data_expedicao_doc_ide?.trim())
            errors.data_expedicao_doc_ide = "Campo obrigatório!";
        if (!formData.orgao_expedidor?.trim()) errors.orgao_expedidor = "Campo obrigatório!";
        if (!formData.naturalidade?.trim()) errors.naturalidade = "Campo obrigatório!";
        if (!formData.nacionalidade?.trim()) errors.nacionalidade = "Campo obrigatório!";
        if (!formData.filiacao?.trim()) errors.filiacao = "Campo obrigatório!";
        if (!formData.genero_biologico?.trim()) errors.genero_biologico = "Campo obrigatório!";
        if (!formData.profissao?.trim()) errors.profissao = "Campo obrigatório!";
        if (!formData.cep_residencial?.trim()) errors.cep_residencial = "Campo obrigatório!";
        if (!formData.logradouro_residencial?.trim())
            errors.logradouro_residencial = "Campo obrigatório!";
        if (!formData.numero_residencial?.trim()) errors.numero_residencial = "Campo obrigatório!";
        if (!formData.bairro_residencial?.trim()) errors.bairro_residencial = "Campo obrigatório!";
        if (!formData.cidade_residencial?.trim()) errors.cidade_residencial = "Campo obrigatório!";
        if (!formData.estado_residencial?.trim()) errors.estado_residencial = "Campo obrigatório!";
        if (!formData.estado_civil?.trim()) errors.estado_civil = "Campo obrigatório!";
        if (!String(formData.renda).trim()) errors.renda = "Campo obrigatório!";
        if (isCasado) {
            if (!formData.nome_conjuge?.trim()) errors.nome_conjuge = "Campo obrigatório!";
            if (!formData.cpf_conjuge?.trim()) errors.cpf_conjuge = "Campo obrigatório!";
        }
        if (!formData.autoriza_terceiro?.trim()) errors.autoriza_terceiro = "Campo obrigatório!";
        if (isAutorizaTerceiro) {
            if (!formData.nome_pessoa_autorizada?.trim())
                errors.nome_pessoa_autorizada = "Campo obrigatório!";
            if (!formData.cpf_pessoa_autorizada?.trim())
                errors.cpf_pessoa_autorizada = "Campo obrigatório!";
        }
        if (!formData.banco?.trim()) errors.banco = "Campo obrigatório!";
        if (!formData.conta?.trim()) errors.conta = "Campo obrigatório!";
        if (!formData.agencia?.trim()) errors.agencia = "Campo obrigatório!";
        if (!formData.ppe?.trim()) errors.ppe = "Campo obrigatório!";
        if (isPpe) {
            if (!formData.ppe_cargo?.trim()) errors.ppe_cargo = "Campo obrigatório!";
            if (!formData.ppe_orgao_publico?.trim())
                errors.ppe_orgao_publico = "Campo obrigatório!";
            if (!formData.ppe_cnpj?.trim()) errors.ppe_cnpj = "Campo obrigatório!";
            if (!formData.data_ppe_inicio_exercicio?.trim())
                errors.data_ppe_inicio_exercicio = "Campo obrigatório!";
            if (!formData.data_ppe_fim_exercicio?.trim())
                errors.data_ppe_fim_exercicio = "Campo obrigatório!";
        }
        if (!formData.agente_relacionado?.trim()) errors.agente_relacionado = "Campo obrigatório!";
        if (isAgenteRelacionado) {
            if (!formData.nome_agente_relacionado?.trim())
                errors.nome_agente_relacionado = "Campo obrigatório!";
            if (!formData.cpf_agente_relacionado?.trim())
                errors.cpf_agente_relacionado = "Campo obrigatório!";
            if (!formData.cargo_agente_relacionado?.trim())
                errors.cargo_agente_relacionado = "Campo obrigatório!";
            if (!formData.tipo_relacao?.trim()) errors.tipo_relacao = "Campo obrigatório!";
        }
        if (!formData.pv_bs2?.trim()) errors.pv_bs2 = "Campo obrigatório!";
        if (!formData.us_person?.trim()) errors.us_person = "Campo obrigatório!";
        return errors;
    };

    const handleChangePhoneInput = e => {
        setFormData({
            ...formData,
            ["celular"]: e
        });
        const errors = validateForm();
        setFormErrors(errors);
    };

    const isCasado =
        formData.estado_civil === "casado" || formData.estado_civil === "uniao_estavel";
    const isAutorizaTerceiro = formData.autoriza_terceiro === "sim";
    const isPpe = formData.ppe === "sim";
    const isAgenteRelacionado = formData.agente_relacionado === "sim";

    const handleChange = e => {
        const name = e.target.name;
        const value = e.target.value;
        if (name.startsWith("renda") && !value) return "";
        if (name.startsWith("data")) {
            setFormData({
                ...formData,
                [name]: maskDate(value)
            });
            const errors = validateForm();
            setFormErrors(errors);
        } else if (name == "estado_civil") {
            setFormData({
                ...formData,
                [name]: value
            });
            const errors = validateForm();
            setFormErrors(errors);
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
            const errors = validateForm();
            setFormErrors(errors);
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        const errors = validateForm();
        setLoading(true);
        if (Object.keys(errors).length === 0) {
            const userToken = process.env.REACT_APP_API_TOKEN;
            const url = `${process.env.REACT_APP_API_HOST}/cadastro/bs2/pf`;

            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${userToken}`
                    },
                    body: JSON.stringify(formData),
                    credentials: "include"
                });

                if (response.status === 200) {
                    const jsonData = await response.json();
                    setData(jsonData);

                    Cookies.set("fileExistsCadastroPF", JSON.stringify(jsonData), { expires: 7 });
                    Cookies.set("formDataCadastroPf", JSON.stringify(formData), { expires: 30 });

                    setError(null);
                } else {
                    throw new Error(`Error: ${response.status} ${response.statusText}`);
                }
            } catch (error) {
                console.error("Erro ao fazer a requisição:", error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        } else {
            setFormErrors(errors);
            setLoading(false);
            console.log(errors);
            if (errors) {
                toast("error", "Erro ao enviar resposta\nPreencha os campos obrigatórios.");
            } else {
                toast(
                    "error",
                    "Erro ao enviar resposta\nDúvidas? Entre em contato no WhatsApp +55 11 93211-4516",
                    true
                );
            }
        }
    };

    const Required = () => {
        return <span className="text-red-500 font-bold">*</span>;
    };

    const handleCepChange = async e => {
        const novoCep = e.target.value;
        setFormData({
            ...formData,
            ["cep_residencial"]: novoCep
        });

        if (novoCep.length === 8) {
            try {
                const response = await fetch(`https://viacep.com.br/ws/${novoCep}/json/`);
                const data = await response.json();

                if (!data.erro) {
                    setFormData({
                        ...formData,
                        ["logradouro_residencial"]: data.logradouro,
                        ["bairro_residencial"]: data.bairro,
                        ["cidade_residencial"]: data.localidade,
                        ["estado_residencial"]: data.uf
                    });
                    document.getElementsByName("numero_residencial")[0].focus();
                } else {
                    alert("CEP não encontrado.");
                }
            } catch (error) {
                console.error("Erro ao buscar o CEP:", error);
            }
        }
    };
    return (
        <form
            onSubmit={handleSubmit}
            className="bg-white p-8 rounded-lg shadow-lg w-full md:min-w-[400px] md:max-w-3xl mx-auto"
            autoComplete="off"
        >
            <p className="text-gray-500 mb-8 text-center">
                O Banco BS2 é a instituição financeira parceira da SuzanCâmbio na realização de
                transferências internacionais. Por isso, após o preenchimento deste formulário você
                receberá um e-mail com a Ficha Cadastral do BS2 devidamente preenchida para
                conferência e assinatura digital.
                <br />
                Todos os dados informados neste formulário serão mantidos em total sigilo pela
                SuzanCâmbio e BS2, seguindo rigorosamente as diretrizes da lei de proteção geral de
                dados - LGPD.
            </p>
            <h2 className="text-2xl font-bold mb-6 text-gray-800 text-center">
                Cadastro Pessoa Física
            </h2>
            <div className="mb-4">
                <label htmlFor="nome" className="block text-gray-700">
                    Nome completo <Required />
                </label>
                <input
                    id="nome"
                    name="nome"
                    type="text"
                    value={formData.nome}
                    onChange={handleChange}
                    autoComplete="nope"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.nome ? "border-red-500" : ""}`}
                />
                {formErrors.nome && <p className="text-red-500 text-xs mt-1">{formErrors.nome}</p>}
            </div>
            <div className="mb-4 w-full">
                <label htmlFor="cpf" className="block text-gray-700">
                    CPF <Required />
                </label>
                <InputMask
                    mask={"999.999.999-99"}
                    value={formData.cpf}
                    onChange={handleChange}
                    autoComplete="nope"
                    id="cpf"
                    name="cpf"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.cpf ? "border-red-500" : ""}`}
                >
                    {inputProps => <input {...inputProps} type="tel" />}
                </InputMask>
                {formErrors.cpf && <p className="text-red-500 text-xs mt-1">{formErrors.cpf}</p>}
            </div>
            <div className="flex flex-cols justify-left relative z-0 w-full mb-5">
                <div className="relative z-0 w-full group">
                    <label htmlFor="datanasccliente" className="block text-gray-700">
                        Data de Nascimento: <Required />
                    </label>
                    <InputMask
                        mask={"99/99/9999"}
                        placeholder="dd/mm/aaaa"
                        maskChar={null}
                        value={formData.datanasccliente}
                        onChange={handleChange}
                        autoComplete="nope"
                        id="datanasccliente"
                        name="datanasccliente"
                        className={`mt-2 p-2 w-full border rounded-md ${formErrors.datanasccliente ? "border-red-500" : ""}`}
                    >
                        {inputProps => (
                            <input {...inputProps} type="tel" placeholder="dd/mm/aaaa" />
                        )}
                    </InputMask>
                    {formErrors.datanasccliente && (
                        <p className="text-red-500 text-xs mt-1">{formErrors.datanasccliente}</p>
                    )}
                </div>
            </div>
            <div className="mb-6">
                <label htmlFor="genero_biologico" className="block text-gray-700">
                    Gênero biológico: <Required />
                </label>
                <fieldset className="sm:flex sm:flex-rows space-x-4 mt-4">
                    <legend className="sr-only">genero_biologico</legend>
                    <div className="flex items-center mb-4">
                        <input
                            id="masculino"
                            type="radio"
                            name="genero_biologico"
                            value="masculino"
                            className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                            checked={formData.genero_biologico === "masculino"}
                            onChange={handleChange}
                            autoComplete="nope"
                        />
                        <label
                            htmlFor="masculino"
                            className="block ms-2  text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            Masculino
                        </label>
                    </div>
                    <div className="flex items-center mb-4">
                        <input
                            id="feminino"
                            type="radio"
                            name="genero_biologico"
                            value="feminino"
                            className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                            checked={formData.genero_biologico === "feminino"}
                            onChange={handleChange}
                            autoComplete="nope"
                        />
                        <label
                            htmlFor="feminino"
                            className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            Feminino
                        </label>
                    </div>
                </fieldset>
                {formErrors.genero_biologico && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.genero_biologico}</p>
                )}
            </div>
            <div className="mb-4">
                <label htmlFor="celular" className="text-sm font-medium text-blue-900">
                    Celular: <Required />
                </label>
                <PhoneInput
                    international={true}
                    countryCallingCodeEditable={true}
                    defaultCountry="BR"
                    value={formData.celular}
                    onChange={handleChangePhoneInput}
                    autoComplete="nope"
                    error={
                        formData.celular
                            ? isValidPhoneNumber(formData.celular)
                                ? undefined
                                : "Telefone inválido"
                            : "Número de celular obrigatório"
                    }
                    id="celular"
                    name="celular"
                    className="rounded-md"
                    limitMaxLength
                />

                {formErrors.celular && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.celular}</p>
                )}
            </div>
            <div className="mb-6">
                <label htmlFor="email" className="block text-gray-700">
                    Email: <Required />
                </label>
                <input
                    id="email"
                    name="email"
                    type="text"
                    value={formData.email}
                    onChange={handleChange}
                    autoComplete="nope"
                    placeholder=" "
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.email ? "border-red-500" : ""}`}
                />
                {formErrors.email && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.email}</p>
                )}
            </div>
            <div className="mb-6">
                <label htmlFor="nr_documento_identidade" className="block text-gray-700">
                    Número do documento de identidade: <Required />
                </label>
                <input
                    id="nr_documento_identidade"
                    name="nr_documento_identidade"
                    type="text"
                    value={formData.nr_documento_identidade}
                    onChange={handleChange}
                    autoComplete="nope"
                    placeholder=" "
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.nr_documento_identidade ? "border-red-500" : ""}`}
                />
                {formErrors.nr_documento_identidade && (
                    <p className="text-red-500 text-xs mt-1">
                        {formErrors.nr_documento_identidade}
                    </p>
                )}
            </div>
            <div className="mb-6">
                <div className="relative z-0 group">
                    <label htmlFor="data_expedicao_doc_ide" className="block text-gray-700">
                        Data de Expedição do documento de identidade: <Required />
                    </label>
                    <input
                        id={"data_expedicao_doc_ide"}
                        name={"data_expedicao_doc_ide"}
                        type="text"
                        value={formData.data_expedicao_doc_ide}
                        onChange={handleChange}
                        autoComplete="nope"
                        className={`mt-2 p-2 w-full border rounded-md ${formErrors.data_expedicao_doc_ide ? "border-red-500" : ""}`}
                        placeholder="dd/mm/aaaa"
                        required
                    />
                    {formErrors.data_expedicao_doc_ide && (
                        <p className="text-red-500 text-xs mt-1">
                            {formErrors.data_expedicao_doc_ide}
                        </p>
                    )}
                </div>
            </div>
            <div className="mb-6">
                <label htmlFor="orgao_expedidor" className="block text-gray-700">
                    Orgão Expedidor: <Required />
                </label>
                <input
                    id="orgao_expedidor"
                    name="orgao_expedidor"
                    type="text"
                    value={formData.orgao_expedidor}
                    onChange={handleChange}
                    autoComplete="nope"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.orgao_expedidor ? "border-red-500" : ""}`}
                />
                {formErrors.orgao_expedidor && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.orgao_expedidor}</p>
                )}
            </div>
            <div className="mb-6">
                <label htmlFor="naturalidade" className="block text-gray-700">
                    Naturalidade: <Required />{" "}
                    <span className="text-sm">{"(Cidade de Nascimento)"}</span>
                </label>
                <input
                    type="text"
                    name="naturalidade"
                    value={formData.naturalidade}
                    onChange={handleChange}
                    autoComplete="nope"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.naturalidade ? "border-red-500" : ""}`}
                />
                {formErrors.naturalidade && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.naturalidade}</p>
                )}
            </div>
            <div className="mb-6">
                <label htmlFor="nacionalidade" className="block text-gray-700">
                    Nacionalidade: <Required />
                </label>
                <fieldset className="sm:flex sm:flex-rows space-x-4 mt-4">
                    <legend className="sr-only">Nacionalidade</legend>
                    <div className="flex items-center mb-4">
                        <input
                            id="brasileiro"
                            type="radio"
                            name="nacionalidade"
                            value="brasileiro"
                            className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                            checked={formData.nacionalidade === "brasileiro"}
                            onChange={handleChange}
                            autoComplete="nope"
                        />
                        <label
                            htmlFor="brasileiro"
                            className="block ms-2  text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            Brasileiro
                        </label>
                    </div>
                    <div className="flex items-center mb-4">
                        <input
                            id="estrangeiro"
                            type="radio"
                            name="nacionalidade"
                            value="estrangeiro"
                            className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                            checked={formData.nacionalidade === "estrangeiro"}
                            onChange={handleChange}
                            autoComplete="nope"
                        />
                        <label
                            htmlFor="estrangeiro"
                            className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            Estrangeiro
                        </label>
                    </div>
                </fieldset>
                {formErrors.nacionalidade && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.nacionalidade}</p>
                )}
            </div>
            <div className="mb-6">
                <label htmlFor="filiacao" className="block text-gray-700">
                    Filiação: <Required />
                </label>
                <input
                    type="text"
                    name="filiacao"
                    value={formData.filiacao}
                    onChange={handleChange}
                    autoComplete="nope"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.filiacao ? "border-red-500" : ""}`}
                />
                {formErrors.filiacao && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.filiacao}</p>
                )}
            </div>
            <div className="mb-6">
                <label htmlFor="profissao" className="block text-gray-700">
                    Profissão: <Required />
                </label>
                <input
                    type="text"
                    name="profissao"
                    value={formData.profissao}
                    onChange={handleChange}
                    autoComplete="nope"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.profissao ? "border-red-500" : ""}`}
                />
                {formErrors.profissao && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.profissao}</p>
                )}
            </div>
            <div className="mb-6">
                <label htmlFor="renda" className="block text-gray-700">
                    Renda: <Required />
                </label>
                <InputNumber
                    id="renda"
                    value={formData.renda}
                    name="renda"
                    onValueChange={handleChange}
                    inputMode="text"
                    type="text"
                    autoComplete="nope"
                    locale="pt-BR"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    className="w-full"
                    inputClassName={`mt-2 p-2 w-full border rounded-md ${formErrors.renda ? "border-red-500" : ""}`}
                />
                {formErrors.renda && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.renda}</p>
                )}
            </div>
            <div className="mb-6">
                <label htmlFor="cep_residencial" className="block text-gray-700">
                    CEP: <Required />
                </label>
                <InputMask
                    mask={"99999999"}
                    maskChar={null}
                    value={formData.cep_residencial}
                    onChange={handleCepChange}
                    onBlur={handleCepChange}
                    id="cep_residencial"
                    name="cep_residencial"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.cep_residencial ? "border-red-500" : ""}`}
                >
                    {inputProps => <input {...inputProps} type="tel" />}
                </InputMask>
                {formErrors.cep_residencial && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.cep_residencial}</p>
                )}
            </div>
            <div className="mb-6">
                <label htmlFor="logradouro_residencial" className="block text-gray-700">
                    Logradouro residencial: <Required />
                </label>
                <input
                    type="text"
                    name="logradouro_residencial"
                    value={formData.logradouro_residencial}
                    onChange={handleChange}
                    autoComplete="nope"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.logradouro_residencial ? "border-red-500" : ""}`}
                />
                {formErrors.logradouro_residencial && (
                    <p className="text-red-500 text-xs mt-1 mb-2">
                        {formErrors.logradouro_residencial}
                    </p>
                )}
                <label htmlFor="numero_residencial" className="block text-gray-700">
                    Número residencial: <Required />
                </label>
                <input
                    type="text"
                    name="numero_residencial"
                    value={formData.numero_residencial}
                    onChange={handleChange}
                    autoComplete="nope"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.numero_residencial ? "border-red-500" : ""}`}
                />
                {formErrors.numero_residencial && (
                    <p className="text-red-500 text-xs mt-1 mb-2">
                        {formErrors.numero_residencial}
                    </p>
                )}
                <label htmlFor="complemento_residencial" className="block text-gray-700">
                    Complemento residencial:
                </label>
                <input
                    type="text"
                    name="complemento_residencial"
                    value={formData.complemento_residencial}
                    onChange={handleChange}
                    autoComplete="nope"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.complemento_residencial ? "border-red-500" : ""}`}
                />
                {formErrors.complemento_residencial && (
                    <p className="text-red-500 text-xs mt-1 mb-2">
                        {formErrors.complemento_residencial}
                    </p>
                )}
                <label htmlFor="bairro_residencial" className="block text-gray-700">
                    Bairro residencial: <Required />
                </label>
                <input
                    type="text"
                    name="bairro_residencial"
                    value={formData.bairro_residencial}
                    onChange={handleChange}
                    autoComplete="nope"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.bairro_residencial ? "border-red-500" : ""}`}
                />
                {formErrors.bairro_residencial && (
                    <p className="text-red-500 text-xs mt-1 mb-2">
                        {formErrors.bairro_residencial}
                    </p>
                )}
                <label htmlFor="cidade_residencial" className="block text-gray-700">
                    Cidade residencial: <Required />
                </label>
                <input
                    type="text"
                    name="cidade_residencial"
                    value={formData.cidade_residencial}
                    onChange={handleChange}
                    autoComplete="nope"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.cidade_residencial ? "border-red-500" : ""}`}
                />
                {formErrors.cidade_residencial && (
                    <p className="text-red-500 text-xs mt-1 mb-2">
                        {formErrors.cidade_residencial}
                    </p>
                )}
                <label htmlFor="estado_residencial" className="block text-gray-700">
                    Estado (UF) residencial: <Required />
                </label>
                <input
                    type="text"
                    name="estado_residencial"
                    value={formData.estado_residencial}
                    onChange={handleChange}
                    autoComplete="nope"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.estado_residencial ? "border-red-500" : ""}`}
                />
                {formErrors.estado_residencial && (
                    <p className="text-red-500 text-xs mt-1 mb-2">
                        {formErrors.estado_residencial}
                    </p>
                )}
            </div>
            <div className="mb-6">
                <label htmlFor="estado_civil" className="block text-gray-700">
                    Estado Civil: <Required />
                </label>
                <fieldset className="sm:flex sm:flex-rows space-x-4 mt-4">
                    <legend className="sr-only">Estado Civil</legend>
                    <div className="flex items-center mb-4">
                        <input
                            id="solteiro"
                            type="radio"
                            name="estado_civil"
                            value="solteiro"
                            className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                            checked={formData.estado_civil === "solteiro"}
                            onChange={handleChange}
                            autoComplete="nope"
                        />
                        <label
                            htmlFor="solteiro"
                            className="block ms-2  text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            Solteiro
                        </label>
                    </div>
                    <div className="flex items-center mb-4">
                        <input
                            id="casado"
                            type="radio"
                            name="estado_civil"
                            value="casado"
                            className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                            checked={formData.estado_civil === "casado"}
                            onChange={handleChange}
                            autoComplete="nope"
                        />
                        <label
                            htmlFor="casado"
                            className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            Casado
                        </label>
                    </div>
                    <div className="flex items-center mb-4">
                        <input
                            id="desquitado"
                            type="radio"
                            name="estado_civil"
                            value="desquitado"
                            className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                            checked={formData.estado_civil === "desquitado"}
                            onChange={handleChange}
                            autoComplete="nope"
                        />
                        <label
                            htmlFor="desquitado"
                            className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            Desquitado
                        </label>
                    </div>
                    <div className="flex items-center mb-4">
                        <input
                            id="divorciado"
                            type="radio"
                            name="estado_civil"
                            value="divorciado"
                            className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                            checked={formData.estado_civil === "divorciado"}
                            onChange={handleChange}
                            autoComplete="nope"
                        />
                        <label
                            htmlFor="divorciado"
                            className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            Divorciado
                        </label>
                    </div>
                    <div className="flex items-center mb-4">
                        <input
                            id="viuvo"
                            type="radio"
                            name="estado_civil"
                            value="viuvo"
                            className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                            checked={formData.estado_civil === "viuvo"}
                            onChange={handleChange}
                            autoComplete="nope"
                        />
                        <label
                            htmlFor="viuvo"
                            className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            Viúvo
                        </label>
                    </div>
                    <div className="flex items-center mb-4">
                        <input
                            id="uniao_estavel"
                            type="radio"
                            name="estado_civil"
                            value="uniao_estavel"
                            className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                            checked={formData.estado_civil === "uniao_estavel"}
                            onChange={handleChange}
                            autoComplete="nope"
                        />
                        <label
                            htmlFor="uniao_estavel"
                            className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            União Estável
                        </label>
                    </div>
                </fieldset>
                {formErrors.estado_civil && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.estado_civil}</p>
                )}
            </div>
            {isCasado && (
                <div id="dados_conjuge" className="mb-4">
                    <div className="mb-4">
                        <label htmlFor="nome_conjuge" className="block text-gray-700">
                            Nome do Cônjuge: <Required />
                        </label>
                        <input
                            type="text"
                            name="nome_conjuge"
                            value={formData.nome_conjuge}
                            onChange={handleChange}
                            autoComplete="nope"
                            className={`mt-2 p-2 w-full border rounded-md ${formErrors.nome_conjuge ? "border-red-500" : ""}`}
                        />
                        {formErrors.nome_conjuge && (
                            <p className="text-red-500 text-xs mt-1">{formErrors.nome_conjuge}</p>
                        )}
                    </div>
                    <div className="mb-4 w-full">
                        <label htmlFor="cpf_conjuge" className="block text-gray-700">
                            CPF do Cônjuge: <Required />
                        </label>
                        <InputMask
                            mask={"999.999.999-99"}
                            value={formData.cpf_conjuge}
                            onChange={handleChange}
                            autoComplete="nope"
                            id="cpf_conjuge"
                            name="cpf_conjuge"
                            className={`mt-2 p-2 w-full border rounded-md ${formErrors.cpf_conjuge ? "border-red-500" : ""}`}
                        >
                            {inputProps => <input {...inputProps} type="tel" />}
                        </InputMask>
                        {formErrors.cpf_conjuge && (
                            <p className="text-red-500 text-xs mt-1">{formErrors.cpf_conjuge}</p>
                        )}
                    </div>
                </div>
            )}
            <hr className="mt-8 mb-8" />
            <div className="mb-6">
                <label
                    htmlFor="autoriza_terceiro"
                    className="block text-gray-700 text-lg font-medium"
                >
                    Você autoriza algum terceiro a emitir ordens em seu nome? <Required />
                </label>
                <fieldset className="sm:flex sm:flex-rows space-x-4 mt-4">
                    <legend className="sr-only">Estado Civil</legend>
                    <div className="flex items-center mb-4">
                        <input
                            id="autoriza_sim"
                            type="radio"
                            name="autoriza_terceiro"
                            value="sim"
                            className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                            checked={formData.autoriza_terceiro === "sim"}
                            onChange={handleChange}
                            autoComplete="nope"
                        />
                        <label
                            htmlFor="autoriza_sim"
                            className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            Sim, autorizo terceiros a emitir ordens em meu nome.
                        </label>
                    </div>
                    <div className="flex items-center mb-4">
                        <input
                            id="autoriza_nao"
                            type="radio"
                            name="autoriza_terceiro"
                            value="nao"
                            className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                            checked={formData.autoriza_terceiro === "nao"}
                            onChange={handleChange}
                            autoComplete="nope"
                        />
                        <label
                            htmlFor="autoriza_nao"
                            className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            Não, eu não autorizo terceiros a emitir ordens em meu nome.
                        </label>
                    </div>
                </fieldset>

                {formErrors.autoriza_terceiro && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.autoriza_terceiro}</p>
                )}
                {isAutorizaTerceiro && (
                    <>
                        <div className="mb-4">
                            <label htmlFor="nome_pessoa_autorizada" className="block text-gray-700">
                                Nome da pessoa autorizada: <Required />
                            </label>
                            <input
                                type="text"
                                name="nome_pessoa_autorizada"
                                value={formData.nome_pessoa_autorizada}
                                onChange={handleChange}
                                autoComplete="nope"
                                className={`mt-2 p-2 w-full border rounded-md ${formErrors.nome_pessoa_autorizada ? "border-red-500" : ""}`}
                            />
                            {formErrors.nome_pessoa_autorizada && (
                                <p className="text-red-500 text-xs mt-1">
                                    {formErrors.nome_pessoa_autorizada}
                                </p>
                            )}
                        </div>
                        <div className="mb-4">
                            <label htmlFor="cpf_pessoa_autorizada" className="block text-gray-700">
                                CPF da pessoa autorizada: <Required />
                            </label>
                            <InputMask
                                mask={"999.999.999-99"}
                                value={formData.cpf_pessoa_autorizada}
                                onChange={handleChange}
                                autoComplete="nope"
                                id="cpf_pessoa_autorizada"
                                name="cpf_pessoa_autorizada"
                                className={`mt-2 p-2 w-full border rounded-md ${formErrors.cpf_pessoa_autorizada ? "border-red-500" : ""}`}
                            >
                                {inputProps => <input {...inputProps} type="tel" />}
                            </InputMask>
                            {formErrors.cpf_pessoa_autorizada && (
                                <p className="text-red-500 text-xs mt-1">
                                    {formErrors.cpf_pessoa_autorizada}
                                </p>
                            )}
                        </div>
                    </>
                )}
            </div>
            <hr className="mt-8 mb-2" />
            <hr className="mt-2 mb-8" />
            <div className="mb-4 space-y-2">
                <label htmlFor="banco" className="block text-gray-700">
                    Banco: <Required />
                </label>
                <input
                    type="text"
                    name="banco"
                    value={formData.banco}
                    onChange={handleChange}
                    autoComplete="nope"
                    required
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.banco ? "border-red-500" : ""}`}
                />
                {formErrors.banco && (
                    <p className="text-red-500 text-xs mt-1 mb-2">{formErrors.banco}</p>
                )}
                <label htmlFor="conta" className="block text-gray-700">
                    Conta: <Required />
                </label>
                <input
                    type="text"
                    name="conta"
                    value={formData.conta}
                    onChange={handleChange}
                    autoComplete="nope"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.conta ? "border-red-500" : ""}`}
                />
                {formErrors.conta && (
                    <p className="text-red-500 text-xs mt-1 mb-2">{formErrors.conta}</p>
                )}
                <label htmlFor="agencia" className="block text-gray-700">
                    Agência: <Required />
                </label>
                <input
                    type="text"
                    name="agencia"
                    value={formData.agencia}
                    onChange={handleChange}
                    autoComplete="nope"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.agencia ? "border-red-500" : ""}`}
                />
                {formErrors.agencia && (
                    <p className="text-red-500 text-xs mt-1 mb-2">{formErrors.agencia}</p>
                )}
                <label htmlFor="telefone_agencia" className="block text-gray-700">
                    Telefone da Agência:
                </label>
                <PhoneInput
                    international={true}
                    countryCallingCodeEditable={true}
                    defaultCountry="BR"
                    value={formData.telefone_agencia}
                    onChange={handleChangePhoneInput}
                    autoComplete="nope"
                    className="p-0 w-full bg-blue-50 border-0 border-b-2 text-blue-800 placeholder-blue-900 placeholder-opacity-25 font-bold text-sm lg:text-lg focus:ring-0"
                    id="telefone_agencia"
                    name="telefone_agencia"
                />
                {formErrors.telefone_agencia && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.telefone_agencia}</p>
                )}
            </div>
            <hr className="mt-8 mb-8" />
            <div className="mb-6">
                <label htmlFor="ppe" className="block text-gray-700">
                    <span className="text-xl font-medium">Pessoas politicamente expostas:</span>{" "}
                    <Required />
                    <p className="mt-4 text-gray-500 mb-4">
                        Conforme estabelece a Circular 3.461 do Banco Central do Brasil, de 24 de
                        julho de 2009, as Instituições Financeiras devem identificar pessoas que
                        desempenham ou tenham desempenhado, nos últimos 5 (cinco) anos, no Brasil ou
                        em outros países, cargos, empregos ou funções públicas relevantes, assim
                        como seus representantes, familiares e pessoas de seu relacionamento.
                    </p>
                    <span className="font-medium">
                        Exerce ou exerceu nos últimos cinco anos, algum cargo, emprego ou função
                        pública relevante?
                    </span>{" "}
                </label>

                <fieldset className="sm:flex sm:flex-rows space-x-4 mt-4">
                    <legend className="sr-only">Pessoas Politicamente expostas</legend>
                    <div className="flex items-center mb-4">
                        <input
                            id="ppe_sim"
                            type="radio"
                            name="ppe"
                            value="sim"
                            className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                            checked={formData.ppe === "sim"}
                            onChange={handleChange}
                            autoComplete="nope"
                        />
                        <label
                            htmlFor="ppe_sim"
                            className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            SIM, sou "PPE".
                        </label>
                    </div>
                    <div className="flex items-center mb-4">
                        <input
                            id="ppe_nao"
                            type="radio"
                            name="ppe"
                            value="nao"
                            className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                            checked={formData.ppe === "nao"}
                            onChange={handleChange}
                            autoComplete="nope"
                        />
                        <label
                            htmlFor="ppe_nao"
                            className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            NÃO, eu não sou "PPE"
                        </label>
                    </div>
                </fieldset>

                {formErrors.ppe && <p className="text-red-500 text-xs mt-1">{formErrors.ppe}</p>}

                {isPpe && (
                    <div className="space-y-2">
                        <label htmlFor="ppe_cargo" className="block text-gray-700">
                            Cargo ou Função: <Required />
                        </label>
                        <input
                            id="ppe_cargo"
                            name="ppe_cargo"
                            type="text"
                            value={formData.ppe_cargo}
                            onChange={handleChange}
                            autoComplete="nope"
                            className={`mt-2 p-2 w-full border rounded-md ${formErrors.ppe_cargo ? "border-red-500" : ""}`}
                        />
                        {formErrors.ppe_cargo && (
                            <p className="text-red-500 text-xs mt-1">{formErrors.ppe_cargo}</p>
                        )}
                        <label htmlFor="ppe_orgao_publico" className="block text-gray-700">
                            Órgão Público: <Required />
                        </label>
                        <input
                            id="ppe_orgao_publico"
                            name="ppe_orgao_publico"
                            type="text"
                            value={formData.ppe_orgao_publico}
                            onChange={handleChange}
                            autoComplete="nope"
                            className={`mt-2 p-2 w-full border rounded-md ${formErrors.ppe_orgao_publico ? "border-red-500" : ""}`}
                        />
                        {formErrors.ppe_orgao_publico && (
                            <p className="text-red-500 text-xs mt-1">
                                {formErrors.ppe_orgao_publico}
                            </p>
                        )}
                        <label htmlFor="ppe_cnpj" className="block text-gray-700">
                            CNPJ: <Required />
                        </label>
                        <InputMask
                            mask={"99.999.999/9999-99"}
                            value={formData.ppe_cnpj}
                            onChange={handleChange}
                            autoComplete="nope"
                            id="ppe_cnpj"
                            name="ppe_cnpj"
                            className={`mt-2 p-2 w-full border rounded-md ${formErrors.ppe_cnpj ? "border-red-500" : ""}`}
                        >
                            {inputProps => <input {...inputProps} type="tel" />}
                        </InputMask>
                        {formErrors.ppe_cnpj && (
                            <p className="text-red-500 text-xs mt-1">{formErrors.ppe_cnpj}</p>
                        )}
                        <label htmlFor="data_ppe_inicio_exercicio" className="block text-gray-700">
                            Data de Início do exercício: <Required />
                        </label>
                        <InputMask
                            mask={"99/99/9999"}
                            placeholder="dd/mm/aaaa"
                            maskChar={null}
                            value={formData.data_ppe_inicio_exercicio}
                            onChange={handleChange}
                            autoComplete="nope"
                            id="data_ppe_inicio_exercicio"
                            name="data_ppe_inicio_exercicio"
                            className={`mt-2 p-2 w-full border rounded-md ${formErrors.data_ppe_inicio_exercicio ? "border-red-500" : ""}`}
                        >
                            {inputProps => (
                                <input {...inputProps} type="tel" placeholder="dd/mm/aaaa" />
                            )}
                        </InputMask>
                        {formErrors.data_ppe_inicio_exercicio && (
                            <p className="text-red-500 text-xs mt-1">
                                {formErrors.data_ppe_inicio_exercicio}
                            </p>
                        )}
                        <label htmlFor="data_ppe_fim_exercicio" className="block text-gray-700">
                            Data de Fim do exercício: <Required />
                        </label>
                        <InputMask
                            mask={"99/99/9999"}
                            placeholder="dd/mm/aaaa"
                            maskChar={null}
                            value={formData.data_ppe_fim_exercicio}
                            onChange={handleChange}
                            autoComplete="nope"
                            id="data_ppe_fim_exercicio"
                            name="data_ppe_fim_exercicio"
                            className={`mt-2 p-2 w-full border rounded-md ${formErrors.data_ppe_fim_exercicio ? "border-red-500" : ""}`}
                        >
                            {inputProps => (
                                <input {...inputProps} type="tel" placeholder="dd/mm/aaaa" />
                            )}
                        </InputMask>
                        {formErrors.data_ppe_fim_exercicio && (
                            <p className="text-red-500 text-xs mt-1">
                                {formErrors.data_ppe_fim_exercicio}
                            </p>
                        )}
                    </div>
                )}
            </div>
            <hr className="mt-8 mb-8" />
            <div className="mb-6">
                <label htmlFor="agente_relacionado" className="block text-gray-700">
                    <span className="text-xl font-medium">
                        Possui relacionamento/ligação com Agente Público?
                    </span>{" "}
                    <Required />
                </label>

                <fieldset className="sm:flex sm:flex-rows space-x-4 mt-4">
                    <legend className="sr-only">Agente público</legend>
                    <div className="flex items-center mb-4">
                        <input
                            id="agente_relacionado_sim"
                            type="radio"
                            name="agente_relacionado"
                            value="sim"
                            className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                            checked={formData.agente_relacionado === "sim"}
                            onChange={handleChange}
                            autoComplete="nope"
                        />
                        <label
                            htmlFor="agente_relacionado_sim"
                            className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            SIM, possuo ligação com Agente Público.
                        </label>
                    </div>
                    <div className="flex items-center mb-4">
                        <input
                            id="agente_relacionado_nao"
                            type="radio"
                            name="agente_relacionado"
                            value="nao"
                            className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                            checked={formData.agente_relacionado === "nao"}
                            onChange={handleChange}
                            autoComplete="nope"
                        />
                        <label
                            htmlFor="agente_relacionado_nao"
                            className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            NÃO, eu não possuo ligação com Agente Público.
                        </label>
                    </div>
                </fieldset>

                {formErrors.agente_relacionado && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.agente_relacionado}</p>
                )}

                {isAgenteRelacionado && (
                    <div className="space-y-2">
                        <label htmlFor="nome_agente_relacionado" className="block text-gray-700">
                            Nome do Relacionado: <Required />
                        </label>
                        <input
                            type="text"
                            name="nome_agente_relacionado"
                            value={formData.nome_agente_relacionado}
                            onChange={handleChange}
                            autoComplete="nope"
                            className={`mt-2 p-2 w-full border rounded-md ${formErrors.nome_agente_relacionado ? "border-red-500" : ""}`}
                        />
                        {formErrors.nome_agente_relacionado && (
                            <p className="text-red-500 text-xs mt-1">
                                {formErrors.nome_agente_relacionado}
                            </p>
                        )}
                        <label htmlFor="cpf_agente_relacionado" className="block text-gray-700">
                            CPF do Relacionado: <Required />
                        </label>
                        <InputMask
                            mask={"999.999.999-99"}
                            value={formData.cpf_agente_relacionado}
                            onChange={handleChange}
                            autoComplete="nope"
                            id="cpf_agente_relacionado"
                            name="cpf_agente_relacionado"
                            className={`mt-2 p-2 w-full border rounded-md ${formErrors.cpf_agente_relacionado ? "border-red-500" : ""}`}
                        >
                            {inputProps => <input {...inputProps} type="tel" />}
                        </InputMask>
                        {formErrors.cpf_agente_relacionado && (
                            <p className="text-red-500 text-xs mt-1">
                                {formErrors.cpf_agente_relacionado}
                            </p>
                        )}
                        <label htmlFor="cargo_agente_relacionado" className="block text-gray-700">
                            Cargo ou Função do Relacionado: <Required />
                        </label>
                        <input
                            type="text"
                            name="cargo_agente_relacionado"
                            value={formData.cargo_agente_relacionado}
                            onChange={handleChange}
                            autoComplete="nope"
                            className={`mt-2 p-2 w-full border rounded-md ${formErrors.cargo_agente_relacionado ? "border-red-500" : ""}`}
                        />
                        {formErrors.cargo_agente_relacionado && (
                            <p className="text-red-500 text-xs mt-1">
                                {formErrors.cargo_agente_relacionado}
                            </p>
                        )}
                        <label htmlFor="tipo_relacao" className="block text-gray-700">
                            Tipo de relação: <Required />
                        </label>
                        <input
                            type="text"
                            name="tipo_relacao"
                            value={formData.tipo_relacao}
                            onChange={handleChange}
                            autoComplete="nope"
                            className={`mt-2 p-2 w-full border rounded-md ${formErrors.tipo_relacao ? "border-red-500" : ""}`}
                        />
                        {formErrors.tipo_relacao && (
                            <p className="text-red-500 text-xs mt-1">{formErrors.tipo_relacao}</p>
                        )}
                    </div>
                )}
            </div>
            <hr className="mt-8 mb-8" />
            <p className="text-xl font-medium mb-4">DECLARAÇÕES DO CLIENTE</p>
            <div className="mb-6">
                <label htmlFor="pv_bs2" className="block text-gray-700">
                    <span className="text-xl">
                        Nos termos da Instrução 301 da Comissão de Valores Mobiliários, o cliente
                        acima qualificado declara ser “Pessoa vinculada” à BS2 Distribuidora de
                        Títulos e Valores Mobiliários?
                    </span>{" "}
                    <Required />
                </label>

                <fieldset className="sm:flex sm:flex-rows space-x-4 mt-4">
                    <legend className="sr-only">PV</legend>
                    <div className="flex items-center mb-4">
                        <input
                            id="pv_bs2_sim"
                            type="radio"
                            name="pv_bs2"
                            value="sim"
                            className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                            checked={formData.pv_bs2 === "sim"}
                            onChange={handleChange}
                            autoComplete="nope"
                        />
                        <label
                            htmlFor="pv_bs2_sim"
                            className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            SIM, sou "PV" ao BS2.
                        </label>
                    </div>
                    <div className="flex items-center mb-4">
                        <input
                            id="pv_bs2_nao"
                            type="radio"
                            name="pv_bs2"
                            value="nao"
                            className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                            checked={formData.pv_bs2 === "nao"}
                            onChange={handleChange}
                            autoComplete="nope"
                        />
                        <label
                            htmlFor="pv_bs2_nao"
                            className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            NÃO, eu não sou "PV" ao BS2.
                        </label>
                    </div>
                </fieldset>

                {formErrors.pv_bs2 && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.pv_bs2}</p>
                )}
            </div>
            <hr className="mt-8 mb-8" />
            <div className="mb-6">
                <label htmlFor="us_person" className="block text-gray-700">
                    <span className="text-xl">
                        Conforme se verifica na legislação Norte-Americana denominada FATCA,
                        consideram-se "US Person": cidadão norte americano, titular de green card,
                        proprietário de ativos nos E.U.A. e territórios, cidadão que por razão de
                        período de viagens aos Estados Unidos da América ou qualquer outra razão
                        seja passível de tributação pela receita federal norte-americana (IRS).
                        Neste contexto, o cliente acima qualificado declara se enquadrar na condição
                        de "US Person"?
                    </span>{" "}
                    <Required />
                </label>

                <fieldset className="sm:flex sm:flex-rows space-x-4 mt-4">
                    <legend className="sr-only">PV</legend>
                    <div className="flex items-center mb-4">
                        <input
                            id="us_person_sim"
                            type="radio"
                            name="us_person"
                            value="sim"
                            className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                            checked={formData.us_person === "sim"}
                            onChange={handleChange}
                            autoComplete="nope"
                        />
                        <label
                            htmlFor="us_person_sim"
                            className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            SIM, sou uma "US Person.
                        </label>
                    </div>
                    <div className="flex items-center mb-4">
                        <input
                            id="us_person_nao"
                            type="radio"
                            name="us_person"
                            value="nao"
                            className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                            checked={formData.us_person === "nao"}
                            onChange={handleChange}
                            autoComplete="nope"
                        />
                        <label
                            htmlFor="us_person_nao"
                            className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            NÃO, eu não sou uma "US Person.
                        </label>
                    </div>
                </fieldset>

                {formErrors.us_person && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.us_person}</p>
                )}
            </div>
            <Button
                type="submit"
                color="primary"
                className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-200"
            >
                {loading && <Loader color={"white"} />}
                Enviar respostas
            </Button>
        </form>
    );
};

export default FormBS2Pf;
