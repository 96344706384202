import { FaHeadset } from "react-icons/fa";
import { SlSocialInstagram } from "react-icons/sl";
import { Link } from "react-router-dom";

import { LogoLinkV2 } from "../components/utils";
import { Container } from "../components/utils";

const Footer = () => {
    return (
        <footer className="bg-[#FBFBFF] py-24 mt-24 border-t border-blue-100">
            <Container>
                <div className="mt-8 flex items-center flex-col justify-center">
                    <LogoLinkV2 />
                    <img
                        src="/images/footer-bg.png"
                        className="w-full object-center object-cover border-b"
                        alt=""
                    />
                </div>

                <div className="mt-8 flex items-center flex-col justify-between space-y-16">
                    <div className="flex items-center space-x-4 text-blue-800">
                        <span className="bg-white flex shadow-lg border border-gray-300 border-opacity-30 rounded-full px-6 py-2">
                            <a
                                href="mailto:sac@suzancambio.com"
                                target="_blank"
                                className="flex items-center space-x-2"
                                rel="noreferrer"
                            >
                                <FaHeadset className="w-6 h-6" />
                                <span>Ouvidoria</span>
                            </a>
                        </span>
                        <span className="bg-white shadow-lg border border-gray-300 border-opacity-30 rounded-full p-2">
                            <Link
                                target="_blank"
                                to="https://www.instagram.com/suzancambio/"
                                className="flex items-center space-x-2"
                            >
                                <SlSocialInstagram className="w-6 h-6" />
                                <span>@suzancambio</span>
                            </Link>
                        </span>
                    </div>
                    <p className="text-gray-500 uppercase text-sm md:text-base">
                        Copyright © 2024 SuzanCâmbio. Todos os direitos reservados.
                    </p>
                </div>
            </Container>
        </footer>
    );
};

export default Footer;
