import { Button, Checkbox, Chip, Divider, Input, Textarea, cn } from "@nextui-org/react";
import { Loader } from "components/utils";
import { toast } from "helpers";
import Cookies from "js-cookie";
import React, { useState, useEffect, useCallback } from "react";
import InputMask from "react-input-mask";
import "react-phone-number-input/style.css";

const defaultFormDataTeste = Cookies.get("formDataKnowYourClientPJ")
    ? JSON.parse(Cookies.get("formDataKnowYourClientPJ"))
    : {
          razao_social: "",
          email: "",
          cnpj: "",
          atividade_economica: "",
          proposito_relacao_negocios_comercio_exterior: "",
          proposito_relacao_negocios_capitais_brasileiros: "",
          proposito_relacao_negocios_capitais_estrangeiros: "",
          proposito_relacao_negocios_servicos_diversos: "",
          proposito_relacao_negocios_seguros: "",
          proposito_relacao_negocios_transportes: "",
          proposito_relacao_negocios_intermediacao_pagamentos: "",
          proposito_relacao_negocios_agencia_viagem_turismo: "",
          finalidade_remessas: "",
          nivel_operacional_opcao1: "",
          nivel_operacional_opcao2: "",
          nivel_operacional_opcao3: "",
          nivel_operacional_opcao4: "",
          pagadores_recebedores_exterior: "",
          como_empresa_foi_captada: "",
          tempo_relacionamento_empresa: "",
          principais_clientes_empresa: "",
          principais_produtos_ofertados_empresa: "",
          principais_fornecedores_empresa: "",
          instituicoes_financeiras_operou_cambio_empresa: "",
          empresa_foi_visitada_nao: "",
          empresa_foi_visitada_nao_justificativa: "",
          empresa_foi_visitada_sim: "",
          empresa_foi_visitada_sim_descricao_estrutura_fisica: "",
          quantidade_colaboradoes: "",
          outras_percepcoes_importantes: "",
          efetivo_exercicio_atividades_sim: "",
          efetivo_exercicio_atividades_nao: "",
          modalidade_recebimento_pagamento_antecipado: "",
          modalidade_recebimento_pagamento_pronta: "",
          modalidade_recebimento_pagamento_ambos: "",
          modalidade_habilitacao_radar_conta_propria: "",
          modalidade_habilitacao_radar_conta_ordem_terceiros: "",
          declaracoes_exportacao_ultimos_seis_meses_sim: "",
          declaracoes_exportacao_ultimos_seis_meses_nao: "",
          armazenamento_distribuicao_mercadorias: "",
          mercadorias_objeto_exportacao_importacao: "",
          atividades_exercidas: "",
          produtos_servicos_oferecidos: "",
          perfis_quantidade_clientes_parceiros: "",
          ferramentas_antifraude: "",
          auditoria_interna: "",
          empresa_cadastrada_coaf: "",
          processadora_atual_nome_data_inicio_parceria: "",
          quantidade_transacoes_mensalmente: "",
          ramo_atividades: "",
          merchants_acordo_comercial: "",
          atua_atende_jogos_azar_apostas: "",
          equipe_para_chargebacks: "",
          informacoes_adicionais: "",
          local: "",
          data_ano: "",
          assinatura_gestor: "",
          data_dia: "",
          data_mes: ""
      };

const Form = ({ setData, setError }) => {
    const [formErrors, setFormErrors] = useState({
        razao_social: "",
        email: "",
        cnpj: "",
        atividade_economica: "",
        proposito_relacao_negocios_comercio_exterior: "",
        proposito_relacao_negocios_capitais_brasileiros: "",
        proposito_relacao_negocios_capitais_estrangeiros: "",
        proposito_relacao_negocios_servicos_diversos: "",
        proposito_relacao_negocios_seguros: "",
        proposito_relacao_negocios_transportes: "",
        proposito_relacao_negocios_intermediacao_pagamentos: "",
        proposito_relacao_negocios_agencia_viagem_turismo: "",
        finalidade_remessas: "",
        nivel_operacional_opcao1: "",
        nivel_operacional_opcao2: "",
        nivel_operacional_opcao3: "",
        nivel_operacional_opcao4: "",
        pagadores_recebedores_exterior: "",
        como_empresa_foi_captada: "",
        tempo_relacionamento_empresa: "",
        principais_clientes_empresa: "",
        principais_produtos_ofertados_empresa: "",
        principais_fornecedores_empresa: "",
        instituicoes_financeiras_operou_cambio_empresa: "",
        empresa_foi_visitada_nao: "",
        empresa_foi_visitada_nao_justificativa: "",
        empresa_foi_visitada_sim: "",
        empresa_foi_visitada_sim_descricao_estrutura_fisica: "",
        quantidade_colaboradoes: "",
        outras_percepcoes_importantes: "",
        efetivo_exercicio_atividades_sim: "",
        efetivo_exercicio_atividades_nao: "",
        modalidade_recebimento_pagamento_antecipado: "",
        modalidade_recebimento_pagamento_pronta: "",
        modalidade_recebimento_pagamento_ambos: "",
        modalidade_habilitacao_radar_conta_propria: "",
        modalidade_habilitacao_radar_conta_ordem_terceiros: "",
        declaracoes_exportacao_ultimos_seis_meses_sim: "",
        declaracoes_exportacao_ultimos_seis_meses_nao: "",
        armazenamento_distribuicao_mercadorias: "",
        mercadorias_objeto_exportacao_importacao: "",
        atividades_exercidas: "",
        produtos_servicos_oferecidos: "",
        perfis_quantidade_clientes_parceiros: "",
        ferramentas_antifraude: "",
        auditoria_interna: "",
        empresa_cadastrada_coaf: "",
        processadora_atual_nome_data_inicio_parceria: "",
        quantidade_transacoes_mensalmente: "",
        ramo_atividades: "",
        merchants_acordo_comercial: "",
        atua_atende_jogos_azar_apostas: "",
        equipe_para_chargebacks: "",
        informacoes_adicionais: "",
        local: "",
        data_ano: "",
        assinatura_gestor: "",
        data_dia: "",
        data_mes: ""
    });
    const [loading, setLoading] = useState(false);

    //Form
    const [formData, setFormData] = useState(defaultFormDataTeste);

    const validateForm = useCallback(() => {
        const errors = {};

        if (!formData.razao_social?.trim()) errors.razao_social = "Campo obrigatório";
        if (!formData.email?.trim()) errors.email = "Campo obrigatório";
        if (!formData.cnpj?.trim()) errors.cnpj = "Campo obrigatório";
        if (!formData.atividade_economica?.trim()) errors.atividade_economica = "Campo obrigatório";
        if (!formData.finalidade_remessas?.trim()) errors.finalidade_remessas = "Campo obrigatório";
        if (!formData.pagadores_recebedores_exterior?.trim())
            errors.pagadores_recebedores_exterior = "Campo obrigatório";
        if (!formData.tempo_relacionamento_empresa?.trim())
            errors.tempo_relacionamento_empresa = "Campo obrigatório";
        if (!formData.principais_clientes_empresa?.trim())
            errors.principais_clientes_empresa = "Campo obrigatório";
        if (!formData.principais_produtos_ofertados_empresa?.trim())
            errors.principais_produtos_ofertados_empresa = "Campo obrigatório";
        if (!formData.principais_fornecedores_empresa?.trim())
            errors.principais_fornecedores_empresa = "Campo obrigatório";
        if (!formData.instituicoes_financeiras_operou_cambio_empresa?.trim())
            errors.instituicoes_financeiras_operou_cambio_empresa = "Campo obrigatório";
        if (!formData.quantidade_colaboradoes?.trim())
            errors.quantidade_colaboradoes = "Campo obrigatório";
        if (!formData.armazenamento_distribuicao_mercadorias?.trim())
            errors.armazenamento_distribuicao_mercadorias = "Campo obrigatório";
        if (!formData.mercadorias_objeto_exportacao_importacao?.trim())
            errors.mercadorias_objeto_exportacao_importacao = "Campo obrigatório";
        setFormErrors(errors);
    }, [formData]);

    useEffect(() => {
        // Chamada da função de validação
        validateForm();
    }, [validateForm]);

    const handleChange = async e => {
        const name = e.target.name;
        const value = e.target.value;
        if (name == "cnpj") {
            console.log(name);
            console.log(value);
            console.log(formData);
        }
        // Para todos os outros campos, atualiza o estado normalmente.
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleCheckBoxChange = async e => {
        const name = e.target.name;
        const value = e.target.checked;
        // Para todos os outros campos, atualiza o estado normalmente.
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit = async e => {
        e.preventDefault();
        console.log(e);
        console.log(formErrors);
        console.log(formData);
        const errors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            const userToken = process.env.REACT_APP_API_TOKEN;
            const url = `${process.env.REACT_APP_API_HOST}/formularios/bancobs2/pj/kyc`;

            const formDataTemp = {
                razao_social: formData.razao_social,
                email: formData.email,
                cnpj: formData.cnpj,
                atividade_economica: formData.atividade_economica,
                proposito_relacao_negocios_comercio_exterior:
                    formData.proposito_relacao_negocios_comercio_exterior,
                proposito_relacao_negocios_capitais_brasileiros:
                    formData.proposito_relacao_negocios_capitais_brasileiros,
                proposito_relacao_negocios_capitais_estrangeiros:
                    formData.proposito_relacao_negocios_capitais_estrangeiros,
                proposito_relacao_negocios_servicos_diversos:
                    formData.proposito_relacao_negocios_servicos_diversos,
                proposito_relacao_negocios_seguros: formData.proposito_relacao_negocios_seguros,
                proposito_relacao_negocios_transportes:
                    formData.proposito_relacao_negocios_transportes,
                proposito_relacao_negocios_intermediacao_pagamentos:
                    formData.proposito_relacao_negocios_intermediacao_pagamentos,
                proposito_relacao_negocios_agencia_viagem_turismo:
                    formData.proposito_relacao_negocios_agencia_viagem_turismo,
                finalidade_remessas: formData.finalidade_remessas,
                nivel_operacional_opcao1: formData.nivel_operacional_opcao1,
                nivel_operacional_opcao2: formData.nivel_operacional_opcao2,
                nivel_operacional_opcao3: formData.nivel_operacional_opcao3,
                nivel_operacional_opcao4: formData.nivel_operacional_opcao4,
                pagadores_recebedores_exterior: formData.pagadores_recebedores_exterior,
                como_empresa_foi_captada: formData.como_empresa_foi_captada,
                tempo_relacionamento_empresa: formData.tempo_relacionamento_empresa,
                principais_clientes_empresa: formData.principais_clientes_empresa,
                principais_produtos_ofertados_empresa:
                    formData.principais_produtos_ofertados_empresa,
                principais_fornecedores_empresa: formData.principais_fornecedores_empresa,
                instituicoes_financeiras_operou_cambio_empresa:
                    formData.instituicoes_financeiras_operou_cambio_empresa,
                empresa_foi_visitada_nao: formData.empresa_foi_visitada_nao,
                empresa_foi_visitada_nao_justificativa:
                    formData.empresa_foi_visitada_nao_justificativa,
                empresa_foi_visitada_sim: formData.empresa_foi_visitada_sim,
                empresa_foi_visitada_sim_descricao_estrutura_fisica:
                    formData.empresa_foi_visitada_sim_descricao_estrutura_fisica,
                quantidade_colaboradoes: formData.quantidade_colaboradoes,
                outras_percepcoes_importantes: formData.outras_percepcoes_importantes,
                efetivo_exercicio_atividades_sim: formData.efetivo_exercicio_atividades_sim,
                efetivo_exercicio_atividades_nao: formData.efetivo_exercicio_atividades_nao,
                modalidade_recebimento_pagamento_antecipado:
                    formData.modalidade_recebimento_pagamento_antecipado,
                modalidade_recebimento_pagamento_pronta:
                    formData.modalidade_recebimento_pagamento_pronta,
                modalidade_recebimento_pagamento_ambos:
                    formData.modalidade_recebimento_pagamento_ambos,
                modalidade_habilitacao_radar_conta_propria:
                    formData.modalidade_habilitacao_radar_conta_propria,
                modalidade_habilitacao_radar_conta_ordem_terceiros:
                    formData.modalidade_habilitacao_radar_conta_ordem_terceiros,
                declaracoes_exportacao_ultimos_seis_meses_sim:
                    formData.declaracoes_exportacao_ultimos_seis_meses_sim,
                declaracoes_exportacao_ultimos_seis_meses_nao:
                    formData.declaracoes_exportacao_ultimos_seis_meses_nao,
                armazenamento_distribuicao_mercadorias:
                    formData.armazenamento_distribuicao_mercadorias,
                mercadorias_objeto_exportacao_importacao:
                    formData.mercadorias_objeto_exportacao_importacao,
                atividades_exercidas: formData.atividades_exercidas,
                produtos_servicos_oferecidos: formData.produtos_servicos_oferecidos,
                perfis_quantidade_clientes_parceiros: formData.perfis_quantidade_clientes_parceiros,
                ferramentas_antifraude: formData.ferramentas_antifraude,
                auditoria_interna: formData.auditoria_interna,
                empresa_cadastrada_coaf: formData.empresa_cadastrada_coaf,
                processadora_atual_nome_data_inicio_parceria:
                    formData.processadora_atual_nome_data_inicio_parceria,
                quantidade_transacoes_mensalmente: formData.quantidade_transacoes_mensalmente,
                ramo_atividades: formData.ramo_atividades,
                merchants_acordo_comercial: formData.merchants_acordo_comercial,
                atua_atende_jogos_azar_apostas: formData.atua_atende_jogos_azar_apostas,
                equipe_para_chargebacks: formData.equipe_para_chargebacks,
                informacoes_adicionais: formData.informacoes_adicionais,
                local: formData.local,
                data_ano: formData.data_ano,
                assinatura_gestor: formData.assinatura_gestor,
                data_dia: formData.data_dia,
                data_mes: formData.data_mes
            };

            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${userToken}`
                    },
                    body: JSON.stringify(formDataTemp),
                    credentials: "include"
                });

                if (response.status === 200) {
                    const jsonData = await response.json();
                    setData(jsonData);

                    Cookies.set("fileExistsKnowYourClientPJ", JSON.stringify(jsonData), {
                        expires: 7
                    });
                    Cookies.set("formDataKnowYourClientPJ", JSON.stringify(formDataTemp), {
                        expires: 30
                    });

                    setError(null);
                } else {
                    toast(
                        "error",
                        `Erro ao enviar resposta\n\n${response.status} ${response.statusText}\n\nDúvidas? Entre em contato no WhatsApp +55 11 93211-4516`,
                        true
                    );
                }
            } catch (error) {
                console.error("Erro ao fazer a requisição:", error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        } else {
            setFormErrors(errors);
            setLoading(false);
            console.log(errors);
            if (errors) {
                toast("error", "Erro ao enviar resposta\nPreencha os campos obrigatórios.");
            } else {
                toast(
                    "error",
                    "Erro ao enviar resposta\nDúvidas? Entre em contato no WhatsApp +55 11 93211-4516",
                    true
                );
            }
        }
    };

    const Required = () => {
        return <span className="text-red-500 font-bold">*</span>;
    };
    return (
        <form
            className="bg-white p-8 rounded-lg shadow-lg w-full md:min-w-[400px] md:max-w-3xl mx-auto"
            autoComplete="off"
        >
            <p className="text-gray-500 mb-8 text-center">
                O Banco BS2 é a instituição financeira parceira da SuzanCâmbio na realização de
                transferências internacionais. Por isso, após o preenchimento deste formulário você
                receberá um e-mail com o docuemnto do BS2 devidamente preenchido para conferência e
                assinatura digital.
                <br />
                Todos os dados informados neste formulário serão mantidos em total sigilo pela
                SuzanCâmbio e BS2, seguindo rigorosamente as diretrizes da lei de proteção geral de
                dados - LGPD.
            </p>
            <h2 className="text-2xl font-bold mb-6 text-gray-800 text-center">
                Formulário - Identificação do Cliente
            </h2>
            <div className="mb-4">
                <label htmlFor="razao_social" className="block text-gray-700">
                    Razão Social <Required />
                </label>
                <Input
                    id="razao_social"
                    name="razao_social"
                    value={formData.razao_social}
                    onChange={handleChange}
                    isRequired
                    variant="bordered"
                    fullWidth
                    classNames={{
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.razao_social ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.razao_social}
                />
            </div>
            <div className="mb-4">
                <label htmlFor="email" className="block text-gray-700">
                    Email <Required />
                </label>
                <Input
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    isRequired
                    variant="bordered"
                    fullWidth
                    classNames={{
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.email ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.email}
                />
            </div>
            <div className="mb-6 w-full grid grid-cols-2 space-x-2">
                <div>
                    <label htmlFor="cnpj" className="block text-gray-700">
                        CNPJ <Required />
                    </label>
                    <InputMask
                        mask={"99.999.999/9999-99"}
                        value={formData.cnpj}
                        onChange={handleChange}
                        autoComplete="nope"
                        id="cnpj"
                        name="cnpj"
                        aria-label="cnpj"
                        className={`mt-2 p-2 w-full border rounded-md ${formErrors.cnpj ? "border-red-500" : ""}`}
                    >
                        {inputProps => <input {...inputProps} type="tel" />}
                    </InputMask>
                    {formErrors.cnpj && (
                        <p className="text-red-500 text-xs mt-1">{formErrors.cnpj}</p>
                    )}
                </div>
            </div>
            <div className="mb-6">
                <label htmlFor="atividade_economica" className="block text-gray-700">
                    Atividade Econômica:
                </label>
                <Textarea
                    id="atividade_economica"
                    name="atividade_economica"
                    value={formData.atividade_economica}
                    onChange={handleChange}
                    variant="bordered"
                    fullWidth
                    classNames={{
                        base: cn("mb-8"),
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.atividade_economica ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.atividade_economica}
                />
            </div>
            <Divider className="mt-8 mb-8" />
            <div className="mb-4">
                <div className="mb-8">
                    <label className="block text-gray-700">
                        <span className="text-xl font-medium">
                            Propósito da Relação de Negócios:
                        </span>{" "}
                    </label>
                </div>
                <div className="w-full">
                    <Checkbox
                        id="proposito_relacao_negocios_comercio_exterior"
                        name="proposito_relacao_negocios_comercio_exterior"
                        value={formData.proposito_relacao_negocios_comercio_exterior}
                        defaultSelected={formData.proposito_relacao_negocios_comercio_exterior}
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <span className="antialiased font-medium text-gray-800">
                                1. Comércio Exterior
                            </span>
                            <p>Exportação e/ou importação de mercadorias</p>
                        </div>
                    </Checkbox>
                    <Checkbox
                        id="proposito_relacao_negocios_capitais_brasileiros"
                        name="proposito_relacao_negocios_capitais_brasileiros"
                        value={formData.proposito_relacao_negocios_capitais_brasileiros}
                        defaultSelected={formData.proposito_relacao_negocios_capitais_brasileiros}
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <span className="antialiased font-medium text-gray-800">
                                2. Capitais Brasileiros
                            </span>
                            <p>
                                Empréstimos e financiamentos, disponibilidades no exterior, compra e
                                venda de imóveis no exterior, doações, manutenção de residentes no
                                exterior, investimentos, lucros e dividendos, outras transferências
                                correntes
                            </p>
                        </div>
                    </Checkbox>
                    <Checkbox
                        id="proposito_relacao_negocios_capitais_estrangeiros"
                        name="proposito_relacao_negocios_capitais_estrangeiros"
                        value={formData.proposito_relacao_negocios_capitais_estrangeiros}
                        defaultSelected={formData.proposito_relacao_negocios_capitais_estrangeiros}
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <span className="antialiased font-medium text-gray-800">
                                3. Capitais Estrangeiros
                            </span>
                            <p className="antialiased">
                                Empréstimos e financiamentos, disponibilidades no país, compra e
                                venda de imóveis no país, doações, manutenção de residentes no
                                exterior, investimentos, lucros e dividendos, outras transferências
                                correntes
                            </p>
                        </div>
                    </Checkbox>
                    <Checkbox
                        id="proposito_relacao_negocios_servicos_diversos"
                        name="proposito_relacao_negocios_servicos_diversos"
                        value={formData.proposito_relacao_negocios_servicos_diversos}
                        defaultSelected={formData.proposito_relacao_negocios_servicos_diversos}
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <span className="antialiased font-medium text-gray-800">
                                4. Serviços Diversos
                            </span>
                            <p className="antialiased">
                                Serviços técnicos e profissionais, transações comerciais
                            </p>
                        </div>
                    </Checkbox>
                    <Checkbox
                        id="proposito_relacao_negocios_seguros"
                        name="proposito_relacao_negocios_seguros"
                        value={formData.proposito_relacao_negocios_seguros}
                        defaultSelected={formData.proposito_relacao_negocios_seguros}
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <span className="antialiased font-medium text-gray-800">
                                5. Seguros
                            </span>
                            <p className="antialiased">
                                Resseguros, prêmios, indenizações e sinistros
                            </p>
                        </div>
                    </Checkbox>
                    <Checkbox
                        id="proposito_relacao_negocios_transportes"
                        name="proposito_relacao_negocios_transportes"
                        value={formData.proposito_relacao_negocios_transportes}
                        defaultSelected={formData.proposito_relacao_negocios_transportes}
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <span className="antialiased font-medium text-gray-800">
                                6. Transportes
                            </span>
                            <p className="antialiased">Fretes sobre exportação ou importação</p>
                        </div>
                    </Checkbox>
                    <Checkbox
                        id="proposito_relacao_negocios_intermediacao_pagamentos"
                        name="proposito_relacao_negocios_intermediacao_pagamentos"
                        value={formData.proposito_relacao_negocios_intermediacao_pagamentos}
                        defaultSelected={
                            formData.proposito_relacao_negocios_intermediacao_pagamentos
                        }
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <span className="antialiased font-medium text-gray-800">
                                7. Intermediação de pagamentos
                            </span>
                            <p className="antialiased">
                                Prestadores de serviços e-FX, facilitadoras de pagamento
                                internacional
                            </p>
                        </div>
                    </Checkbox>
                    <Checkbox
                        id="proposito_relacao_negocios_agencia_viagem_turismo"
                        name="proposito_relacao_negocios_agencia_viagem_turismo"
                        value={formData.proposito_relacao_negocios_agencia_viagem_turismo}
                        defaultSelected={formData.proposito_relacao_negocios_agencia_viagem_turismo}
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <span className="antialiased font-medium text-gray-800">
                                8. Agências de viagens e turismo
                            </span>
                        </div>
                    </Checkbox>
                </div>
                <Textarea
                    id="finalidade_remessas"
                    name="finalidade_remessas"
                    value={formData.finalidade_remessas}
                    onChange={handleChange}
                    isRequired
                    label="Finalidade das remessas (detalhamento):"
                    labelPlacement="outside"
                    variant="bordered"
                    fullWidth
                    classNames={{
                        base: cn("mt-8 mb-8"),
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.finalidade_remessas ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.finalidade_remessas}
                />
            </div>
            <div className="mb-4">
                <div className="mb-8">
                    <label className="block text-gray-700">
                        <span className="text-xl font-medium">Nível Operacional Pretendido:</span>{" "}
                    </label>
                </div>
                <div className="w-full">
                    <Checkbox
                        id="nivel_operacional_opcao1"
                        name="nivel_operacional_opcao1"
                        value={formData.nivel_operacional_opcao1}
                        defaultSelected={formData.nivel_operacional_opcao1}
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <Chip className="mr-3 bg-blue-300">
                                <span className="font-medium text-white">NÍVEL 1</span>
                            </Chip>
                            <span className="antialiased font-medium text-gray-800">
                                Até US$ 20.000,00{" "}
                                <span className="font-normal">
                                    (ou o equivalente em outras moedas)
                                </span>
                            </span>
                        </div>
                    </Checkbox>
                    <Checkbox
                        id="nivel_operacional_opcao2"
                        name="nivel_operacional_opcao2"
                        value={formData.nivel_operacional_opcao2}
                        defaultSelected={formData.nivel_operacional_opcao2}
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <Chip className="mr-3 bg-blue-400">
                                <span className="font-medium text-white">NÍVEL 2</span>
                            </Chip>
                            <span className="antialiased font-medium text-gray-800">
                                Entre US$ 20.000,01 e US$ 250.000,00{" "}
                                <span className="font-normal">
                                    (ou o equivalente em outras moedas)
                                </span>
                            </span>
                        </div>
                    </Checkbox>
                    <Checkbox
                        id="nivel_operacional_opcao3"
                        name="nivel_operacional_opcao3"
                        value={formData.nivel_operacional_opcao3}
                        defaultSelected={formData.nivel_operacional_opcao3}
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <Chip className="mr-3 bg-blue-700">
                                <span className="font-medium text-white">NÍVEL 3</span>
                            </Chip>
                            <span className="antialiased font-medium text-gray-800">
                                Entre US$ 250.000,01 e US$ 1.500.000,00{" "}
                                <span className="font-normal">
                                    (ou o equivalente em outras moedas)
                                </span>
                            </span>
                        </div>
                    </Checkbox>
                    <Checkbox
                        id="nivel_operacional_opcao4"
                        name="nivel_operacional_opcao4"
                        value={formData.nivel_operacional_opcao4}
                        defaultSelected={formData.nivel_operacional_opcao4}
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <Chip className="mr-3 bg-blue-900">
                                <span className="font-medium text-white">NÍVEL 4</span>
                            </Chip>
                            <span className="antialiased font-medium text-gray-800">
                                Acima de US$ 1.500.000,00{" "}
                                <span className="font-normal">
                                    (ou o equivalente em outras moedas)
                                </span>
                            </span>
                        </div>
                    </Checkbox>
                </div>
                <Textarea
                    id="pagadores_recebedores_exterior"
                    name="pagadores_recebedores_exterior"
                    value={formData.pagadores_recebedores_exterior}
                    onChange={handleChange}
                    isRequired
                    label="Pagadores/Recebedores no exterior e seus respectivos países:"
                    labelPlacement="outside"
                    variant="bordered"
                    fullWidth
                    classNames={{
                        base: cn("mt-8 mb-8"),
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.pagadores_recebedores_exterior ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.pagadores_recebedores_exterior}
                />
            </div>
            <Divider className="mt-4 mb-8" />
            <div>
                <label className="block text-gray-700">
                    <span className="text-xl font-medium">Informações adicionais</span>{" "}
                </label>
                <Textarea
                    id="como_empresa_foi_captada"
                    name="como_empresa_foi_captada"
                    value={formData.como_empresa_foi_captada}
                    onChange={handleChange}
                    label="Como a empresa foi captada (ou como chegou até o BS2)?"
                    labelPlacement="outside"
                    variant="bordered"
                    fullWidth
                    classNames={{
                        base: cn("mt-8 mb-8"),
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.como_empresa_foi_captada ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.como_empresa_foi_captada}
                />
                <div className="flex flex-cols justify-left relative z-0 w-full mb-5">
                    <div className="relative z-0 w-full group">
                        <label
                            htmlFor="tempo_relacionamento_empresa"
                            className="block text-gray-700"
                        >
                            Desde quando tem relacionamento com a empresa: <Required />
                        </label>
                        <InputMask
                            mask={"99/99/9999"}
                            placeholder="dd/mm/aaaa"
                            maskChar={null}
                            value={formData.tempo_relacionamento_empresa}
                            onChange={handleChange}
                            autoComplete="nope"
                            id="tempo_relacionamento_empresa"
                            name="tempo_relacionamento_empresa"
                            className={`mt-2 p-2 w-full border rounded-md ${formErrors.tempo_relacionamento_empresa ? "border-red-500" : ""}`}
                        >
                            {inputProps => (
                                <input {...inputProps} type="tel" placeholder="dd/mm/aaaa" />
                            )}
                        </InputMask>
                        {formErrors.tempo_relacionamento_empresa && (
                            <p className="text-red-500 text-xs mt-1">
                                {formErrors.tempo_relacionamento_empresa}
                            </p>
                        )}
                    </div>
                </div>
                <Textarea
                    id="principais_produtos_ofertados_empresa"
                    name="principais_produtos_ofertados_empresa"
                    value={formData.principais_produtos_ofertados_empresa}
                    onChange={handleChange}
                    isRequired
                    label="Quais os principais produtos e/ou serviços ofertados pela empresa?"
                    labelPlacement="outside"
                    variant="bordered"
                    fullWidth
                    classNames={{
                        base: cn("mt-8 mb-8"),
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.principais_produtos_ofertados_empresa ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.principais_produtos_ofertados_empresa}
                />
                <Textarea
                    id="principais_clientes_empresa"
                    name="principais_clientes_empresa"
                    value={formData.principais_clientes_empresa}
                    onChange={handleChange}
                    isRequired
                    label="Quais os principais cliente da empresa?"
                    labelPlacement="outside"
                    variant="bordered"
                    fullWidth
                    classNames={{
                        base: cn("mt-8 mb-8"),
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.principais_clientes_empresa ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.principais_clientes_empresa}
                />
                <Textarea
                    id="principais_fornecedores_empresa"
                    name="principais_fornecedores_empresa"
                    value={formData.principais_fornecedores_empresa}
                    onChange={handleChange}
                    isRequired
                    label="Quais os principais fornecedores da empresa?"
                    labelPlacement="outside"
                    variant="bordered"
                    fullWidth
                    classNames={{
                        base: cn("mt-8 mb-8"),
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.principais_fornecedores_empresa ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.principais_fornecedores_empresa}
                />
                <Textarea
                    id="instituicoes_financeiras_operou_cambio_empresa"
                    name="instituicoes_financeiras_operou_cambio_empresa"
                    value={formData.instituicoes_financeiras_operou_cambio_empresa}
                    onChange={handleChange}
                    isRequired
                    label="Com quais instituições financeiras a empresa opera (ou já operou) câmbio?"
                    labelPlacement="outside"
                    variant="bordered"
                    fullWidth
                    classNames={{
                        base: cn("mt-8 mb-8"),
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.instituicoes_financeiras_operou_cambio_empresa ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.instituicoes_financeiras_operou_cambio_empresa}
                />
            </div>
            <Divider className="mt-4 mb-8" />
            <div>
                <label className="block text-gray-700">
                    <span className="text-xl font-medium">Visita</span>{" "}
                </label>
                <div>
                    <label htmlFor="quantidade_colaboradoes" className="block text-gray-700">
                        Quantidade de colaboradores: <Required />
                    </label>
                    <Input
                        id="quantidade_colaboradoes"
                        name="quantidade_colaboradoes"
                        value={formData.quantidade_colaboradoes}
                        onChange={handleChange}
                        isRequired
                        type="number"
                        variant="bordered"
                        fullWidth
                        classNames={{
                            inputWrapper: "border-none p-0",
                            input: cn(
                                "resize-y min-h-[40px] rounded-md",
                                `mt-2 p-2 w-full border rounded-md ${formErrors?.quantidade_colaboradoes ? "border-red-500" : ""}`
                            ),
                            label: "w-full antialiased block text-gray-700 text-base"
                        }}
                        errorMessage={formErrors?.quantidade_colaboradoes}
                    />
                </div>
                <Textarea
                    id="outras_percepcoes_importantes"
                    name="outras_percepcoes_importantes"
                    value={formData.outras_percepcoes_importantes}
                    onChange={handleChange}
                    label="Outras percepções julgadas importantes pelo visitante:"
                    labelPlacement="outside"
                    variant="bordered"
                    fullWidth
                    classNames={{
                        base: cn("mt-8 mb-8"),
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.outras_percepcoes_importantes ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.outras_percepcoes_importantes}
                />
                <div className="mb-8">
                    <label className="block text-gray-700">
                        <span>
                            Foi constatado o efetivo exercício das suas atividades? (movimento de
                            clientes, colaboradores, instalações apropriadas e afins)
                        </span>{" "}
                    </label>
                </div>
                <div className="w-full">
                    <Checkbox
                        id="efetivo_exercicio_atividades_sim"
                        name="efetivo_exercicio_atividades_sim"
                        value={formData.efetivo_exercicio_atividades_sim}
                        defaultSelected={formData.efetivo_exercicio_atividades_sim}
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <span className="antialiased font-medium text-gray-800">Sim</span>
                        </div>
                    </Checkbox>
                    <Checkbox
                        id="efetivo_exercicio_atividades_nao"
                        name="efetivo_exercicio_atividades_nao"
                        value={formData.efetivo_exercicio_atividades_nao}
                        defaultSelected={formData.efetivo_exercicio_atividades_nao}
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <span className="antialiased font-medium text-gray-800">Não</span>
                        </div>
                    </Checkbox>
                </div>
            </div>
            <Divider className="mt-4 mb-8" />
            <div>
                <label className="block text-gray-700">
                    <span className="text-xl font-medium">Comércio Exterior</span>{" "}
                </label>
                <p>
                    Se a opção comércio exterior foi indicada como propósito da relação de negócio,
                    informe:
                </p>
                <div className="mb-8">
                    <label className="block text-gray-700">
                        <span>Modalidade de recebimento/pagamento:</span>{" "}
                    </label>
                </div>
                <div className="w-full">
                    <Checkbox
                        id="modalidade_recebimento_pagamento_antecipado"
                        name="modalidade_recebimento_pagamento_antecipado"
                        value={formData.modalidade_recebimento_pagamento_antecipado}
                        defaultSelected={formData.modalidade_recebimento_pagamento_antecipado}
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <span className="antialiased text-gray-800">
                                Antecipado/A vista (sem DUE/DUIMP)
                            </span>
                        </div>
                    </Checkbox>
                    <Checkbox
                        id="modalidade_recebimento_pagamento_pronta"
                        name="modalidade_recebimento_pagamento_pronta"
                        value={formData.modalidade_recebimento_pagamento_pronta}
                        defaultSelected={formData.modalidade_recebimento_pagamento_pronta}
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <span className="antialiased text-gray-800">Pronta</span>
                        </div>
                    </Checkbox>
                    <Checkbox
                        id="modalidade_recebimento_pagamento_ambos"
                        name="modalidade_recebimento_pagamento_ambos"
                        value={formData.modalidade_recebimento_pagamento_ambos}
                        defaultSelected={formData.modalidade_recebimento_pagamento_ambos}
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <span className="antialiased text-gray-800">Ambos</span>
                        </div>
                    </Checkbox>
                </div>
                <div className="mb-8">
                    <label className="block font-medium text-gray-700">
                        <span>
                            Modalidade da habilitação para operar comércio exterior (RADAR):
                        </span>{" "}
                    </label>
                </div>
                <div className="w-full">
                    <Checkbox
                        id="modalidade_habilitacao_radar_conta_propria"
                        name="modalidade_habilitacao_radar_conta_propria"
                        value={formData.modalidade_habilitacao_radar_conta_propria}
                        defaultSelected={formData.modalidade_habilitacao_radar_conta_propria}
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <span className="antialiased text-gray-800">Por conta própria</span>
                        </div>
                    </Checkbox>
                    <Checkbox
                        id="modalidade_habilitacao_radar_conta_ordem_terceiros"
                        name="modalidade_habilitacao_radar_conta_ordem_terceiros"
                        value={formData.modalidade_habilitacao_radar_conta_ordem_terceiros}
                        defaultSelected={
                            formData.modalidade_habilitacao_radar_conta_ordem_terceiros
                        }
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <span className="antialiased text-gray-800">
                                Por conta e ordem de terceiros
                            </span>
                            <p>
                                (trading ou comercial exportadora/importadora). Neste caso, informar
                                o nome e CNPJ dos principais adquirentes no campo INFORMAÇÕES
                                ADICIONAIS.
                            </p>
                        </div>
                    </Checkbox>
                </div>
                <div className="mb-8">
                    <label className="block font-medium text-gray-700">
                        <span>
                            A empresa tem declarações de exportação/importação registradas nos
                            últimos seis meses?
                        </span>{" "}
                    </label>
                </div>
                <div className="w-full">
                    <Checkbox
                        id="declaracoes_exportacao_ultimos_seis_meses_sim"
                        name="declaracoes_exportacao_ultimos_seis_meses_sim"
                        value={formData.declaracoes_exportacao_ultimos_seis_meses_sim}
                        defaultSelected={formData.declaracoes_exportacao_ultimos_seis_meses_sim}
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <span className="antialiased text-gray-800">
                                Sim. Neste caso, forneça uma amostra de DUE/DUIMP (até seis)
                            </span>
                        </div>
                    </Checkbox>
                    <Checkbox
                        id="declaracoes_exportacao_ultimos_seis_meses_nao"
                        name="declaracoes_exportacao_ultimos_seis_meses_nao"
                        value={formData.declaracoes_exportacao_ultimos_seis_meses_nao}
                        defaultSelected={formData.declaracoes_exportacao_ultimos_seis_meses_nao}
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <span className="antialiased text-gray-800">
                                Não. Neste caso, justifique no campo INFORMAÇÕES ADICIONAIS
                            </span>
                        </div>
                    </Checkbox>
                </div>
                <Textarea
                    id="armazenamento_distribuicao_mercadorias"
                    name="armazenamento_distribuicao_mercadorias"
                    value={formData.armazenamento_distribuicao_mercadorias}
                    onChange={handleChange}
                    isRequired
                    label="Como e onde é feito o armazenamento e distribuição de mercadorias?"
                    labelPlacement="outside"
                    variant="bordered"
                    fullWidth
                    classNames={{
                        base: cn("mt-8 mb-8"),
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.armazenamento_distribuicao_mercadorias ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.armazenamento_distribuicao_mercadorias}
                />
                <Textarea
                    id="mercadorias_objeto_exportacao_importacao"
                    name="mercadorias_objeto_exportacao_importacao"
                    value={formData.mercadorias_objeto_exportacao_importacao}
                    onChange={handleChange}
                    isRequired
                    label="Mercadorias objeto de exportação/importação:"
                    labelPlacement="outside"
                    variant="bordered"
                    fullWidth
                    classNames={{
                        base: cn("mt-8 mb-8"),
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.mercadorias_objeto_exportacao_importacao ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.mercadorias_objeto_exportacao_importacao}
                />
            </div>
            <Divider className="mt-4 mb-8" />
            <div>
                <label className="block text-gray-700">
                    <span className="text-xl font-medium">e-FX (Facilitadoras)</span>{" "}
                </label>
                <Textarea
                    id="atividades_exercidas"
                    name="atividades_exercidas"
                    value={formData.atividades_exercidas}
                    onChange={handleChange}
                    label="Atividades exercidas (core business) e o site na internet"
                    labelPlacement="outside"
                    variant="bordered"
                    fullWidth
                    classNames={{
                        base: cn("mt-8 mb-8"),
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.atividades_exercidas ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.atividades_exercidas}
                />
                <Textarea
                    id="produtos_servicos_oferecidos"
                    name="produtos_servicos_oferecidos"
                    value={formData.produtos_servicos_oferecidos}
                    onChange={handleChange}
                    label="Produtos e serviços oferecidos e os canais de distribuição"
                    labelPlacement="outside"
                    variant="bordered"
                    fullWidth
                    classNames={{
                        base: cn("mt-8 mb-8"),
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.produtos_servicos_oferecidos ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.produtos_servicos_oferecidos}
                />
                <Textarea
                    id="perfis_quantidade_clientes_parceiros"
                    name="perfis_quantidade_clientes_parceiros"
                    value={formData.perfis_quantidade_clientes_parceiros}
                    onChange={handleChange}
                    label="Perfis e quantidade dos seus clientes/parceiros"
                    labelPlacement="outside"
                    variant="bordered"
                    fullWidth
                    classNames={{
                        base: cn("mt-8 mb-8"),
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.perfis_quantidade_clientes_parceiros ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.perfis_quantidade_clientes_parceiros}
                />
                <Textarea
                    id="ferramentas_antifraude"
                    name="ferramentas_antifraude"
                    value={formData.ferramentas_antifraude}
                    onChange={handleChange}
                    label="Possui ferramentas antifraude? Caso sim, informe o sistema/solução utilizada e explique o processo"
                    labelPlacement="outside"
                    variant="bordered"
                    fullWidth
                    classNames={{
                        base: cn("mt-8 mb-8"),
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.ferramentas_antifraude ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.ferramentas_antifraude}
                />
                <Textarea
                    id="empresa_cadastrada_coaf"
                    name="empresa_cadastrada_coaf"
                    value={formData.empresa_cadastrada_coaf}
                    onChange={handleChange}
                    label="Empresa é cadastrada no COAF (SisCoaf)? Caso sim, informar o número de comunicações suspeitas"
                    labelPlacement="outside"
                    variant="bordered"
                    fullWidth
                    classNames={{
                        base: cn("mt-8 mb-8"),
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.empresa_cadastrada_coaf ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.empresa_cadastrada_coaf}
                />
                <Textarea
                    id="auditoria_interna"
                    name="auditoria_interna"
                    value={formData.auditoria_interna}
                    onChange={handleChange}
                    label="Possui estrutura de Auditoria Interna? Caso sim, informar se é própria ou terceirizada (se terceirizada,
                        indicar o nome da empresa prestadora do serviço) realizadas nos últimos 12 meses"
                    labelPlacement="outside"
                    variant="bordered"
                    fullWidth
                    classNames={{
                        base: cn("mt-8 mb-8"),
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.auditoria_interna ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.auditoria_interna}
                />
                <div className="mb-2">
                    <label className="block font-medium text-gray-700">
                        <span>Modalidade de recebimento/pagamento:</span>{" "}
                    </label>
                </div>
                <ul className="list-disc text-gray-600">
                    <li>Organograma da estrutura organizacional e quantidade de funcionários</li>
                    <li>
                        Possui políticas e procedimentos voltados para prevenção à lavagem de
                        dinheiro e ao financiamento do terrorismo? Caso sim, compartilhar os
                        documentos/manuais.
                    </li>
                    <li>
                        Disponibilizar as Demonstrações Financeiras do último exercício encerrado.
                    </li>
                </ul>
                <Textarea
                    id="processadora_atual_nome_data_inicio_parceria"
                    name="processadora_atual_nome_data_inicio_parceria"
                    value={formData.processadora_atual_nome_data_inicio_parceria}
                    onChange={handleChange}
                    label="Processadora atual (nome e data de início da parceria)"
                    labelPlacement="outside"
                    variant="bordered"
                    fullWidth
                    classNames={{
                        base: cn("mt-8 mb-8"),
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.processadora_atual_nome_data_inicio_parceria ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.processadora_atual_nome_data_inicio_parceria}
                />
                <div>
                    <label
                        htmlFor="quantidade_transacoes_mensalmente"
                        className="block text-gray-700"
                    >
                        Quantidade de transações processadas mensalmente
                    </label>
                    <Input
                        id="quantidade_transacoes_mensalmente"
                        name="quantidade_transacoes_mensalmente"
                        value={formData.quantidade_transacoes_mensalmente}
                        onChange={handleChange}
                        type="number"
                        variant="bordered"
                        fullWidth
                        classNames={{
                            inputWrapper: "border-none p-0",
                            input: cn(
                                "resize-y min-h-[40px] rounded-md",
                                `mt-2 p-2 w-full border rounded-md ${formErrors?.quantidade_transacoes_mensalmente ? "border-red-500" : ""}`
                            ),
                            label: "w-full antialiased block text-gray-700 text-base"
                        }}
                        errorMessage={formErrors?.quantidade_transacoes_mensalmente}
                    />
                </div>
                <Textarea
                    id="ramo_atividades"
                    name="ramo_atividades"
                    value={formData.ramo_atividades}
                    onChange={handleChange}
                    label="Ramos de atividades (indústrias) que atua/atende no âmbito da atividade de facilitadora/intermediadora"
                    labelPlacement="outside"
                    variant="bordered"
                    fullWidth
                    classNames={{
                        base: cn("mt-8 mb-8"),
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.ramo_atividades ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.ramo_atividades}
                />
                <Textarea
                    id="merchants_acordo_comercial"
                    name="merchants_acordo_comercial"
                    value={formData.merchants_acordo_comercial}
                    onChange={handleChange}
                    label="Quem são os Merchants com os quais possuem acordo comercial"
                    labelPlacement="outside"
                    variant="bordered"
                    fullWidth
                    classNames={{
                        base: cn("mt-8 mb-8"),
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.merchants_acordo_comercial ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.merchants_acordo_comercial}
                />
                <Textarea
                    id="atua_atende_jogos_azar_apostas"
                    name="atua_atende_jogos_azar_apostas"
                    value={formData.atua_atende_jogos_azar_apostas}
                    onChange={handleChange}
                    label="Atua e/ou atende indústrias de jogos de azar, apostas e correlatos? Caso sim, explique"
                    labelPlacement="outside"
                    variant="bordered"
                    fullWidth
                    classNames={{
                        base: cn("mt-8 mb-8"),
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.atua_atende_jogos_azar_apostas ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.atua_atende_jogos_azar_apostas}
                />
                <Textarea
                    id="equipe_para_chargebacks"
                    name="equipe_para_chargebacks"
                    value={formData.equipe_para_chargebacks}
                    onChange={handleChange}
                    label="Possui equipe para análise e tratamento de chargebacks? Caso sim, explique"
                    labelPlacement="outside"
                    variant="bordered"
                    fullWidth
                    classNames={{
                        base: cn("mt-8 mb-8"),
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.equipe_para_chargebacks ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.equipe_para_chargebacks}
                />
            </div>
            <Divider className="mt-4 mb-8" />
            <div>
                <label className="block text-gray-700">
                    <span className="text-xl font-medium">Informações Adicionais</span>{" "}
                </label>
                <Textarea
                    id="informacoes_adicionais"
                    name="informacoes_adicionais"
                    value={formData.informacoes_adicionais}
                    onChange={handleChange}
                    variant="bordered"
                    fullWidth
                    classNames={{
                        base: cn("mt-8 mb-8"),
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[250px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.informacoes_adicionais ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.informacoes_adicionais}
                />
            </div>
            <Divider className="mt-4 mb-8" />
            <Button
                type="button"
                onClick={handleSubmit}
                color="primary"
                className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-200"
            >
                {loading && <Loader color={"white"} />}
                Enviar respostas
            </Button>
        </form>
    );
};

export default Form;
