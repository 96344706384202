export const RELOAD_ACCOUNT_HISTORY = [
    {
        id: 1,
        registerNumber: "#081451",
        date: "30 Jan 2021",
        amount: "400",
        status: "success"
    },
    {
        id: 2,
        registerNumber: "#081652",
        date: "20 Jan 2021",
        amount: "5000",
        status: "success"
    },
    {
        id: 57,
        registerNumber: "#081452",
        date: "20 Jan 2021",
        amount: "4000",
        status: "failure"
    },
    {
        id: 3,
        registerNumber: "#081681",
        date: "25 Jan 2021",
        amount: "4000",
        status: "failure"
    },
    {
        id: 4,
        registerNumber: "#091768",
        date: "15 Dec 2020",
        amount: "2200",
        status: "success"
    },
    {
        id: 5,
        registerNumber: "#082693",
        date: "06 Feb 2021",
        amount: "50000",
        status: "success"
    },
    {
        id: 6,
        registerNumber: "#084743",
        date: "27 Dec 2020",
        amount: "2000",
        status: "failure"
    },
    {
        id: 7,
        registerNumber: "#086643",
        date: "31 Dec 2020",
        amount: "34400",
        status: "success"
    },
    {
        id: 8,
        registerNumber: "#086773",
        date: "03 Jan 2021",
        amount: "40500",
        status: "success"
    },
    {
        id: 9,
        registerNumber: "#087916",
        date: "28 Jan 2021",
        amount: "14900",
        status: "success"
    },
    {
        id: 10,
        registerNumber: "#089472",
        date: "14 Jan 2021",
        amount: "10000",
        status: "success"
    },
    {
        id: 11,
        registerNumber: "#091768",
        date: "10 Feb 2021",
        amount: "23400",
        status: "success"
    },
    {
        id: 12,
        registerNumber: "#095841",
        date: "20 Dec 2020",
        amount: "104400",
        status: "success"
    }
];

export const LISTA_PARCEIROS = [
    {
        nome: "Mellone Law Firm",
        codigo: "mellone",
        link_direto: "https://suzancambio.com/parceiro/mellone",
        link_whitelabel: null,
        comissao: 0.0,
        spread: 0.0,
        turismo: false,
        remessa: false,
        mensagem:
            "Estou solicitando uma cotação para a remessa abaixo, por meio do escritório do *Dr. Mellone*."
    },
    {
        nome: "Open Maxx Solutions",
        codigo: "openmaxx",
        link_direto: "https://suzancambio.com/parceiro/openmaxx",
        link_whitelabel: null,
        comissao: 0.5,
        spread: 0.0,
        turismo: false,
        remessa: false,
        mensagem:
            "Venho através da *Open Maxx* e gostaria de solicitar uma cotação para a remessa abaixo, e agradeço a prioridade com a parceria."
    },
    {
        nome: "Jacqueline Nunez Maca",
        codigo: "jacquelinenunez",
        link_direto: "https://suzancambio.com/parceiro/jacquelinenunez",
        link_whitelabel: null,
        comissao: 0.0,
        spread: 0.0,
        turismo: false,
        remessa: false,
        mensagem:
            "Estou solicitando uma cotação para a remessa abaixo, por meio da *Jacqueline Nunez Maca - Serviço tradução juramentada - Itália*."
    },
    {
        nome: "Eliana Organizer",
        codigo: "elianaorganizer",
        link_direto: "https://suzancambio.com/parceiro/elianaorganizer",
        link_whitelabel: null,
        comissao: 0.0,
        spread: 0.0,
        turismo: false,
        remessa: false,
        mensagem:
            "Estou solicitando uma cotação para a remessa abaixo, pela indicação da *Eliana Organizer*."
    },
    {
        nome: "Cristiano Scholbi",
        codigo: "cristianoscholbi",
        link_direto: "https://suzancambio.com/parceiro/cristianoscholbi",
        link_whitelabel: null,
        comissao: 50.0,
        spread: 0.0,
        turismo: false,
        remessa: false,
        mensagem:
            "Estou solicitando uma cotação para a remessa abaixo, pela indicação da *Cristiano Scholbi*."
    },
    {
        nome: "Sara Cidadania",
        codigo: "saracidadania",
        link_direto: "https://suzancambio.com/parceiro/saracidadania",
        link_whitelabel: null,
        comissao: 50.0,
        spread: 0.0,
        turismo: false,
        remessa: false,
        mensagem:
            "Estou solicitando uma cotação para a remessa abaixo, pela indicação da *Sara Cidadania*."
    },
    {
        nome: "Vendendo Com Julinho",
        codigo: "vendendocomjulinho",
        link_direto: "https://suzancambio.com/parceiro/vendendocomjulinho",
        link_whitelabel: null,
        comissao: 50.0,
        spread: 0.0,
        turismo: false,
        remessa: false,
        mensagem:
            "Estou solicitando uma cotação para a remessa abaixo, pela indicação da *Vendendo Com Julinho*."
    }
];
