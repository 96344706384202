import { Button, cn, Input } from "@nextui-org/react";
import Cookies from "js-cookie";
import React, { useState, useEffect, useCallback } from "react";
import InputMask from "react-input-mask";
import "react-phone-number-input/style.css";

import { Loader } from "../../../../../components/utils";
import { toast } from "../../../../../helpers";

// import "./Form.css";

console.log("NODE_ENV:", process.env.NODE_ENV);

const defaultFormDataTeste = Cookies.get("formDataAlteracaoParceiroCambialPF")
    ? JSON.parse(Cookies.get("formDataAlteracaoParceiroCambialPF"))
    : {
          nome_cliente: "",
          email: "",
          cpf: "",
          cep: "",
          logradouro: "",
          numero: "",
          complemento: "",
          bairro: "",
          cidade: "",
          estado: ""
      };

const Form = ({ setData, setError }) => {
    const [formErrors, setFormErrors] = useState({
        nome_cliente: "",
        email: "",
        cpf: "",
        cep: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        estado: ""
    });
    const [loading, setLoading] = useState(false);

    //Form
    const [formData, setFormData] = useState(defaultFormDataTeste);

    const validateForm = useCallback(() => {
        const errors = {};
        if (!formData.nome_cliente?.trim()) errors.nome_cliente = "Campo obrigatório";
        if (!formData.email?.trim()) errors.email = "Campo obrigatório";
        if (!formData.cpf?.trim()) errors.cpf = "Campo obrigatório";
        if (!formData.cep?.trim()) errors.cep = "Campo obrigatório";
        if (!formData.logradouro?.trim()) errors.logradouro = "Campo obrigatório";
        if (!formData.numero?.trim()) errors.numero = "Campo obrigatório";
        if (!formData.bairro?.trim()) errors.bairro = "Campo obrigatório";
        if (!formData.cidade?.trim()) errors.cidade = "Campo obrigatório";
        if (!formData.estado?.trim()) errors.estado = "Campo obrigatório";
        setFormErrors(errors);
    }, [formData]);

    useEffect(() => {
        // Chamada da função de validação
        validateForm();
    }, [validateForm]);

    const handleChange = async e => {
        const name = e.target.name;
        const value = e.target.value;
        // Para todos os outros campos, atualiza o estado normalmente.
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleCepChange = async e => {
        const novoCep = e.target.value;
        setFormData({
            ...formData,
            ["cep"]: novoCep
        });

        if (novoCep.length === 8) {
            try {
                const response = await fetch(`https://viacep.com.br/ws/${novoCep}/json/`);
                const data = await response.json();

                if (!data.erro) {
                    setFormData({
                        ...formData,
                        ["logradouro"]: data.logradouro,
                        ["bairro"]: data.bairro,
                        ["cidade"]: data.localidade,
                        ["estado"]: data.uf
                    });
                    document.getElementsByName("numero")[0].focus();
                } else {
                    toast("error", "CEP não encontrado");
                    setFormData({
                        ...formData,
                        ["cep"]: "",
                        ["logradouro"]: "",
                        ["numero"]: "",
                        ["complemento"]: "",
                        ["bairro"]: "",
                        ["cidade"]: "",
                        ["estado"]: ""
                    });
                    document.getElementsByName("numero")[0].blur();
                }
            } catch (error) {
                console.error("Erro ao buscar o CEP:", error);
            }
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        const errors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            const userToken = process.env.REACT_APP_API_TOKEN;
            const url = `${process.env.REACT_APP_API_HOST}/formularios/bancobs2/pf/alteracao_parceiro_cambial`;

            const formDataTemp = {
                nome_cliente: formData.nome_cliente,
                email: formData.email,
                cpf: formData.cpf,
                cep: formData.cep,
                logradouro: formData.logradouro,
                numero: formData.numero,
                complemento: formData.complemento,
                bairro: formData.bairro,
                cidade: formData.cidade,
                estado: formData.estado
            };

            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${userToken}`
                    },
                    body: JSON.stringify(formDataTemp),
                    credentials: "include"
                });

                if (response.status === 200) {
                    const jsonData = await response.json();
                    setData(jsonData);

                    Cookies.set("fileExistsAlteracaoParceiroCambialPF", JSON.stringify(jsonData), {
                        expires: 7
                    });
                    Cookies.set(
                        "formDataAlteracaoParceiroCambialPF",
                        JSON.stringify(formDataTemp),
                        {
                            expires: 30
                        }
                    );

                    setError(null);
                } else {
                    toast(
                        "error",
                        `Erro ao enviar resposta\n\n${response.status} ${response.statusText}\n\nDúvidas? Entre em contato no WhatsApp +55 11 93211-4516`,
                        true
                    );
                }
            } catch (error) {
                console.error("Erro ao fazer a requisição:", error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        } else {
            setFormErrors(errors);
            setLoading(false);
            console.log(errors);
            if (errors) {
                toast("error", "Erro ao enviar resposta\nPreencha os campos obrigatórios.");
            } else {
                toast(
                    "error",
                    "Erro ao enviar resposta\nDúvidas? Entre em contato no WhatsApp +55 11 93211-4516",
                    true
                );
            }
        }
    };

    const Required = () => {
        return <span className="text-red-500 font-bold">*</span>;
    };
    return (
        <form
            className="bg-white p-8 rounded-lg shadow-lg w-full md:min-w-[400px] md:max-w-3xl mx-auto"
            autoComplete="off"
        >
            <p className="text-gray-500 mb-8 text-center">
                O Banco BS2 é a instituição financeira parceira da SuzanCâmbio na realização de
                transferências internacionais. Por isso, após o preenchimento deste formulário você
                receberá um e-mail com a Ficha de Alteração de Parceiro Cambial devidamente
                preenchida para conferência e assinatura digital.
                <br />
                Todos os dados informados neste formulário serão mantidos em total sigilo pela
                SuzanCâmbio e BS2, seguindo rigorosamente as diretrizes da lei de proteção geral de
                dados - LGPD.
            </p>
            <h2 className="text-2xl font-bold mb-6 text-gray-800 text-center">
                Alteração de Parceiro Cambial
            </h2>
            <div className="mb-4">
                <label htmlFor="nome_cliente" className="block text-gray-700">
                    Nome completo <Required />
                </label>
                <Input
                    id="nome_cliente"
                    name="nome_cliente"
                    value={formData.nome_cliente}
                    onChange={handleChange}
                    isRequired
                    variant="bordered"
                    fullWidth
                    classNames={{
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.nome_cliente ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.nome_cliente}
                />
            </div>
            <div className="mb-6">
                <label htmlFor="email" className="block text-gray-700">
                    Email: <Required />
                </label>
                <Input
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    isRequired
                    variant="bordered"
                    fullWidth
                    classNames={{
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.email ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.email}
                />
            </div>
            <div className="mb-6 w-full grid grid-cols-2 space-x-2">
                <div>
                    <label htmlFor="cpf" className="block text-gray-700">
                        CPF <Required />
                    </label>
                    <InputMask
                        mask={"999.999.999-99"}
                        value={formData.cpf}
                        onChange={handleChange}
                        autoComplete="nope"
                        id="cpf"
                        name="cpf"
                        aria-label="cpf"
                        className={`mt-2 p-2 w-full border rounded-md ${formErrors.cpf ? "border-red-500" : ""}`}
                    >
                        {inputProps => <input {...inputProps} type="tel" />}
                    </InputMask>
                    {formErrors.cpf && (
                        <p className="text-red-500 text-xs mt-1">{formErrors.cpf}</p>
                    )}
                </div>
            </div>
            <hr className="mt-8 mb-8" />
            <div className="mb-4">
                <label htmlFor="cep" className="block text-gray-700">
                    CEP: <Required />
                </label>
                <InputMask
                    mask={"99999999"}
                    maskChar={null}
                    value={formData.cep}
                    onChange={handleCepChange}
                    onBlur={handleCepChange}
                    id="cep"
                    name="cep"
                    className={`mt-2 p-2 w-50% border rounded-md ${formErrors.cep ? "border-red-500" : ""}`}
                >
                    {inputProps => <input {...inputProps} type="tel" />}
                </InputMask>
                {formErrors.cep && <p className="text-red-500 text-xs mt-1">{formErrors.cep}</p>}
            </div>
            <div className="mb-6 space-y-4">
                <div>
                    <label htmlFor="logradouro" className="block text-gray-700">
                        Logradouro residencial: <Required />
                    </label>
                    <Input
                        id="logradouro"
                        name="logradouro"
                        value={formData.logradouro}
                        onChange={handleChange}
                        isRequired
                        variant="bordered"
                        fullWidth
                        classNames={{
                            inputWrapper: "border-none p-0",
                            input: cn(
                                "resize-y min-h-[40px] rounded-md",
                                `mt-2 p-2 w-full border rounded-md ${formErrors?.logradouro ? "border-red-500" : ""}`
                            ),
                            label: "w-full antialiased block text-gray-700 text-base"
                        }}
                        errorMessage={formErrors?.logradouro}
                    />
                </div>
                <div>
                    <label htmlFor="numero" className="block text-gray-700">
                        Número residencial: <Required />
                    </label>
                    <Input
                        id="numero"
                        name="numero"
                        value={formData.numero}
                        onChange={handleChange}
                        isRequired
                        variant="bordered"
                        fullWidth
                        classNames={{
                            inputWrapper: "border-none p-0",
                            input: cn(
                                "resize-y min-h-[40px] rounded-md",
                                `mt-2 p-2 w-full border rounded-md ${formErrors?.numero ? "border-red-500" : ""}`
                            ),
                            label: "w-full antialiased block text-gray-700 text-base"
                        }}
                        errorMessage={formErrors?.numero}
                    />
                </div>
                <div>
                    <label htmlFor="complemento" className="block text-gray-700">
                        Complemento residencial:
                    </label>
                    <Input
                        id="complemento"
                        name="complemento"
                        value={formData.complemento}
                        onChange={handleChange}
                        variant="bordered"
                        fullWidth
                        classNames={{
                            inputWrapper: "border-none p-0",
                            input: cn(
                                "resize-y min-h-[40px] rounded-md",
                                `mt-2 p-2 w-full border rounded-md ${formErrors?.complemento ? "border-red-500" : ""}`
                            ),
                            label: "w-full antialiased block text-gray-700 text-base"
                        }}
                        errorMessage={formErrors?.complemento}
                    />
                </div>
                <div>
                    <label htmlFor="bairro" className="block text-gray-700">
                        Bairro residencial: <Required />
                    </label>
                    <Input
                        id="bairro"
                        name="bairro"
                        value={formData.bairro}
                        onChange={handleChange}
                        isRequired
                        variant="bordered"
                        fullWidth
                        classNames={{
                            inputWrapper: "border-none p-0",
                            input: cn(
                                "resize-y min-h-[40px] rounded-md",
                                `mt-2 p-2 w-full border rounded-md ${formErrors?.bairro ? "border-red-500" : ""}`
                            ),
                            label: "w-full antialiased block text-gray-700 text-base"
                        }}
                        errorMessage={formErrors?.bairro}
                    />
                </div>
                <div>
                    <label htmlFor="cidade" className="block text-gray-700">
                        Cidade residencial: <Required />
                    </label>
                    <Input
                        id="cidade"
                        name="cidade"
                        value={formData.cidade}
                        onChange={handleChange}
                        isRequired
                        variant="bordered"
                        fullWidth
                        classNames={{
                            inputWrapper: "border-none p-0",
                            input: cn(
                                "resize-y min-h-[40px] rounded-md",
                                `mt-2 p-2 w-full border rounded-md ${formErrors?.cidade ? "border-red-500" : ""}`
                            ),
                            label: "w-full antialiased block text-gray-700 text-base"
                        }}
                        errorMessage={formErrors?.cidade}
                    />
                </div>
                <div>
                    <label htmlFor="estado" className="block text-gray-700">
                        Estado (UF) residencial: <Required />
                    </label>
                    <Input
                        id="estado"
                        name="estado"
                        value={formData.estado}
                        onChange={handleChange}
                        isRequired
                        variant="bordered"
                        fullWidth
                        classNames={{
                            inputWrapper: "border-none p-0",
                            input: cn(
                                "resize-y min-h-[40px] rounded-md",
                                `mt-2 p-2 w-full border rounded-md ${formErrors?.estado ? "border-red-500" : ""}`
                            ),
                            label: "w-full antialiased block text-gray-700 text-base"
                        }}
                        errorMessage={formErrors?.estado}
                    />
                </div>
            </div>
            <hr className="mt-8 mb-8" />
            <Button
                type="button"
                onClick={handleSubmit}
                color="primary"
                className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-200"
            >
                {loading && <Loader color={"white"} />}
                Enviar respostas
            </Button>
        </form>
    );
};

export default Form;
