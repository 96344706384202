import { Menu, Transition } from "@headlessui/react";
import { Button, Divider, Input, Spacer, Textarea, cn } from "@nextui-org/react";
import { Loader } from "components/utils";
import { toast, moedas } from "helpers";
import Cookies from "js-cookie";
import { InputNumber } from "primereact/inputnumber";
import React, { useState, useEffect, useCallback } from "react";
import { HiChevronRight } from "react-icons/hi";
import InputMask from "react-input-mask";
import "react-phone-number-input/style.css";

const defaultFormDataTeste = Cookies.get("formDataTermoDoacao")
    ? JSON.parse(Cookies.get("formDataTermoDoacao"))
    : {
          nome: "",
          email: "",
          cpf: "",
          rua: "",
          numero: "",
          complemento: "",
          bairro: "",
          cep: "",
          cidade: "",
          uf: "",
          valor_numerico: "",
          moeda_plural: "",
          valor_extenso: "",
          nome_beneficiario: "",
          pais: "",
          dados_bancarios: ""
      };

console.log(defaultFormDataTeste);

const Required = () => {
    return <span className="text-red-500 font-bold">*</span>;
};

const CustomInput = ({ name, label, isRequired, spacer, handleChange, formData, formErrors }) => {
    const inputClassNames = cn(
        "resize-y min-h-[40px] rounded-md",
        `mt-2 p-2 w-full border rounded-md ${formErrors[name] ? "border-red-500" : ""}`
    );
    return (
        <>
            <label htmlFor={name} className="block text-gray-700">
                {label}: {isRequired && <Required />}
            </label>
            <Input
                id={name}
                name={name}
                value={formData[name]}
                onChange={handleChange}
                isRequired
                variant="bordered"
                fullWidth
                classNames={{
                    inputWrapper: "border-none p-0",
                    input: inputClassNames,
                    label: "w-full antialiased block text-gray-700 text-base"
                }}
                errorMessage={formErrors[name]}
            />
            {spacer && <Spacer y={spacer} />}
        </>
    );
};

const Form = ({ setData, setError }) => {
    const [formErrors, setFormErrors] = useState({
        nome: "",
        email: "",
        cpf: "",
        rua: "",
        numero: "",
        complemento: "",
        bairro: "",
        cep: "",
        cidade: "",
        uf: "",
        valor_numerico: "",
        moeda_plural: "",
        valor_extenso: "",
        nome_beneficiario: "",
        pais: "",
        dados_bancarios: ""
    });
    const [loading, setLoading] = useState(false);
    const [coin, setCoin] = useState(moedas[0]);

    //Form
    const [formData, setFormData] = useState(defaultFormDataTeste);

    const validateForm = useCallback(() => {
        const errors = {};
        // Exemplo de validação

        if (!formData.nome?.trim()) errors.nome = "Campo obrigatório";
        if (!formData.email?.trim()) errors.email = "Campo obrigatório";
        if (!formData.cpf?.trim()) errors.cpf = "Campo obrigatório";
        if (!formData.rua?.trim()) errors.rua = "Campo obrigatório";
        if (!formData.numero?.trim()) errors.numero = "Campo obrigatório";
        if (!formData.bairro?.trim()) errors.bairro = "Campo obrigatório";
        if (!formData.cep?.trim()) errors.cep = "Campo obrigatório";
        if (!formData.cidade?.trim()) errors.cidade = "Campo obrigatório";
        if (!formData.uf?.trim()) errors.uf = "Campo obrigatório";
        if (!(formData.valor_numerico && String(formData.valor_numerico).trim()))
            errors.valor_numerico = "Campo obrigatório";
        if (!coin.currency?.trim()) errors.moeda_plural = "Campo obrigatório";
        if (!formData.valor_extenso?.trim()) errors.valor_extenso = "Campo obrigatório";
        if (!formData.nome_beneficiario?.trim()) errors.nome_beneficiario = "Campo obrigatório";
        if (!formData.pais?.trim()) errors.pais = "Campo obrigatório";
        if (!formData.dados_bancarios?.trim()) errors.dados_bancarios = "Campo obrigatório";
        // Incluir outras validações conforme necessário...
        setFormErrors(errors);
        console.log(errors);
        console.log(formData);
    }, [formData, coin]);

    useEffect(() => {
        // Chamada da função de validação
        validateForm();
    }, [validateForm]);

    const handleChange = async e => {
        const name = e.target.name;
        const value = e.target.value;
        // Para todos os outros campos, atualiza o uf normalmente.
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleCepChange = async e => {
        const novoCep = e.target.value;
        setFormData({
            ...formData,
            ["cep"]: novoCep
        });

        if (novoCep.length === 8) {
            try {
                const response = await fetch(`https://viacep.com.br/ws/${novoCep}/json/`);
                const data = await response.json();
                // {
                //     "cep"         : "",
                //     "logradouro"  : "",
                //     "complemento" : "",
                //     "bairro"      : "",
                //     "localidade"  : "",
                //     "uf"          : "",
                //     "ibge"        : "",
                //     "gia"         : "",
                //     "ddd"         : "",
                //     "siafi"       : ""
                // }

                if (!data.erro) {
                    setFormData({
                        ...formData,
                        ["rua"]: data.logradouro,
                        ["complemento"]: data.complemento,
                        ["bairro"]: data.bairro,
                        ["cidade"]: data.localidade,
                        ["uf"]: data.uf
                    });
                    document.getElementsByName("numero")[0].focus();
                } else {
                    alert("CEP não encontrado.");
                }
            } catch (error) {
                console.error("Erro ao buscar o CEP:", error);
            }
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        const errors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            const userToken = process.env.REACT_APP_API_TOKEN;
            const url = `${process.env.REACT_APP_API_HOST}/formularios/bancobs2/pf/termo_doacao`;

            const formDataTemp = {
                nome: formData.nome,
                email: formData.email,
                cpf: formData.cpf,
                rua: formData.rua,
                numero: formData.numero,
                complemento: formData.complemento,
                bairro: formData.bairro,
                cep: formData.cep,
                cidade: formData.cidade,
                uf: formData.uf,
                valor_numerico: formData.valor_numerico,
                moeda_plural: coin.currency,
                valor_extenso: formData.valor_extenso,
                nome_beneficiario: formData.nome_beneficiario,
                pais: formData.pais,
                dados_bancarios: formData.dados_bancarios
            };

            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${userToken}`
                    },
                    body: JSON.stringify(formDataTemp),
                    credentials: "include"
                });

                if (response.status === 200) {
                    const jsonData = await response.json();
                    setData(jsonData);

                    Cookies.set("fileExistsTermoDoacao", JSON.stringify(jsonData), { expires: 7 });
                    Cookies.set("formDataTermoDoacao", JSON.stringify(formDataTemp), {
                        expires: 30
                    });

                    setError(null);
                } else {
                    toast(
                        "error",
                        `Erro ao enviar resposta\n${response.status} ${response.statusText}\nDúvidas? Entre em contato no WhatsApp +55 11 93211-4516`,
                        true
                    );
                }
            } catch (error) {
                console.error("Erro ao fazer a requisição:", error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        } else {
            setFormErrors(errors);
            setLoading(false);
            console.log(errors);
            if (errors) {
                toast("error", "Erro ao enviar resposta\nPreencha os campos obrigatórios.");
            } else {
                toast(
                    "error",
                    "Erro ao enviar resposta\nDúvidas? Entre em contato no WhatsApp +55 11 93211-4516",
                    true
                );
            }
        }
    };
    return (
        <form
            className="bg-white p-8 rounded-lg shadow-lg w-full md:min-w-[400px] md:max-w-3xl mx-auto"
            autoComplete="off"
        >
            <p className="text-gray-500 mb-8 text-justify">
                O Banco BS2 é a instituição financeira parceira da SuzanCâmbio na realização de
                transferências internacionais. Por isso, após o preenchimento desta declaração você
                receberá um e-mail com a declaração do BS2 devidamente preenchida para conferência e
                assinatura digital.
                <br />
                Todos os dados informados neste formulário serão mantidos em total sigilo pela
                SuzanCâmbio e BS2, seguindo rigorosamente as diretrizes da lei de proteção geral de
                dados - LGPD.
            </p>
            <h2 className="text-2xl font-bold mb-6 text-gray-800 text-center">
                Formulário - Termo de Doação
            </h2>
            <CustomInput
                name={"nome"}
                label={"Nome completo"}
                isRequired
                spacer={4}
                handleChange={handleChange}
                formData={formData}
                formErrors={formErrors}
            />
            <CustomInput
                name={"email"}
                label={"Email"}
                isRequired
                spacer={4}
                handleChange={handleChange}
                formData={formData}
                formErrors={formErrors}
            />
            <div className="mb-6 w-full grid grid-cols-2 space-x-2">
                <div>
                    <label htmlFor="cpf" className="block text-gray-700">
                        CPF <Required />
                    </label>
                    <InputMask
                        mask={"999.999.999-99"}
                        value={formData.cpf}
                        onChange={handleChange}
                        autoComplete="nope"
                        id="cpf"
                        name="cpf"
                        aria-label="cpf"
                        className={`mt-2 p-2 w-full border rounded-md ${formErrors.cpf ? "border-red-500" : ""}`}
                    >
                        {inputProps => <input {...inputProps} type="tel" />}
                    </InputMask>
                    {formErrors.cpf && (
                        <p className="text-red-500 text-xs mt-1">{formErrors.cpf}</p>
                    )}
                </div>
            </div>
            <Divider className="mt-8 mb-8" />
            <div className="mb-4 flex flex-cols space-x-4">
                <div>
                    <label htmlFor="cep" className="block text-gray-700">
                        CEP: <Required />
                    </label>
                    <InputMask
                        mask={"99999999"}
                        maskChar={null}
                        value={formData.cep}
                        autoComplete="nope"
                        onChange={handleCepChange}
                        onBlur={handleCepChange}
                        id="cep"
                        name="cep"
                        className={`mt-2 p-2 w-50% border rounded-md ${formErrors.cep ? "border-red-500" : ""}`}
                    >
                        {inputProps => <input {...inputProps} type="tel" />}
                    </InputMask>
                    {formErrors.cep && (
                        <p className="text-red-500 text-xs mt-1">{formErrors.cep}</p>
                    )}
                </div>
            </div>
            <div className="mb-6">
                <CustomInput
                    name={"rua"}
                    label={"Logradouro residencial"}
                    isRequired
                    spacer={4}
                    handleChange={handleChange}
                    formData={formData}
                    formErrors={formErrors}
                />
                <CustomInput
                    name={"numero"}
                    label={"Número residencial"}
                    isRequired
                    spacer={4}
                    handleChange={handleChange}
                    formData={formData}
                    formErrors={formErrors}
                />
                <CustomInput
                    name={"complemento"}
                    label={"Complemento residencial"}
                    spacer={4}
                    handleChange={handleChange}
                    formData={formData}
                    formErrors={formErrors}
                />
                <CustomInput
                    name={"bairro"}
                    label={"Bairro residencial"}
                    isRequired
                    spacer={4}
                    handleChange={handleChange}
                    formData={formData}
                    formErrors={formErrors}
                />
                <CustomInput
                    name={"cidade"}
                    label={"Cidade residencial"}
                    isRequired
                    spacer={4}
                    handleChange={handleChange}
                    formData={formData}
                    formErrors={formErrors}
                />
                <CustomInput
                    name={"uf"}
                    label={"Estado (UF) residencial"}
                    isRequired
                    spacer={4}
                    handleChange={handleChange}
                    formData={formData}
                    formErrors={formErrors}
                />
            </div>
            <hr className="mb-6" />
            <div className="mb-6">
                <h2>Informações do Beneficiário</h2>
            </div>
            <CustomInput
                name={"nome_beneficiario"}
                label={"Nome do(a) beneficiário(a)"}
                isRequired
                spacer={4}
                handleChange={handleChange}
                formData={formData}
                formErrors={formErrors}
            />
            <CustomInput
                name={"pais"}
                label={"País do(a) beneficiário(a)"}
                isRequired
                spacer={4}
                handleChange={handleChange}
                formData={formData}
                formErrors={formErrors}
            />
            <div className="mb-6 grid grid-cols-8 space-x-4">
                <div className="col-span-3">
                    <label htmlFor="moeda_plural" className="mb-2 block text-gray-700">
                        Moeda da doação: <Required />
                    </label>
                    <Menu>
                        {({ open }) => (
                            // <div className="relative w-1/4 md:w-1/4 mr-1">
                            <div className="">
                                <Menu.Button className="w-full shadow-sm flex items-center space-x-2 justify-center text-blue-900 bg-white py-3 md:py-2 border rounded-lg md:text-lg font-bold uppercase font-semibold">
                                    <img
                                        src={`/images/flags/${coin.img_rounded}`}
                                        className="rounded-full h-6 w-6"
                                        alt=""
                                    />
                                    <span>{coin.currency}</span>
                                    <HiChevronRight
                                        className={`transition-all duration-300 ${
                                            open ? "rotate-90" : ""
                                        } h-6 w-6`}
                                    />
                                </Menu.Button>

                                <Transition
                                    enter="transition duration-100 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-75 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0"
                                >
                                    <Menu.Items>
                                        <div className="right-0 z-10 px-2 py-1 mt-1 w-44 text-gray-600 bg-white border rounded-md shadow">
                                            {moedas.map((item, index) => (
                                                <Menu.Item
                                                    key={index}
                                                    as={"button"}
                                                    type="button"
                                                    className="flex items-center space-x-3 px-3 py-2 text-sm hover:text-blue-600"
                                                    onClick={() => setCoin(item)}
                                                >
                                                    <img
                                                        src={`/images/flags/${item.img_rounded}`}
                                                        className="rounded-full h-6 w-6"
                                                        alt=""
                                                    />
                                                    <span>{item.currency}</span>
                                                </Menu.Item>
                                            ))}
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </div>
                        )}
                    </Menu>
                    {formErrors.moeda_plural && (
                        <p className="text-red-500 text-xs mt-1">{formErrors.moeda_plural}</p>
                    )}
                </div>
                <div className="col-span-5">
                    <label htmlFor="valor_numerico" className="block text-gray-700">
                        Valor Doado: <Required />
                    </label>
                    <InputNumber
                        id="valor_numerico"
                        value={formData.valor_numerico}
                        name="valor_numerico"
                        onValueChange={handleChange}
                        inputMode="text"
                        type="text"
                        autoComplete="nope"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        className="w-full"
                        inputClassName={`mt-2 p-2 w-full border rounded-md ${formErrors.renda ? "border-red-500" : ""}`}
                    />
                    {formErrors.valor_numerico && (
                        <p className="text-red-500 text-xs mt-1">{formErrors.valor_numerico}</p>
                    )}
                </div>
            </div>
            <CustomInput
                name={"valor_extenso"}
                label={"Valor Doado (por extenso)"}
                isRequired
                spacer={4}
                handleChange={handleChange}
                formData={formData}
                formErrors={formErrors}
            />
            <Textarea
                id="dados_bancarios"
                name="dados_bancarios"
                value={formData.dados_bancarios}
                onChange={handleChange}
                isRequired
                label="Dados bancários do beneficiário:"
                labelPlacement="outside"
                variant="underlined"
                classNames={{
                    base: cn("mt-2 mb-8 p-2 w-full"),
                    input: cn(
                        "resize-y min-h-[40px] rounded-md",
                        `mt-2 p-2 w-full border rounded-md ${formErrors?.dados_bancarios ? "border-red-500" : ""}`
                    ),
                    label: "w-full antialiased block text-gray-700 text-xl font-medium"
                }}
            />
            <hr className="mt-8 mb-2" />
            <Button
                type="button"
                onClick={handleSubmit}
                color="primary"
                className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-200"
            >
                {loading && <Loader color={"white"} />}
                Enviar respostas
            </Button>
        </form>
    );
};

export default Form;
