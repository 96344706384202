import { Button } from "@nextui-org/react";
import Cookies from "js-cookie";
import { InputNumber } from "primereact/inputnumber";
import React, { useState } from "react";
import { HiMinus, HiPlus } from "react-icons/hi";
import InputMask from "react-input-mask";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

// import { Loader } from "../../../../components/utils";
import { maskDate, toast } from "../../../../helpers";

import "./FormBS2Pj.css";

const defaultFormDataTeste = Cookies.get("formDataCadastroPJ")
    ? JSON.parse(Cookies.get("formDataCadastroPJ"))
    : {
          razao_social: "",
          forma_constituicao: "",
          nome_fantasia: "",
          setor_atividade: "",
          cnpj: "",
          endereco: "",
          numero: "",
          complemento: "",
          bairro: "",
          cidade: "",
          uf: "",
          cep: "",
          endereco_corresp: "",
          bairro_corresp: "",
          cidade_corresp: "",
          uf_corresp: "",
          cep_corresp: "",
          telefone: "",
          email: "",
          cnae: "",
          atividade_principal: "",
          faturamento_bruto_anual: "",
          capital_social_integralizado: "",
          data_ultima_alteracao: "",
          socio1_nome: "",
          socio1_cnpj_cpf: "",
          socio1_participacao: "",
          socio2_nome: "",
          socio2_cnpj_cpf: "",
          socio2_participacao: "",
          socio3_nome: "",
          socio3_cnpj_cpf: "",
          socio3_participacao: "",
          socio4_nome: "",
          socio4_cnpj_cpf: "",
          socio4_participacao: "",
          socio5_nome: "",
          socio5_cnpj_cpf: "",
          socio5_participacao: "",
          beneficiario1_nome: "",
          beneficiario1_cargo: "",
          beneficiario1_cpf: "",
          data_beneficiario1_vigencia_mandato: "",
          beneficiario2_nome: "",
          beneficiario2_cargo: "",
          beneficiario2_cpf: "",
          data_beneficiario2_vigencia_mandato: "",
          beneficiario3_nome: "",
          beneficiario3_cargo: "",
          beneficiario3_cpf: "",
          data_beneficiario3_vigencia_mandato: "",
          beneficiario4_nome: "",
          beneficiario4_cargo: "",
          beneficiario4_cpf: "",
          data_beneficiario4_vigencia_mandato: "",
          beneficiario5_nome: "",
          beneficiario5_cargo: "",
          beneficiario5_cpf: "",
          data_beneficiario5_vigencia_mandato: ""
      };

const checkSocios = formData => {
    let qtdSocios = 1;
    for (let i = 2; i <= 4; i++) {
        const _nome = formData[`socio${i}_nome`];
        const _cnpj_cpf = formData[`socio${i}_cnpj_cpf`];
        const _participacao = formData[`socio${i}_participacao`];
        if (_nome || _cnpj_cpf || _participacao) {
            qtdSocios = qtdSocios + 1;
        }
    }
    return Array.from({ length: qtdSocios }, (_, index) => index + 1);
};

const checkBeneficiarios = formData => {
    let qtdBeneficiarios = 1;
    for (let i = 2; i <= 4; i++) {
        const _nome = formData[`beneficiario${i}_nome`];
        const _cnpj_cpf = formData[`beneficiario${i}_cnpj_cpf`];
        const _participacao = formData[`beneficiario${i}_participacao`];
        const _data_vigencia_mandato = formData[`data_beneficiario${i}_vigencia_mandato`];
        if (_nome || _cnpj_cpf || _participacao || _data_vigencia_mandato) {
            qtdBeneficiarios = qtdBeneficiarios + 1;
        }
    }
    return Array.from({ length: qtdBeneficiarios }, (_, index) => index + 1);
};

const FormBS2Pj = ({ setData, setError }) => {
    const [formErrors, setFormErrors] = useState({});
    const [loading, setLoading] = useState(false);

    //Form
    const [formData, setFormData] = useState(defaultFormDataTeste);

    const [socios, setSocios] = useState(checkSocios(formData));
    const [beneficiarios, setBeneficiarios] = useState(checkBeneficiarios(formData));

    const validateForm = () => {
        const errors = {};
        if (!formData.razao_social?.trim()) errors.razao_social = "Campo obrigatório";
        if (!formData.nome_fantasia?.trim()) errors.nome_fantasia = "Campo obrigatório";
        if (!formData.setor_atividade?.trim()) errors.setor_atividade = "Campo obrigatório";
        if (!formData.cnpj?.trim()) errors.cnpj = "Campo obrigatório";
        if (!formData.endereco?.trim()) errors.endereco = "Campo obrigatório";
        if (!formData.numero?.trim()) errors.numero = "Campo obrigatório";
        if (!formData.bairro?.trim()) errors.bairro = "Campo obrigatório";
        if (!formData.cidade?.trim()) errors.cidade = "Campo obrigatório";
        if (!formData.uf?.trim()) errors.uf = "Campo obrigatório";
        if (!formData.cep?.trim()) errors.cep = "Campo obrigatório";
        if (!formData.telefone?.trim()) errors.telefone = "Campo obrigatório";
        if (!formData.email?.trim()) errors.email = "Campo obrigatório";
        if (!formData.cnae?.trim()) errors.cnae = "Campo obrigatório";
        if (!formData.atividade_principal?.trim()) errors.atividade_principal = "Campo obrigatório";
        if (!String(formData.faturamento_bruto_anual)?.trim())
            errors.faturamento_bruto_anual = "Campo obrigatório";
        if (!String(formData.capital_social_integralizado)?.trim())
            errors.capital_social_integralizado = "Campo obrigatório";
        if (!formData.data_ultima_alteracao?.trim())
            errors.data_ultima_alteracao = "Campo obrigatório";
        {
            socios.map((_, index) => {
                if (!formData[`socio${index + 1}_nome`]?.trim())
                    errors[`socio${index + 1}_nome`] = "Campo obrigatório";
                if (!formData[`socio${index + 1}_cnpj_cpf`]?.trim())
                    errors[`socio${index + 1}_cnpj_cpf`] = "Campo obrigatório";
                if (!String(formData[`socio${index + 1}_participacao`])?.trim())
                    errors[`socio${index + 1}_participacao`] = "Campo obrigatório";
            });
        }
        if (!formData.beneficiario1_nome?.trim()) errors.beneficiario1_nome = "Campo obrigatório";
        if (!formData.beneficiario1_cargo?.trim()) errors.beneficiario1_cargo = "Campo obrigatório";
        if (!formData.beneficiario1_cpf?.trim()) errors.beneficiario1_cpf = "Campo obrigatório";
        if (!formData.data_beneficiario1_vigencia_mandato?.trim())
            errors.data_beneficiario1_vigencia_mandato = "Campo obrigatório";
        return errors;
    };

    const handleAddSocio = () => {
        if (socios.length < 4) {
            if (
                formData["socio1_nome"] &&
                formData["socio1_cnpj_cpf"] &&
                formData["socio1_participacao"]
            ) {
                setSocios(prevSocios => [...prevSocios, prevSocios.length + 1]);
                toast("success", "Sócio adicionado!");
            } else {
                toast("error", "Preencha os dados do sócio obrigatório!");
            }
        } else {
            toast("info", "Número máximo de sócios atingido!");
        }
    };

    const handleRemoveSocio = index => {
        const atual = index + 1;
        const prox_ord = index + 2;
        if (socios.length > 0) {
            setSocios(prevSocios => prevSocios.filter((_, i) => i !== index));
            setFormData({
                ...formData,
                [`socio${atual}_nome`]: formData[`socio${prox_ord}_nome`],
                [`socio${atual}_cnpj_cpf`]: formData[`socio${prox_ord}_cnpj_cpf`],
                [`socio${atual}_participacao`]: formData[`socio${prox_ord}_participacao`],
                [`socio${prox_ord}_nome`]: "",
                [`socio${prox_ord}_cnpj_cpf`]: "",
                [`socio${prox_ord}_participacao`]: ""
            });
            toast("warning", `Sócio ${atual} removido!`);
        }
    };

    const handleAddBeneficiario = () => {
        if (beneficiarios.length < 4) {
            if (
                formData["beneficiario1_nome"] &&
                formData["beneficiario1_cpf"] &&
                formData["beneficiario1_cargo"]
            ) {
                setBeneficiarios(prevBeneficiarios => [
                    ...prevBeneficiarios,
                    prevBeneficiarios.length + 1
                ]);
                toast("success", "Diretor adicionado!");
            } else {
                toast("error", "Preencha os dados do diretor obrigatório!");
            }
        } else {
            toast("info", "Número máximo de diretores atingido!");
        }
    };

    const handleRemoveBeneficiario = index => {
        const atual = index + 1;
        const prox_ord = index + 2;
        if (beneficiarios.length > 0) {
            setBeneficiarios(prevBeneficiarios => prevBeneficiarios.filter((_, i) => i !== index));
            setFormData({
                ...formData,
                [`beneficiario${atual}_nome`]: formData[`beneficiario${prox_ord}_nome`],
                [`beneficiario${atual}_cpf`]: formData[`beneficiario${prox_ord}_cpf`],
                [`beneficiario${atual}_cargo`]: formData[`beneficiario${prox_ord}_cargo`],
                [`beneficiario${prox_ord}_nome`]: "",
                [`beneficiario${prox_ord}_cpf`]: "",
                [`beneficiario${prox_ord}_cargo`]: ""
            });
            toast("warning", `Diretor ${atual} removido!`);
        }
    };

    const handleChangePhoneInput = e => {
        setFormData({
            ...formData,
            ["telefone"]: e
        });
        const errors = validateForm();
        setFormErrors(errors);
    };

    const handleChange = e => {
        const name = e.target.name;
        let value = e.target.value;
        if (name.startsWith("capital_social_integralizado") && !value) value = 0;
        if (name.startsWith("faturamento_bruto_anual") && !value) return "";
        if (name.startsWith("socio1_participacao") && !value) return "";
        if (name.startsWith("socio2_participacao") && !value) return "";
        if (name.startsWith("socio3_participacao") && !value) return "";
        if (name.startsWith("socio4_participacao") && !value) return "";
        if (name.startsWith("data")) {
            setFormData({
                ...formData,
                [name]: maskDate(value)
            });
            const errors = validateForm();
            setFormErrors(errors);
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
            const errors = validateForm();
            setFormErrors(errors);
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        const errors = validateForm();
        setLoading(true);
        if (Object.keys(errors).length === 0) {
            const userToken = process.env.REACT_APP_API_TOKEN;
            const url = `${process.env.REACT_APP_API_HOST}/cadastro/bs2/pj`;

            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${userToken}`
                    },
                    body: JSON.stringify(formData),
                    credentials: "include"
                });

                if (response.status === 200) {
                    const jsonData = await response.json();
                    setData(jsonData);

                    Cookies.set("fileExistsCadastroPJ", JSON.stringify(jsonData), { expires: 7 });
                    Cookies.set("formDataCadastroPJ", JSON.stringify(formData), { expires: 30 });

                    setError(null);
                } else {
                    throw new Error(`Error: ${response.status} ${response.statusText}`);
                }
            } catch (error) {
                console.error("Erro ao fazer a requisição:", error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        } else {
            setFormErrors(errors);
            setLoading(false);
            console.log(errors);
            if (errors) {
                toast("error", "Erro ao enviar resposta\nPreencha os campos obrigatórios.");
            } else {
                toast(
                    "error",
                    "Erro ao enviar resposta\nDúvidas? Entre em contato no WhatsApp +55 11 93211-4516",
                    true
                );
            }
        }
    };

    const Required = () => {
        return <span className="text-red-500 font-bold">*</span>;
    };

    const handleCepChange = async e => {
        const novoCep = e.target.value;
        setFormData({
            ...formData,
            ["cep"]: novoCep
        });

        if (novoCep.length === 8) {
            try {
                const response = await fetch(`https://viacep.com.br/ws/${novoCep}/json/`);
                const data = await response.json();

                if (!data.erro) {
                    setFormData({
                        ...formData,
                        ["endereco"]: data.logradouro,
                        ["bairro"]: data.bairro,
                        ["cidade"]: data.localidade,
                        ["uf"]: data.uf
                    });
                    document.getElementsByName("numero")[0].focus();
                } else {
                    alert("CEP não encontrado.");
                }
            } catch (error) {
                console.error("Erro ao buscar o CEP:", error);
            }
        }

        const errors = validateForm();
        setFormErrors(errors);
    };
    return (
        <form
            onSubmit={handleSubmit}
            className="bg-white p-8 rounded-lg shadow-lg w-full md:min-w-[400px] md:max-w-3xl mx-auto"
            autoComplete="off"
        >
            <p className="text-gray-500 mb-8 text-center">
                O Banco BS2 é a instituição financeira parceira da SuzanCâmbio na realização de
                transferências internacionais. Por isso, após o preenchimento deste formulário você
                receberá um e-mail com a Ficha Cadastral do BS2 devidamente preenchida para
                conferência e assinatura digital.
                <br />
                Todos os dados informados neste formulário serão mantidos em total sigilo pela
                SuzanCâmbio e BS2, seguindo rigorosamente as diretrizes da lei de proteção geral de
                dados - LGPD.
            </p>
            <h2 className="text-2xl font-bold mb-6 text-gray-800 text-center">
                Cadastro Pessoa Jurídica
            </h2>
            <div className="mb-4">
                <label htmlFor="razao_social" className="block text-gray-700">
                    Razão Social <Required />
                </label>
                <input
                    id="razao_social"
                    name="razao_social"
                    type="text"
                    value={formData.razao_social}
                    onChange={handleChange}
                    autoComplete="nope"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.razao_social ? "border-red-500" : ""}`}
                />
                {formErrors.razao_social && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.razao_social}</p>
                )}
            </div>
            <div className="mb-4">
                <label htmlFor="nome_fantasia" className="block text-gray-700">
                    Nome Fantasia <Required />
                </label>
                <input
                    id="nome_fantasia"
                    name="nome_fantasia"
                    type="text"
                    value={formData.nome_fantasia}
                    onChange={handleChange}
                    autoComplete="nope"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.nome_fantasia ? "border-red-500" : ""}`}
                />
                {formErrors.nome_fantasia && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.nome_fantasia}</p>
                )}
            </div>
            <div className="mb-4 w-full">
                <label htmlFor="cnpj" className="block text-gray-700">
                    CNPJ <Required />
                </label>
                <InputMask
                    mask={"99.999.999/9999-99"}
                    value={formData.cnpj}
                    onChange={handleChange}
                    autoComplete="nope"
                    id="cnpj"
                    name="cnpj"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.cnpj ? "border-red-500" : ""}`}
                >
                    {inputProps => <input {...inputProps} type="tel" />}
                </InputMask>
                {formErrors.cnpj && <p className="text-red-500 text-xs mt-1">{formErrors.cnpj}</p>}
            </div>
            <div className="mb-4">
                <label htmlFor="forma_constituicao" className="block text-gray-700">
                    Forma de Constituição
                </label>
                <input
                    id="forma_constituicao"
                    name="forma_constituicao"
                    type="text"
                    value={formData.forma_constituicao}
                    onChange={handleChange}
                    autoComplete="nope"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.forma_constituicao ? "border-red-500" : ""}`}
                />
                {formErrors.forma_constituicao && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.forma_constituicao}</p>
                )}
            </div>
            <div className="mb-4">
                <label htmlFor="setor_atividade" className="block text-gray-700">
                    Setor Atividade <Required />
                </label>
                <input
                    id="setor_atividade"
                    name="setor_atividade"
                    type="text"
                    value={formData.setor_atividade}
                    onChange={handleChange}
                    autoComplete="nope"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.setor_atividade ? "border-red-500" : ""}`}
                />
                {formErrors.setor_atividade && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.setor_atividade}</p>
                )}
            </div>
            <div className="mb-4">
                <label htmlFor="atividade_principal" className="block text-gray-700">
                    Atividade Principal <Required />
                </label>
                <input
                    id="atividade_principal"
                    name="atividade_principal"
                    type="text"
                    value={formData.atividade_principal}
                    onChange={handleChange}
                    autoComplete="nope"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.atividade_principal ? "border-red-500" : ""}`}
                />
                {formErrors.atividade_principal && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.atividade_principal}</p>
                )}
            </div>
            <div className="mb-4">
                <label htmlFor="cnae" className="block text-gray-700">
                    Classificação CNAE <Required />
                </label>
                <input
                    id="cnae"
                    name="cnae"
                    type="text"
                    value={formData.cnae}
                    onChange={handleChange}
                    autoComplete="nope"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.cnae ? "border-red-500" : ""}`}
                />
                {formErrors.cnae && <p className="text-red-500 text-xs mt-1">{formErrors.cnae}</p>}
            </div>
            <div className="mb-4">
                <label htmlFor="telefone" className="text-sm font-medium text-blue-900">
                    Telefone/Celular: <Required />
                </label>
                <PhoneInput
                    international={true}
                    countryCallingCodeEditable={true}
                    defaultCountry="BR"
                    value={formData.telefone}
                    onChange={handleChangePhoneInput}
                    autoComplete="nope"
                    error={
                        formData.telefone
                            ? isValidPhoneNumber(formData.telefone)
                                ? undefined
                                : "Telefone inválido"
                            : "Número de telefone obrigatório"
                    }
                    id="telefone"
                    name="telefone"
                    className="rounded-md"
                    limitMaxLength
                />

                {formErrors.telefone && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.telefone}</p>
                )}
            </div>
            <div className="mb-6">
                <label htmlFor="email" className="block text-gray-700">
                    Email: <Required />
                </label>
                <input
                    id="email"
                    name="email"
                    type="text"
                    value={formData.email}
                    onChange={handleChange}
                    autoComplete="nope"
                    placeholder=" "
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.email ? "border-red-500" : ""}`}
                />
                {formErrors.email && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.email}</p>
                )}
            </div>
            <div className="mb-6">
                <label htmlFor="cep" className="block text-gray-700">
                    CEP: <Required />
                </label>
                <InputMask
                    mask={"99999999"}
                    maskChar={null}
                    value={formData.cep}
                    onChange={handleCepChange}
                    onBlur={handleCepChange}
                    id="cep"
                    name="cep"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.cep ? "border-red-500" : ""}`}
                >
                    {inputProps => <input {...inputProps} type="tel" />}
                </InputMask>
                {formErrors.cep && <p className="text-red-500 text-xs mt-1">{formErrors.cep}</p>}
            </div>
            <div className="mb-6">
                <label htmlFor="endereco" className="block text-gray-700">
                    Endereço: <Required />
                </label>
                <input
                    type="text"
                    name="endereco"
                    value={formData.endereco}
                    onChange={handleChange}
                    autoComplete="nope"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.endereco ? "border-red-500" : ""}`}
                />
                {formErrors.endereco && (
                    <p className="text-red-500 text-xs mt-1 mb-2">{formErrors.endereco}</p>
                )}
                <label htmlFor="numero" className="block text-gray-700">
                    Número: <Required />
                </label>
                <input
                    type="text"
                    name="numero"
                    value={formData.numero}
                    onChange={handleChange}
                    autoComplete="nope"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.numero ? "border-red-500" : ""}`}
                />
                {formErrors.numero && (
                    <p className="text-red-500 text-xs mt-1 mb-2">{formErrors.numero}</p>
                )}
                <label htmlFor="complemento" className="block text-gray-700">
                    Complemento:
                </label>
                <input
                    type="text"
                    name="complemento"
                    value={formData.complemento}
                    onChange={handleChange}
                    autoComplete="nope"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.complemento ? "border-red-500" : ""}`}
                />
                {formErrors.complemento && (
                    <p className="text-red-500 text-xs mt-1 mb-2">{formErrors.complemento}</p>
                )}
                <label htmlFor="bairro" className="block text-gray-700">
                    Bairro: <Required />
                </label>
                <input
                    type="text"
                    name="bairro"
                    value={formData.bairro}
                    onChange={handleChange}
                    autoComplete="nope"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.bairro ? "border-red-500" : ""}`}
                />
                {formErrors.bairro && (
                    <p className="text-red-500 text-xs mt-1 mb-2">{formErrors.bairro}</p>
                )}
                <label htmlFor="cidade" className="block text-gray-700">
                    Cidade: <Required />
                </label>
                <input
                    type="text"
                    name="cidade"
                    value={formData.cidade}
                    onChange={handleChange}
                    autoComplete="nope"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.cidade ? "border-red-500" : ""}`}
                />
                {formErrors.cidade && (
                    <p className="text-red-500 text-xs mt-1 mb-2">{formErrors.cidade}</p>
                )}
                <label htmlFor="uf" className="block text-gray-700">
                    Estado (UF): <Required />
                </label>
                <input
                    type="text"
                    name="uf"
                    value={formData.uf}
                    onChange={handleChange}
                    autoComplete="nope"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.uf ? "border-red-500" : ""}`}
                />
                {formErrors.uf && <p className="text-red-500 text-xs mt-1 mb-2">{formErrors.uf}</p>}
            </div>
            <div className="mb-6">
                <label htmlFor="faturamento_bruto_anual" className="block text-gray-700">
                    Faturamento Bruto Anual: <Required />
                </label>
                <InputNumber
                    id="faturamento_bruto_anual"
                    value={formData.faturamento_bruto_anual}
                    name="faturamento_bruto_anual"
                    onValueChange={handleChange}
                    autoComplete="nope"
                    mode="decimal"
                    locale="pt-BR"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    className="w-full"
                    inputClassName={`mt-2 p-2 w-full border rounded-md ${formErrors.faturamento_bruto_anual ? "border-red-500" : ""}`}
                />
                {formErrors.faturamento_bruto_anual && (
                    <p className="text-red-500 text-xs mt-1">
                        {formErrors.faturamento_bruto_anual}
                    </p>
                )}
            </div>
            <div className="mb-6">
                <label htmlFor="capital_social_integralizado" className="block text-gray-700">
                    Capital Social Integralizado: <Required />
                </label>
                <InputNumber
                    id="capital_social_integralizado"
                    value={formData.capital_social_integralizado}
                    name="capital_social_integralizado"
                    onValueChange={handleChange}
                    autoComplete="nope"
                    mode="decimal"
                    locale="pt-BR"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    className="w-full"
                    inputClassName={`mt-2 p-2 w-full border rounded-md ${formErrors.capital_social_integralizado ? "border-red-500" : ""}`}
                />
                {formErrors.capital_social_integralizado && (
                    <p className="text-red-500 text-xs mt-1">
                        {formErrors.capital_social_integralizado}
                    </p>
                )}
            </div>

            <div className="flex flex-cols justify-left relative z-0 w-full mb-5">
                <div className="relative z-0 w-full group">
                    <label htmlFor="data_ultima_alteracao" className="block text-gray-700">
                        Data Última Alteração: <Required />
                    </label>
                    <InputMask
                        mask={"99/99/9999"}
                        placeholder="dd/mm/aaaa"
                        maskChar={null}
                        value={formData.data_ultima_alteracao}
                        onChange={handleChange}
                        autoComplete="nope"
                        id="data_ultima_alteracao"
                        name="data_ultima_alteracao"
                        className={`mt-2 p-2 w-full border rounded-md ${formErrors.data_ultima_alteracao ? "border-red-500" : ""}`}
                    >
                        {inputProps => (
                            <input {...inputProps} type="tel" placeholder="dd/mm/aaaa" />
                        )}
                    </InputMask>
                    {formErrors.data_ultima_alteracao && (
                        <p className="text-red-500 text-xs mt-1">
                            {formErrors.data_ultima_alteracao}
                        </p>
                    )}
                </div>
            </div>
            <hr className="mt-8 mb-8" />
            <p className="text-xl font-medium mb-4">ACIONISTAS / SÓCIOS</p>
            <div className="bg-white p-8 rounded-lg shadow-lg min-w-[400px] max-w-3xl">
                {socios.map((_, index) => (
                    <>
                        <div className="text-lg flex items-center font-medium mb-4 space-x-4">
                            <p className="text-left">Sócio {index + 1}</p>
                            {index > 0 && (
                                <Button
                                    size="sm"
                                    radius="full"
                                    color="danger"
                                    isIconOnly
                                    className="w-4"
                                    onPress={() => handleRemoveSocio(index)}
                                >
                                    <HiMinus />
                                </Button>
                            )}
                        </div>
                        <div className="mb-2 md:grid md:grid-cols-6 md:space-x-4">
                            <div className="mb-2 md:col-span-3">
                                <label
                                    htmlFor={`socio${index + 1}_nome`}
                                    className="block text-gray-700 text-sm"
                                >
                                    Nome <Required />
                                </label>
                                <input
                                    id={`socio${index + 1}_nome`}
                                    name={`socio${index + 1}_nome`}
                                    type="text"
                                    value={formData[`socio${index + 1}_nome`]}
                                    onChange={handleChange}
                                    autoComplete="nope"
                                    className={`mt-2 p-2 w-full border rounded-md ${formErrors[`socio${index + 1}_nome`] ? "border-red-500" : ""}`}
                                />
                                {formErrors[`socio${index + 1}_nome`] && (
                                    <p className="text-red-500 text-xs mt-1">
                                        {formErrors[`socio${index + 1}_nome`]}
                                    </p>
                                )}
                            </div>
                            <div className="mb-2 md:col-span-2">
                                <label
                                    htmlFor={`socio${index + 1}_cnpj_cpf`}
                                    className="block text-gray-700 text-sm"
                                >
                                    CNPJ/CPF <Required />
                                </label>
                                <InputMask
                                    mask={
                                        formData[`socio${index + 1}_cnpj_cpf`]?.length < 15
                                            ? "999.999.999-999"
                                            : "99.999.999/9999-99"
                                    }
                                    maskChar=""
                                    value={formData[`socio${index + 1}_cnpj_cpf`]}
                                    onChange={handleChange}
                                    autoComplete="nope"
                                    id={`socio${index + 1}_cnpj_cpf`}
                                    name={`socio${index + 1}_cnpj_cpf`}
                                    className={`mt-2 p-2 w-full border rounded-md ${formErrors[`socio${index + 1}_cnpj_cpf`] ? "border-red-500" : ""}`}
                                >
                                    {inputProps => <input {...inputProps} type="tel" />}
                                </InputMask>
                                {formErrors[`socio${index + 1}_cnpj_cpf`] && (
                                    <p className="text-red-500 text-xs mt-1">
                                        {formErrors[`socio${index + 1}_cnpj_cpf`]}
                                    </p>
                                )}
                            </div>
                            <div className="mb-2 md:col-span-1">
                                <label
                                    htmlFor={`socio${index + 1}_participacao`}
                                    className="block text-gray-700 text-sm"
                                >
                                    Participação <Required />
                                </label>
                                <InputNumber
                                    id={`socio${index + 1}_participacao`}
                                    name={`socio${index + 1}_participacao`}
                                    value={formData[`socio${index + 1}_participacao`]}
                                    type="text"
                                    onValueChange={handleChange}
                                    autoComplete="nope"
                                    mode="decimal"
                                    locale="pt-BR"
                                    minFractionDigits={0}
                                    maxFractionDigits={2}
                                    suffix="%"
                                    min={0}
                                    max={100}
                                    step={5}
                                    className="w-full"
                                    inputClassName={`mt-2 p-2 w-full border rounded-md ${formErrors[`socio${index + 1}_participacao`] ? "border-red-500" : ""}`}
                                />
                                {formErrors[`socio${index + 1}_participacao`] && (
                                    <p className="text-red-500 text-xs mt-1">
                                        {formErrors[`socio${index + 1}_participacao`]}
                                    </p>
                                )}
                            </div>
                        </div>
                    </>
                ))}
                {socios.length < 4 && (
                    <Button
                        size="sm"
                        radius="full"
                        color="primary"
                        variant="ghost"
                        startContent={<HiPlus />}
                        onPress={handleAddSocio}
                    >
                        Adicionar um sócio
                    </Button>
                )}
            </div>
            <hr className="mt-8 mb-8" />
            <p className="text-xl font-medium mb-4">DIRETORES / PROCURADORES</p>
            <div className="bg-white p-8 rounded-lg shadow-lg min-w-[400px] max-w-3xl">
                {beneficiarios.map((_, index) => (
                    <>
                        <div className="text-lg flex items-center font-medium mb-4 space-x-4">
                            <p className="text-left">Diretor {index + 1}</p>
                            {index > 0 && (
                                <Button
                                    size="sm"
                                    radius="full"
                                    color="danger"
                                    isIconOnly
                                    className="w-4"
                                    onPress={() => handleRemoveBeneficiario(index)}
                                >
                                    <HiMinus />
                                </Button>
                            )}
                        </div>
                        <div className="mb-2 md:grid md:grid-cols-6">
                            <div className="mb-2 mr-4 md:col-span-3">
                                <label
                                    htmlFor={`beneficiario${index + 1}_nome`}
                                    className="block text-gray-700 text-sm"
                                >
                                    Nome <Required />
                                </label>
                                <input
                                    id={`beneficiario${index + 1}_nome`}
                                    name={`beneficiario${index + 1}_nome`}
                                    type="text"
                                    value={formData[`beneficiario${index + 1}_nome`]}
                                    onChange={handleChange}
                                    autoComplete="nope"
                                    className={`mt-2 p-2 w-full border rounded-md ${formErrors[`beneficiario${index + 1}_nome`] ? "border-red-500" : ""}`}
                                />
                                {formErrors[`beneficiario${index + 1}_nome`] && (
                                    <p className="text-red-500 text-xs mt-1">
                                        {formErrors[`beneficiario${index + 1}_nome`]}
                                    </p>
                                )}
                            </div>
                            <div className="mb-2 mr-4 md:col-span-3">
                                <label
                                    htmlFor={`beneficiario${index + 1}_cpf`}
                                    className="block text-gray-700 text-sm"
                                >
                                    CPF <Required />
                                </label>
                                <InputMask
                                    mask={"999.999.999-99"}
                                    value={formData[`beneficiario${index + 1}_cpf`]}
                                    onChange={handleChange}
                                    autoComplete="nope"
                                    id={`beneficiario${index + 1}_cpf`}
                                    name={`beneficiario${index + 1}_cpf`}
                                    className={`mt-2 p-2 w-full border rounded-md ${formErrors[`beneficiario${index + 1}_cpf`] ? "border-red-500" : ""}`}
                                >
                                    {inputProps => <input {...inputProps} type="tel" />}
                                </InputMask>
                                {formErrors[`beneficiario${index + 1}_cpf`] && (
                                    <p className="text-red-500 text-xs mt-1">
                                        {formErrors[`beneficiario${index + 1}_cpf`]}
                                    </p>
                                )}
                            </div>
                            <div className="mb-2 mr-4 md:col-span-3">
                                <label
                                    htmlFor={`beneficiario${index + 1}_cargo`}
                                    className="block text-gray-700 text-sm"
                                >
                                    Cargo <Required />
                                </label>
                                <input
                                    id={`beneficiario${index + 1}_cargo`}
                                    name={`beneficiario${index + 1}_cargo`}
                                    type="text"
                                    value={formData[`beneficiario${index + 1}_cargo`]}
                                    onChange={handleChange}
                                    autoComplete="nope"
                                    className={`mt-2 p-2 w-full border rounded-md ${formErrors[`beneficiario${index + 1}_cargo`] ? "border-red-500" : ""}`}
                                />
                                {formErrors[`beneficiario${index + 1}_cargo`] && (
                                    <p className="text-red-500 text-xs mt-1">
                                        {formErrors[`beneficiario${index + 1}_cargo`]}
                                    </p>
                                )}
                            </div>
                            <div className="mb-2 mr-4 md:col-span-3">
                                <div className="relative z-0 w-full group">
                                    <label
                                        htmlFor={`data_beneficiario${index + 1}_vigencia_mandato`}
                                        className="block text-gray-700"
                                    >
                                        Vigência do Mandato: <Required />
                                    </label>
                                    <InputMask
                                        mask={"99/99/9999"}
                                        placeholder="dd/mm/aaaa"
                                        maskChar={null}
                                        value={
                                            formData[
                                                `data_beneficiario${index + 1}_vigencia_mandato`
                                            ]
                                        }
                                        onChange={handleChange}
                                        autoComplete="nope"
                                        id={`data_beneficiario${index + 1}_vigencia_mandato`}
                                        name={`data_beneficiario${index + 1}_vigencia_mandato`}
                                        className={`mt-2 p-2 w-full border rounded-md ${formErrors[`data_beneficiario${index + 1}_vigencia_mandato`] ? "border-red-500" : ""}`}
                                    >
                                        {inputProps => (
                                            <input
                                                {...inputProps}
                                                type="tel"
                                                placeholder="dd/mm/aaaa"
                                            />
                                        )}
                                    </InputMask>
                                    {formErrors[
                                        `data_beneficiario${index + 1}_vigencia_mandato`
                                    ] && (
                                        <p className="text-red-500 text-xs mt-1">
                                            {
                                                formErrors[
                                                    `data_beneficiario${index + 1}_vigencia_mandato`
                                                ]
                                            }
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                ))}
                {beneficiarios.length < 4 && (
                    <Button
                        size="sm"
                        radius="full"
                        color="primary"
                        variant="ghost"
                        startContent={<HiPlus />}
                        onPress={handleAddBeneficiario}
                    >
                        Adicionar um diretor
                    </Button>
                )}
            </div>
            <Button
                type="submit"
                color="primary"
                className="mt-4 w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-200"
                isLoading={loading}
            >
                Enviar respostas
            </Button>
        </form>
    );
};

export default FormBS2Pj;
