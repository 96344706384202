import { Button, Checkbox, Chip, Divider, Input, Textarea, cn } from "@nextui-org/react";
import { Loader } from "components/utils";
import { toast } from "helpers";
import Cookies from "js-cookie";
import React, { useState, useEffect, useCallback } from "react";
import InputMask from "react-input-mask";
import "react-phone-number-input/style.css";

const defaultFormDataTeste = Cookies.get("formDataKnowYourClientPF")
    ? JSON.parse(Cookies.get("formDataKnowYourClientPF"))
    : {
          nome: "",
          cpf: "",
          email: "",
          atividade_economica: "",
          proposito_relacao_negocios_comercio_exterior: "",
          proposito_relacao_negocios_capitais_brasileiros: "",
          proposito_relacao_negocios_capitais_estrangeiros: "",
          proposito_relacao_negocios_servicos_diretos: "",
          proposito_relacao_negocios_seguros: "",
          finalidade_remessas: "",
          nivel_operacional_pretendido_opcao1: "",
          nivel_operacional_pretendido_opcao2: "",
          nivel_operacional_pretendido_opcao3: "",
          nivel_operacional_pretendido_opcao4: "",
          pagadores_recebedores_exterior: "",
          como_cliente_foi_captado: "",
          tempo_relacionamento_cliente: "",
          observacoes: "",
          cidade_residencial: "",
          estado_residencial: "",
          assinatura_gestor: ""
      };

const Form = ({ setData, setError }) => {
    const [formErrors, setFormErrors] = useState({
        nome: "",
        cpf: "",
        email: "",
        atividade_economica: "",
        proposito_relacao_negocios_comercio_exterior: "",
        proposito_relacao_negocios_capitais_brasileiros: "",
        proposito_relacao_negocios_capitais_estrangeiros: "",
        proposito_relacao_negocios_servicos_diretos: "",
        proposito_relacao_negocios_seguros: "",
        finalidade_remessas: "",
        nivel_operacional_pretendido_opcao1: "",
        nivel_operacional_pretendido_opcao2: "",
        nivel_operacional_pretendido_opcao3: "",
        nivel_operacional_pretendido_opcao4: "",
        pagadores_recebedores_exterior: "",
        como_cliente_foi_captado: "",
        tempo_relacionamento_cliente: "",
        observacoes: "",
        cidade_residencial: "",
        estado_residencial: "",
        assinatura_gestor: ""
    });
    const [loading, setLoading] = useState(false);

    //Form
    const [formData, setFormData] = useState(defaultFormDataTeste);

    const validateForm = useCallback(() => {
        const errors = {};

        if (!formData.nome?.trim()) errors.nome = "Campo obrigatório";
        if (!formData.cpf?.trim()) errors.cpf = "Campo obrigatório";
        if (!formData.email?.trim()) errors.email = "Campo obrigatório";
        if (!formData.atividade_economica?.trim()) errors.atividade_economica = "Campo obrigatório";
        if (!formData.finalidade_remessas?.trim()) errors.finalidade_remessas = "Campo obrigatório";
        if (!formData.pagadores_recebedores_exterior?.trim())
            errors.pagadores_recebedores_exterior = "Campo obrigatório";
        if (!formData.como_cliente_foi_captado?.trim())
            errors.como_cliente_foi_captado = "Campo obrigatório";
        if (!formData.tempo_relacionamento_cliente?.trim())
            errors.tempo_relacionamento_cliente = "Campo obrigatório";
        setFormErrors(errors);
    }, [formData]);

    useEffect(() => {
        // Chamada da função de validação
        validateForm();
    }, [validateForm]);

    const handleChange = async e => {
        const name = e.target.name;
        const value = e.target.value;
        // Para todos os outros campos, atualiza o estado normalmente.
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleCheckBoxChange = async e => {
        const name = e.target.name;
        const value = e.target.checked;
        // Para todos os outros campos, atualiza o estado normalmente.
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit = async e => {
        e.preventDefault();
        const errors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            const userToken = process.env.REACT_APP_API_TOKEN;
            const url = `${process.env.REACT_APP_API_HOST}/formularios/bancobs2/pf/kyc`;

            const formDataTemp = {
                nome: formData.nome,
                cpf: formData.cpf,
                email: formData.email,
                atividade_economica: formData.atividade_economica,
                proposito_relacao_negocios_comercio_exterior:
                    formData.proposito_relacao_negocios_comercio_exterior,
                proposito_relacao_negocios_capitais_brasileiros:
                    formData.proposito_relacao_negocios_capitais_brasileiros,
                proposito_relacao_negocios_capitais_estrangeiros:
                    formData.proposito_relacao_negocios_capitais_estrangeiros,
                proposito_relacao_negocios_servicos_diretos:
                    formData.proposito_relacao_negocios_servicos_diretos,
                proposito_relacao_negocios_seguros: formData.proposito_relacao_negocios_seguros,
                finalidade_remessas: formData.finalidade_remessas,
                nivel_operacional_pretendido_opcao1: formData.nivel_operacional_pretendido_opcao1,
                nivel_operacional_pretendido_opcao2: formData.nivel_operacional_pretendido_opcao2,
                nivel_operacional_pretendido_opcao3: formData.nivel_operacional_pretendido_opcao3,
                nivel_operacional_pretendido_opcao4: formData.nivel_operacional_pretendido_opcao4,
                pagadores_recebedores_exterior: formData.pagadores_recebedores_exterior,
                como_cliente_foi_captado: formData.como_cliente_foi_captado,
                tempo_relacionamento_cliente: formData.tempo_relacionamento_cliente,
                observacoes: formData.observacoes,
                cidade_residencial: formData.cidade_residencial,
                estado_residencial: formData.estado_residencial,
                assinatura_gestor: formData.assinatura_gestor
            };

            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${userToken}`
                    },
                    body: JSON.stringify(formDataTemp),
                    credentials: "include"
                });

                if (response.status === 200) {
                    const jsonData = await response.json();
                    setData(jsonData);

                    Cookies.set("fileExistsKnowYourClientPF", JSON.stringify(jsonData), {
                        expires: 7
                    });
                    Cookies.set("formDataKnowYourClientPF", JSON.stringify(formDataTemp), {
                        expires: 30
                    });

                    setError(null);
                } else {
                    toast(
                        "error",
                        `Erro ao enviar resposta\n\n${response.status} ${response.statusText}\n\nDúvidas? Entre em contato no WhatsApp +55 11 93211-4516`,
                        true
                    );
                }
            } catch (error) {
                console.error("Erro ao fazer a requisição:", error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        } else {
            setFormErrors(errors);
            setLoading(false);
            console.log(errors);
            if (errors) {
                toast("error", "Erro ao enviar resposta\nPreencha os campos obrigatórios.");
            } else {
                toast(
                    "error",
                    "Erro ao enviar resposta\nDúvidas? Entre em contato no WhatsApp +55 11 93211-4516",
                    true
                );
            }
        }
    };

    const Required = () => {
        return <span className="text-red-500 font-bold">*</span>;
    };
    return (
        <form
            className="bg-white p-8 rounded-lg shadow-lg w-full md:min-w-[400px] md:max-w-3xl mx-auto"
            autoComplete="off"
        >
            <p className="text-gray-500 mb-8 text-center">
                O Banco BS2 é a instituição financeira parceira da SuzanCâmbio na realização de
                transferências internacionais. Por isso, após o preenchimento deste formulário você
                receberá um e-mail com o docuemnto do BS2 devidamente preenchido para conferência e
                assinatura digital.
                <br />
                Todos os dados informados neste formulário serão mantidos em total sigilo pela
                SuzanCâmbio e BS2, seguindo rigorosamente as diretrizes da lei de proteção geral de
                dados - LGPD.
            </p>
            <h2 className="text-2xl font-bold mb-6 text-gray-800 text-center">
                Formulário - Identificação do Cliente
            </h2>
            <div className="mb-4">
                <label htmlFor="nome" className="block text-gray-700">
                    Nome Completo <Required />
                </label>
                <Input
                    id="nome"
                    name="nome"
                    value={formData.nome}
                    onChange={handleChange}
                    isRequired
                    variant="bordered"
                    fullWidth
                    classNames={{
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.nome ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.nome}
                />
            </div>
            <div className="mb-4">
                <label htmlFor="email" className="block text-gray-700">
                    Email <Required />
                </label>
                <Input
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    isRequired
                    variant="bordered"
                    fullWidth
                    classNames={{
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.email ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.email}
                />
            </div>
            <div className="mb-6 w-full grid grid-cols-2 space-x-2">
                <div>
                    <label htmlFor="cpf" className="block text-gray-700">
                        CPF <Required />
                    </label>
                    <InputMask
                        mask={"999.999.999-99"}
                        value={formData.cpf}
                        onChange={handleChange}
                        autoComplete="nope"
                        id="cpf"
                        name="cpf"
                        aria-label="cpf"
                        className={`mt-2 p-2 w-full border rounded-md ${formErrors.cpf ? "border-red-500" : ""}`}
                    >
                        {inputProps => <input {...inputProps} type="tel" />}
                    </InputMask>
                    {formErrors.cpf && (
                        <p className="text-red-500 text-xs mt-1">{formErrors.cpf}</p>
                    )}
                </div>
            </div>
            <div className="mb-6">
                <label htmlFor="atividade_economica" className="block text-gray-700">
                    Atividade Econômica: <Required />
                </label>
                <Input
                    id="atividade_economica"
                    name="atividade_economica"
                    value={formData.atividade_economica}
                    onChange={handleChange}
                    isRequired
                    variant="bordered"
                    fullWidth
                    classNames={{
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.atividade_economica ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.atividade_economica}
                />
            </div>
            <Divider className="mt-8 mb-8" />
            <div className="mb-4">
                <div className="mb-8">
                    <label className="block text-gray-700">
                        <span className="text-xl font-medium">
                            Propósito da Relação de Negócios:
                        </span>{" "}
                    </label>
                </div>
                <div className="w-full">
                    <Checkbox
                        id="proposito_relacao_negocios_comercio_exterior"
                        name="proposito_relacao_negocios_comercio_exterior"
                        value={formData.proposito_relacao_negocios_comercio_exterior}
                        defaultSelected={formData.proposito_relacao_negocios_comercio_exterior}
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <span className="antialiased font-medium text-gray-800">
                                1. Comércio Exterior
                            </span>
                            <p>Exportação e/ou importação de mercadorias</p>
                        </div>
                    </Checkbox>
                    <Checkbox
                        id="proposito_relacao_negocios_capitais_brasileiros"
                        name="proposito_relacao_negocios_capitais_brasileiros"
                        value={formData.proposito_relacao_negocios_capitais_brasileiros}
                        defaultSelected={formData.proposito_relacao_negocios_capitais_brasileiros}
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <span className="antialiased font-medium text-gray-800">
                                2. Capitais Brasileiros
                            </span>
                            <p>
                                Empréstimos e financiamentos, disponibilidades no exterior, compra e
                                venda de imóveis no exterior, doações, manutenção de residentes no
                                exterior, investimentos, lucros e dividendos, outras transferências
                                correntes
                            </p>
                        </div>
                    </Checkbox>
                    <Checkbox
                        id="proposito_relacao_negocios_capitais_estrangeiros"
                        name="proposito_relacao_negocios_capitais_estrangeiros"
                        value={formData.proposito_relacao_negocios_capitais_estrangeiros}
                        defaultSelected={formData.proposito_relacao_negocios_capitais_estrangeiros}
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <span className="antialiased font-medium text-gray-800">
                                3. Capitais Estrangeiros
                            </span>
                            <p className="antialiased">
                                Empréstimos e financiamentos, disponibilidades no país, compra e
                                venda de imóveis no país, doações, manutenção de residentes no
                                exterior, investimentos, lucros e dividendos, outras transferências
                                correntes
                            </p>
                        </div>
                    </Checkbox>
                    <Checkbox
                        id="proposito_relacao_negocios_servicos_diretos"
                        name="proposito_relacao_negocios_servicos_diretos"
                        value={formData.proposito_relacao_negocios_servicos_diretos}
                        defaultSelected={formData.proposito_relacao_negocios_servicos_diretos}
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <span className="antialiased font-medium text-gray-800">
                                4. Serviços Diretos
                            </span>
                            <p className="antialiased">
                                Serviços técnicos e profissionais, transações comerciais
                            </p>
                        </div>
                    </Checkbox>
                    <Checkbox
                        id="proposito_relacao_negocios_seguros"
                        name="proposito_relacao_negocios_seguros"
                        value={formData.proposito_relacao_negocios_seguros}
                        defaultSelected={formData.proposito_relacao_negocios_seguros}
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <span className="antialiased font-medium text-gray-800">
                                5. Seguros
                            </span>
                            <p className="antialiased">
                                Resseguros, prêmios, indenizações e sinistros
                            </p>
                        </div>
                    </Checkbox>
                </div>
                <Textarea
                    id="finalidade_remessas"
                    name="finalidade_remessas"
                    value={formData.finalidade_remessas}
                    onChange={handleChange}
                    isRequired
                    label="Finalidade das remessas (detalhamento):"
                    labelPlacement="outside"
                    variant="underlined"
                    classNames={{
                        base: cn("mt-2 mb-8 p-2 w-full"),
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.finalidade_remessas ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-xl font-medium"
                    }}
                />
            </div>
            <div className="mb-4">
                <div className="mb-8">
                    <label className="block text-gray-700">
                        <span className="text-xl font-medium">Nível Operacional Pretendido:</span>{" "}
                    </label>
                </div>
                <div className="w-full">
                    <Checkbox
                        id="nivel_operacional_pretendido_opcao1"
                        name="nivel_operacional_pretendido_opcao1"
                        value={formData.nivel_operacional_pretendido_opcao1}
                        defaultSelected={formData.nivel_operacional_pretendido_opcao1}
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <Chip className="mr-3 bg-blue-300">
                                <span className="font-medium text-white">NÍVEL 1</span>
                            </Chip>
                            <span className="antialiased font-medium text-gray-800">
                                Até US$ 10.000,00{" "}
                                <span className="font-normal">
                                    (ou o equivalente em outras moedas)
                                </span>
                            </span>
                        </div>
                    </Checkbox>
                    <Checkbox
                        id="nivel_operacional_pretendido_opcao2"
                        name="nivel_operacional_pretendido_opcao2"
                        value={formData.nivel_operacional_pretendido_opcao2}
                        defaultSelected={formData.nivel_operacional_pretendido_opcao2}
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <Chip className="mr-3 bg-blue-400">
                                <span className="font-medium text-white">NÍVEL 2</span>
                            </Chip>
                            <span className="antialiased font-medium text-gray-800">
                                Entre US$ 10.000,01 e US$ 50.000,00{" "}
                                <span className="font-normal">
                                    (ou o equivalente em outras moedas)
                                </span>
                            </span>
                        </div>
                    </Checkbox>
                    <Checkbox
                        id="nivel_operacional_pretendido_opcao3"
                        name="nivel_operacional_pretendido_opcao3"
                        value={formData.nivel_operacional_pretendido_opcao3}
                        defaultSelected={formData.nivel_operacional_pretendido_opcao3}
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <Chip className="mr-3 bg-blue-700">
                                <span className="font-medium text-white">NÍVEL 3</span>
                            </Chip>
                            <span className="antialiased font-medium text-gray-800">
                                Entre US$ 50.000,01 e US$ 100.000,00{" "}
                                <span className="font-normal">
                                    (ou o equivalente em outras moedas)
                                </span>
                            </span>
                        </div>
                    </Checkbox>
                    <Checkbox
                        id="nivel_operacional_pretendido_opcao4"
                        name="nivel_operacional_pretendido_opcao4"
                        value={formData.nivel_operacional_pretendido_opcao4}
                        defaultSelected={formData.nivel_operacional_pretendido_opcao4}
                        onChange={handleCheckBoxChange}
                        classNames={{
                            base: cn(
                                "w-full max-w-full bg-content1",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                                "mb-4"
                            ),
                            label: "w-full text-gray-500 hover:text-gray-800"
                        }}
                    >
                        <div className="w-full justify-between gap-2">
                            <Chip className="mr-3 bg-blue-900">
                                <span className="font-medium text-white">NÍVEL 4</span>
                            </Chip>
                            <span className="antialiased font-medium text-gray-800">
                                Acima de US$ 100.000,00{" "}
                                <span className="font-normal">
                                    (ou o equivalente em outras moedas)
                                </span>
                            </span>
                        </div>
                    </Checkbox>
                </div>
                <Textarea
                    id="pagadores_recebedores_exterior"
                    name="pagadores_recebedores_exterior"
                    value={formData.pagadores_recebedores_exterior}
                    onChange={handleChange}
                    isRequired
                    label="Pagadores/Recebedores no exterior e seus respectivos países:"
                    labelPlacement="outside"
                    variant="bordered"
                    fullWidth
                    classNames={{
                        base: cn("mt-8 mb-8"),
                        inputWrapper: "border-none p-0",
                        input: cn(
                            "resize-y min-h-[40px] rounded-md",
                            `mt-2 p-2 w-full border rounded-md ${formErrors?.pagadores_recebedores_exterior ? "border-red-500" : ""}`
                        ),
                        label: "w-full antialiased block text-gray-700 text-base"
                    }}
                    errorMessage={formErrors?.pagadores_recebedores_exterior}
                />
            </div>
            <Divider className="mt-4 mb-8" />
            <div>
                <label className="block text-gray-700">
                    <span className="text-xl font-medium">Informações adicionais</span>{" "}
                </label>
            </div>
            <Textarea
                id="como_cliente_foi_captado"
                name="como_cliente_foi_captado"
                value={formData.como_cliente_foi_captado}
                onChange={handleChange}
                isRequired
                label="Como o cliente foi captado (ou como chegou até o BS2)?"
                labelPlacement="outside"
                variant="bordered"
                fullWidth
                classNames={{
                    base: cn("mt-8 mb-8"),
                    inputWrapper: "border-none p-0",
                    input: cn(
                        "resize-y min-h-[40px] rounded-md",
                        `mt-2 p-2 w-full border rounded-md ${formErrors?.como_cliente_foi_captado ? "border-red-500" : ""}`
                    ),
                    label: "w-full antialiased block text-gray-700 text-base"
                }}
                errorMessage={formErrors?.como_cliente_foi_captado}
            />
            <div className="flex flex-cols justify-left relative z-0 w-full mb-5">
                <div className="relative z-0 w-full group">
                    <label htmlFor="tempo_relacionamento_cliente" className="block text-gray-700">
                        Desde quando tem relacionamento com o cliente: <Required />
                    </label>
                    <InputMask
                        mask={"99/99/9999"}
                        placeholder="dd/mm/aaaa"
                        maskChar={null}
                        value={formData.tempo_relacionamento_cliente}
                        onChange={handleChange}
                        autoComplete="nope"
                        id="tempo_relacionamento_cliente"
                        name="tempo_relacionamento_cliente"
                        className={`mt-2 p-2 w-full border rounded-md ${formErrors.tempo_relacionamento_cliente ? "border-red-500" : ""}`}
                    >
                        {inputProps => (
                            <input {...inputProps} type="tel" placeholder="dd/mm/aaaa" />
                        )}
                    </InputMask>
                    {formErrors.tempo_relacionamento_cliente && (
                        <p className="text-red-500 text-xs mt-1">
                            {formErrors.tempo_relacionamento_cliente}
                        </p>
                    )}
                </div>
            </div>
            <Textarea
                id="observacoes"
                name="observacoes"
                value={formData.observacoes}
                onChange={handleChange}
                label="Observações e/ou outras informações derivadas dos procedimentos de Conheça Seu Cliente (se houver)"
                labelPlacement="outside"
                variant="bordered"
                fullWidth
                classNames={{
                    base: cn("mt-8 mb-8"),
                    inputWrapper: "border-none p-0",
                    input: cn(
                        "resize-y min-h-[40px] rounded-md",
                        `mt-2 p-2 w-full border rounded-md ${formErrors?.observacoes ? "border-red-500" : ""}`
                    ),
                    label: "w-full antialiased block text-gray-700 text-base"
                }}
                errorMessage={formErrors?.observacoes}
            />
            <Divider className="mt-4 mb-8" />
            <Button
                type="button"
                onClick={handleSubmit}
                color="primary"
                className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-200"
            >
                {loading && <Loader color={"white"} />}
                Enviar respostas
            </Button>
        </form>
    );
};

export default Form;
