import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { useParams, useNavigate } from "react-router-dom";

import { Container } from "../components/utils";
import { LISTA_PARCEIROS } from "../constants";
import Banner from "../layouts/Banner";
import Nav from "../layouts/Nav";

import Footer from "./Footer";

const Home = () => {
    // Utiliza useParams para acessar diretamente os parâmetros da URL
    const { codigoParceiro } = useParams(); // Assumindo que a rota é /parceira/:codigoParceiro
    const navigate = useNavigate();

    const [parceiro, setParceiro] = useState(null);

    useEffect(() => {
        if (codigoParceiro) {
            // Verifica se o parceiro existe na lista
            const parceiroEncontrado = LISTA_PARCEIROS.find(p => p.codigo === codigoParceiro);

            if (!parceiroEncontrado) {
                // Se o parceiro não existir, redireciona para a página inicial
                navigate("/");
            } else {
                // Se o parceiro existir, armazena no estado
                setParceiro(parceiroEncontrado);
            }
        }
    }, [codigoParceiro, navigate]);

    return (
        <div>
            <Nav />

            <Banner parceiro={parceiro ? parceiro : ""} />
            <Container className="wow fadeInUp relative -mt-[110px]">
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4 bg-white border border-gray-200 border-opacity-50 shadow-2xl rounded-xl">
                    <div className="text-center py-10 lg:py-16 space-y-3 text-blue-950 font-medium">
                        <h1 className="text-5xl lg:text-6xl font-semibold text-blue-600 flex items-center justify-center flex-row">
                            Taxa 0
                        </h1>
                        <p className="text-sm lg:text-base -mt-8">
                            Não cobramos taxas adicionais por remessas realizadas ou por contratos
                            emitidos.
                        </p>
                    </div>

                    <div className="text-center py-10 lg:py-16 space-y-3 text-blue-950 font-medium">
                        <h1 className="text-5xl lg:text-6xl font-semibold text-blue-600">
                            <CountUp end={100} />+
                        </h1>
                        <p className="text-sm lg:text-base">
                            Conectando você a mais de 100 países.
                        </p>
                    </div>

                    <div className="text-center py-10 lg:py-16 space-y-3 text-blue-950 font-medium">
                        <h1 className="text-5xl lg:text-6xl font-semibold text-blue-600">
                            <CountUp end={1} /> Dia
                        </h1>
                        <p className="text-sm lg:text-base">
                            O dinheiro chega no destino
                            <br className="hidden lg:block" /> em até 1 dia útil.
                            <br className="hidden lg:block" /> Para operações de Euro ou Libra,
                            <br className="hidden lg:block" /> na maioria dos casos, o prazo é
                            menor.
                        </p>
                    </div>

                    <div className="text-center py-10 lg:py-16 space-y-3 text-blue-950 font-medium">
                        <h1 className="text-5xl lg:text-6xl font-semibold text-blue-600">
                            <CountUp end={7} />/<CountUp end={24} />+
                        </h1>
                        <p className="text-sm lg:text-base">
                            Suporte dedicado
                            <br className="hidden lg:block" />a qualquer hora.
                        </p>
                    </div>
                </div>
            </Container>

            <section id="about-us" className="mb-32"></section>
            <div className="wow fadeInUp text-center text-blue-950 space-y-8 mt-28 mb-16">
                <h4 className="text-xl lg:text-2xl font-bold">
                    Cadastro simplificado para a maioria das transferências internacionais.
                </h4>

                <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold">
                    O mundo ao seu alcance em um <br className="hidden lg:block" /> Clique!
                </h1>

                <h4 className="text-xl lg:text-2xl font-bold">
                    Entre em contato para otimizar suas operações de câmbio.
                </h4>
            </div>

            <Container className="wow fadeInUp flex items-center flex-col-reverse xl:flex-row xl:space-y-0 xl:space-x-5">
                <div className="w-full xl:w-1/2">
                    <img
                        src="/images/global-payment-img.png"
                        className="h-auto object-contain object-center align-middle mt-16 xl:mt-0"
                        alt=""
                    />
                </div>

                <div className="w-full xl:w-1/2 grid grid-cols-1 md:grid-cols-2 gap-6 text-blue-950">
                    <div className="transition-all duration-300 border border-blue-200 rounded-3xl p-4 lg:p-8 text-center flex flex-col space-y-8 hover:border-gray-200 hover:border-opacity-50 hover:shadow-2xl">
                        <div className="flex items-center justify-center">
                            <img src="/images/global-payment-icon-1.png" alt="" />
                        </div>
                        <h3 className="text-xl lg:text-2xl font-bold text-blue-950">
                            Tranquilidade Garantida
                        </h3>
                        <p className="max-w-lg">
                            Segurança e confiabilidade em cada operação. Com a SuzanCâmbio, suas
                            transações de moeda e remessas internacionais estão seguras.
                        </p>
                    </div>

                    <div className="transition-all duration-300 border border-blue-200 rounded-3xl p-4 lg:p-8 text-center flex flex-col space-y-8 hover:border-gray-200 hover:border-opacity-50 hover:shadow-2xl">
                        <div className="flex items-center justify-center">
                            <img src="/images/global-payment-icon-2.png" alt="" />
                        </div>
                        <h3 className="text-xl lg:text-2xl font-bold text-blue-950">
                            Prontos para Negócios
                        </h3>
                        <p className="max-w-lg">
                            Leve sua empresa para o mundo com facilidade. A SuzanCâmbio oferece
                            soluções personalizadas para empresas que querem se globalizar.
                        </p>
                    </div>

                    <div className="transition-all duration-300 border border-blue-200 rounded-3xl p-4 lg:p-8 text-center flex flex-col space-y-8 hover:border-gray-200 hover:border-opacity-50 hover:shadow-2xl">
                        <div className="flex items-center justify-center">
                            <img src="/images/global-payment-icon-3.png" alt="" />
                        </div>
                        <h3 className="text-xl lg:text-2xl font-bold text-blue-950">
                            Transparência Completa
                        </h3>
                        <p className="max-w-lg">
                            Clareza absoluta, sem custos escondidos. A SuzanCâmbio assegura
                            transparência integral em todas as taxas e operações.
                        </p>
                    </div>

                    <div className="transition-all duration-300 border border-blue-200 rounded-3xl p-4 lg:p-8 text-center flex flex-col space-y-8 hover:border-gray-200 hover:border-opacity-50 hover:shadow-2xl">
                        <div className="flex items-center justify-center">
                            <img src="/images/global-payment-icon-4.png" alt="" />
                        </div>
                        <h3 className="text-xl lg:text-2xl font-bold text-blue-950">
                            Rede Internacional
                        </h3>
                        <p className="max-w-lg">
                            Acesso a uma ampla rede global. Nossa cobertura internacional assegura
                            que suas remessas cheguem a mais de 100 países de forma rápida e sem
                            complicações.
                        </p>
                    </div>
                </div>
            </Container>

            {/* <div
                className="mt-28 pt-10 md:pt-28 pb-52 space-y-8 text-center text-white bg-no-repeat bg-cover bg-center"
                style={{ backgroundImage: "url('/images/our-solutions-bg.png')" }}
            >
                <h3 className="wow fadeInUp text-xl md:text-2xl text-green-400 font-bold">
                    High speeds. Low fees. No hassle.
                </h3>
                <h1 className="wow fadeInUp text-3xl md:text-4xl xl:text-6xl font-bold">
                    All Your Payments In <br className="hidden lg:block" /> One Place
                </h1>
                <p className="wow fadeInUp text-white font-medium text-opacity-80 text-sm md:text-base">
                    Get used to low fees and great exchange rates on international money <br />{" "}
                    transfers.Expand your business worldwide
                </p>
            </div>

            <Container className="-mt-[150px] wow fadeInUp">
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6">
                    <div className="w-2/3 mx-auto md:w-full bg-white transition-all duration-300 border border-gray-200 border-opacity-50 rounded-3xl p-4 lg:p-8 text-center flex flex-col space-y-8 shadow-xl">
                        <div className="flex items-center justify-center">
                            <img src="/images/our-solutions-icon-1.png" alt="" />
                        </div>
                        <h3 className="text-xl lg:text-2xl font-bold text-blue-950">Payments</h3>
                        <p className="max-w-lg text-lg text-blue-950">
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry.
                        </p>
                    </div>

                    <div className="w-2/3 mx-auto md:w-full bg-white transition-all duration-300 border border-gray-200 border-opacity-50 rounded-3xl p-4 lg:p-8 text-center flex flex-col space-y-8 shadow-xl">
                        <div className="flex items-center justify-center">
                            <img src="/images/our-solutions-icon-2.png" alt="" />
                        </div>
                        <h3 className="text-xl lg:text-2xl font-bold text-blue-950">Collections</h3>
                        <p className="max-w-lg text-lg text-blue-950">
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry.
                        </p>
                    </div>

                    <div className="w-2/3 mx-auto md:w-full bg-white transition-all duration-300 border border-gray-200 border-opacity-50 rounded-3xl p-4 lg:p-8 text-center flex flex-col space-y-8 shadow-xl">
                        <div className="flex items-center justify-center">
                            <img src="/images/our-solutions-icon-3.png" alt="" />
                        </div>
                        <h3 className="text-xl lg:text-2xl font-bold text-blue-950">Conversions</h3>
                        <p className="max-w-lg text-lg text-blue-950">
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry.
                        </p>
                    </div>

                    <div className="w-2/3 mx-auto md:w-full bg-white transition-all duration-300 border border-gray-200 border-opacity-50 rounded-3xl p-4 lg:p-8 text-center flex flex-col space-y-8 shadow-xl">
                        <div className="flex items-center justify-center">
                            <img src="/images/our-solutions-icon-4.png" alt="" />
                        </div>
                        <h3 className="text-xl lg:text-2xl font-bold text-blue-950">
                            Global Account
                        </h3>
                        <p className="max-w-lg text-lg text-blue-950">
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry.
                        </p>
                    </div>
                </div>

                <div className="flex items-center flex-col-reverse  lg:flex-row space-x-16 mt-24">
                    <img
                        src="/images/app-download-img.png"
                        className="w-full lg:w-1/2 mt-8 lg:mt-0 object-cover object-center"
                        alt=""
                    />

                    <div className="w-full lg:w-1/2 space-y-8">
                        <div className="text-blue-950 space-y-8 mt-28">
                            <h4 className="text-xl text-blue-600 lg:text-2xl font-bold">
                                App Download
                            </h4>

                            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold">
                                Fast, Secure Money <br className="hidden lg:block" /> Transfers
                            </h1>

                            <p className="text-sm sm:text-base">
                                Access your account via your mobile phone. View balance, transfer{" "}
                                <br /> funds, view transactions wherever you are.
                            </p>
                        </div>

                        <div className="space-y-5 text-blue-950">
                            <div className="flex items-center space-x-3 lg:space-x-5">
                                <img src="/images/check.png" alt="" />
                                <p>Login with fingerprint or Face ID.</p>
                            </div>

                            <div className="flex items-center space-x-3 lg:space-x-5">
                                <img src="/images/check.png" alt="" />
                                <p>Simple few Taps to send money.</p>
                            </div>

                            <div className="flex items-center space-x-3 lg:space-x-5">
                                <img src="/images/check.png" alt="" />
                                <p>View transaction history.</p>
                            </div>

                            <div className="flex items-center space-x-3 lg:space-x-5">
                                <img src="/images/check.png" alt="" />
                                <p>Get instant App notifications.</p>
                            </div>
                        </div>

                        <div className="flex items-center space-x-3">
                            <button className="transition-all duration-300 hover:shadow-xl">
                                <img src="/images/GooglePlay-btn.png" alt="" />
                            </button>

                            <button className="transition-all duration-300 hover:shadow-xl">
                                <img src="/images/apple-btn.png" alt="" />
                            </button>
                        </div>
                    </div>
                </div>
            </Container> */}

            <Footer />
        </div>
    );
};

export default Home;
