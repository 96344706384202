import { Menu, Transition } from "@headlessui/react";
import React, { useState } from "react";
import "react-phone-number-input/style.css";
import CurrencyInput from "react-currency-input-field";
import { HiChevronRight } from "react-icons/hi";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

// import { IoMdSync } from "react-icons/io";
// import { useNavigate } from "react-router-dom";

// import { formatToCurrency } from "../helpers";
import {
    toast,
    moedas,
    motivos,
    operacoes,
    tipos_pessoa,
    msg_padrao,
    msg_parceiro,
    CamelCase
} from "../helpers";
// import { supabase } from "../hooks/supabaseClient"; // Certifique-se de que este é o caminho correto para o seu cliente Supabase

import { Loader } from "./utils";

const ContactForm = ({ parceiro }) => {
    const defaultMessage = {
        operacao: [],
        tipoPessoa: [],
        coin: [],
        quantia: [],
        motivo: [],
        nome: [],
        email: [],
        telefone: [],
        nomeEmpresa: []
    };

    // const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState(defaultMessage);
    const [loading, setLoading] = useState(false);

    //Form
    const [operacao, setOperacao] = useState(operacoes[0]);
    const [tipoPessoa, setTipoPessoa] = useState(tipos_pessoa[0]);
    const [coin, setCoin] = useState(moedas[0]);
    const [quantia, setQuantia] = useState(1000);
    const [motivo, setMotivo] = useState(motivos[operacao][tipoPessoa][0]["valor"]);
    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [telefone, setTelefone] = useState("");
    const [nomeEmpresa, setNomeEmpresa] = useState("n/a");

    // const insert_supabase = async formDataTemp => {
    //     console.log(formDataTemp);
    //     const { data, error } = await supabase.from("remessas").insert([{ ...formDataTemp }]);

    //     if (error) {
    //         console.error("Erro ao inserir dados:", error);
    //         toast("error", "Erro ao enviar sua solicitação");
    //     } else {
    //         console.log("Dados inseridos com sucesso:", data);
    //         toast("success", "Remessa solicitado com sucesso");
    //         setOperacao(operacoes[0]);
    //         setTipoPessoa(tipos_pessoa[0]);
    //         setCoin(flags[0]);
    //         setQuantia("");
    //         setMotivo(motivos[operacao][tipoPessoa][0]["valor"]);
    //         setNome("");
    //         setEmail("");
    //         setTelefone("");
    //         setNomeEmpresa("n/a");
    //     }
    // };

    //     function manda_pro_carlinhos(formDataTemp) {
    //         const operacao = formDataTemp.operacao;
    //         const tipoPessoa = formDataTemp.tipoPessoa;
    //         const moeda = formDataTemp.moeda;
    //         const quantia = formDataTemp.quantia;
    //         const motivo = formDataTemp.motivo;
    //         const nome = formDataTemp.nome;
    //         const email = formDataTemp.email;
    //         const telefone = formDataTemp.telefone;

    //         const msg = `Olá, meu nome é ${nome}. Gostaria de solicitar a cotação da remessa abaixo:

    // *Tipo de Remessa:* ${operacao}
    // Remessa será feita por uma pessoa *${tipoPessoa}*
    // *Moeda:* ${moeda} | *Quantidade:* ${quantia}
    // *Motivo:* ${motivo}

    // *Informações de Contato*
    // Empresa: ${nomeEmpresa}
    // Email: ${email}
    // Telefone: ${telefone}
    //         `;

    //         // const msgWhats = encodeURI(`Olá ${remessa.nome}, tudo bem? Me chamo Carlos e falo em nome da empresa SuzanCâmbio.%0D%0A%0D%0ARecebemos sua solicitação de remessa [${remessa.id}] no site SuzanCambio.com e gostaria de saber se podemos dar continuidade na operação.`)
    //         // const msgWhats = `Olá ${remessa.nome}, tudo bem? Me chamo Carlos e falo em nome da empresa SuzanCâmbio.%0D%0A%0D%0ARecebemos sua solicitação de remessa [${remessa.id}] no site SuzanCambio.com e gostaria de saber se podemos dar continuidade na operação.`
    //         const whatsAppLink = `https://wa.me/+5511932114516?text=${encodeURI(msg)}`;
    //         window.open(whatsAppLink, "_blank");
    //     }

    // function novaRemessaWhatsApp(formDataTemp) {
    //     let msg = "";

    //     if (formDataTemp.parceiro) {
    //         msg = msg_parceiro(formDataTemp);
    //     } else {
    //         msg = msg_padrao(formDataTemp);
    //     }
    //     // const msgWhats = encodeURI(`Olá ${remessa.nome}, tudo bem? Me chamo Carlos e falo em nome da empresa SuzanCâmbio.%0D%0A%0D%0ARecebemos sua solicitação de remessa [${remessa.id}] no site SuzanCambio.com e gostaria de saber se podemos dar continuidade na operação.`)
    //     // const msgWhats = `Olá ${remessa.nome}, tudo bem? Me chamo Carlos e falo em nome da empresa SuzanCâmbio.%0D%0A%0D%0ARecebemos sua solicitação de remessa [${remessa.id}] no site SuzanCambio.com e gostaria de saber se podemos dar continuidade na operação.`
    //     const whatsAppLink = `https://wa.me/5511932114516?text=${encodeURI(msg)}`;
    //     window.open(whatsAppLink, "_blank");
    // }

    function novaRemessaWhatsApp(formDataTemp) {
        let msg = "";

        if (formDataTemp.parceiro) {
            msg = msg_parceiro(formDataTemp);
        } else {
            msg = msg_padrao(formDataTemp);
        }
        const whatsAppLink = `https://api.whatsapp.com/send/?phone=+5511978468354&text=${encodeURI(msg)}`;
        window.open(whatsAppLink, "_system", "location=no");
    }

    // function novaRemessaWhatsApp2() {
    //     const msg = "Finalmente pegou";
    //     const whatsAppLink = `https://api.whatsapp.com/send/?phone=+5511932114516&text=${encodeURI(msg)}`;
    //     window.open(whatsAppLink, "_system", "location=no");
    // }

    const submit = () => {
        setLoading(true);
        const newErrorMessage = defaultMessage;
        const formDataTemp = {
            operacao: operacao,
            tipoPessoa: tipoPessoa,
            moeda: coin.currency,
            quantia: quantia,
            motivo: motivo,
            nome: nome,
            email: email,
            telefone: telefone,
            nomeEmpresa: nomeEmpresa,
            parceiro: parceiro
        };

        newErrorMessage.operacao = !formDataTemp.operacao ? ["This field is required"] : [];
        newErrorMessage.tipoPessoa = !formDataTemp.tipoPessoa ? ["This field is required"] : [];
        newErrorMessage.coin = !formDataTemp.moeda ? ["This field is required"] : [];
        newErrorMessage.quantia = !formDataTemp.quantia ? ["This field is required"] : [];
        newErrorMessage.motivo = !formDataTemp.motivo ? ["This field is required"] : [];
        newErrorMessage.nome = !formDataTemp.nome ? ["This field is required"] : [];
        newErrorMessage.email = !formDataTemp.email ? ["This field is required"] : [];
        newErrorMessage.telefone = !formDataTemp.telefone ? ["This field is required"] : [];
        newErrorMessage.nomeEmpresa =
            !formDataTemp.nomeEmpresa & (formDataTemp.tipoPessoa == tipos_pessoa[1])
                ? ["This field is required"]
                : [];
        console.log(newErrorMessage);
        if (
            formDataTemp.operacao &&
            formDataTemp.tipoPessoa &&
            formDataTemp.moeda &&
            formDataTemp.quantia &&
            formDataTemp.motivo &&
            formDataTemp.nome &&
            formDataTemp.email &&
            formDataTemp.telefone &&
            formDataTemp.nomeEmpresa
        ) {
            // insert_supabase(formDataTemp);
            // manda_pro_carlinhos(formDataTemp);
            novaRemessaWhatsApp(formDataTemp);
        } else {
            toast("error", "Falha na solicitação");
        }
        setErrorMessage(newErrorMessage);
        setLoading(false);
    };

    // const submit2 = e => {
    //     e.preventDefault();
    //     setLoading(true);
    //     novaRemessaWhatsApp2();
    //     setLoading(false);
    // };

    // Função para atualizar o estado com a operação selecionada
    const handleOperacaoChange = e => {
        setOperacao(e.target.value);
    };

    // Função para atualizar o estado com a operação selecionada
    const handleTipoPessoaChange = e => {
        setTipoPessoa(e.target.value);
        const nomeEmpresa = e.target.value == "JURIDICA" ? "" : "n/a";
        setNomeEmpresa(nomeEmpresa);
    };

    const handleMotivoRemessaChange = e => {
        setMotivo(e.target.value);
    };

    const handleQuantiaChange = value => {
        if (!value) return;
        const numericValue = parseFloat(value.replaceAll(",", "."));
        if (numericValue < 100000000) {
            setQuantia(value);
        }
    };

    return (
        <div className="space-y-8 lg:space-y-10">
            {/* <form onSubmit={submit2}>
                <button
                    type="submit"
                    className="w-full flex items-center justify-center space-x-3 transition-all block text-center duration-300 px-3 lg:px-4 xl:px-8 font-medium lg:text-lg py-3 bg-blue-950 text-white rounded-md focus:outline-none hover:bg-blue-700 focus:ring focus:border-blue-500 focus:ring-blue-500/50"
                >
                    {loading && <Loader color={"white"} />}
                    <span>Teste WhatsApp</span>
                </button>
            </form> */}

            <div id="div-operacao">
                {parceiro && (
                    <h3 className="mb-2 text-lg font-medium text-blue-900">
                        Parceiro:{" "}
                        <CamelCase className="font-bold text-blue-500" text={parceiro.nome} />
                    </h3>
                )}
                <h3 className="mb-2 text-lg font-medium text-blue-900">
                    Escolha o tipo de remessa:
                </h3>
                <ul className="grid w-full gap-6 md:grid-cols-2">
                    <li>
                        <input
                            type="radio"
                            id="op-enviar"
                            name="operacao"
                            value="ENVIAR"
                            className="hidden peer"
                            required
                            checked={operacao === operacoes[0]}
                            onChange={handleOperacaoChange}
                        />
                        <label
                            htmlFor="op-enviar"
                            className="inline-flex items-center justify-center w-full p-3 border rounded-lg cursor-pointer text-blue-950 bg-gray-300 hover:bg-blue-600 dark:hover:bg-blue-950 hover:text-white dark:hover:text-white hover:border-blue-400 dark:hover:border-blue-400 peer-checked:bg-blue-800 peer-checked:text-white peer-checked:border-blue-950 dark:peer-checked:bg-blue-800 dark:peer-checked:text-white dark:peer-checked:border-blue-950 hover:peer-checked:bg-blue-900"
                        >
                            <div className="block">
                                <div className="w-full text-sm font-semibold">ENVIAR</div>
                            </div>
                        </label>
                    </li>
                    <li>
                        <input
                            type="radio"
                            id="op-receber"
                            name="operacao"
                            value="RECEBER"
                            className="hidden peer"
                            required
                            checked={operacao === operacoes[1]}
                            onChange={handleOperacaoChange}
                        />
                        <label
                            htmlFor="op-receber"
                            className="inline-flex items-center justify-center w-full p-3 border rounded-lg cursor-pointer text-blue-950 bg-gray-300 hover:bg-blue-600 dark:hover:bg-blue-950 hover:text-white dark:hover:text-white hover:border-blue-400 dark:hover:border-blue-400 peer-checked:bg-blue-800 peer-checked:text-white peer-checked:border-blue-950 dark:peer-checked:bg-blue-800 dark:peer-checked:text-white dark:peer-checked:border-blue-950 hover:peer-checked:bg-blue-900"
                        >
                            <div className="block">
                                <div className="w-full text-sm font-semibold">RECEBER</div>
                            </div>
                        </label>
                    </li>
                </ul>
            </div>
            <div id="div-tipopessoa">
                <h3 className="mb-2 text-lg font-medium text-blue-900">
                    Remessa será feita por uma:
                </h3>
                <ul className="grid w-full gap-6 md:grid-cols-2">
                    <li>
                        <input
                            type="radio"
                            id="tipopessoa-fisica"
                            name="tipopessoa"
                            value="FISICA"
                            className="hidden peer"
                            required
                            checked={tipoPessoa === tipos_pessoa[0]}
                            onChange={handleTipoPessoaChange}
                        />
                        <label
                            htmlFor="tipopessoa-fisica"
                            className="inline-flex items-center justify-center w-full p-3 border rounded-lg cursor-pointer text-blue-950 bg-gray-300 hover:bg-blue-600 dark:hover:bg-blue-950 hover:text-white dark:hover:text-white hover:border-blue-400 dark:hover:border-blue-400 peer-checked:bg-blue-800 peer-checked:text-white peer-checked:border-blue-950 dark:peer-checked:bg-blue-800 dark:peer-checked:text-white dark:peer-checked:border-blue-950 hover:peer-checked:bg-blue-900"
                        >
                            <div className="block">
                                <div className="w-full text-sm font-semibold">PESSOA FÍSICA</div>
                            </div>
                        </label>
                    </li>
                    <li>
                        <input
                            type="radio"
                            id="tipopessoa-juridica"
                            name="tipopessoa"
                            value="JURIDICA"
                            className="hidden peer"
                            required
                            checked={tipoPessoa === tipos_pessoa[1]}
                            onChange={handleTipoPessoaChange}
                        />
                        <label
                            htmlFor="tipopessoa-juridica"
                            className="inline-flex items-center justify-center w-full p-3 border rounded-lg cursor-pointer text-blue-950 bg-gray-300 hover:bg-blue-600 dark:hover:bg-blue-950 hover:text-white dark:hover:text-white hover:border-blue-400 dark:hover:border-blue-400 peer-checked:bg-blue-800 peer-checked:text-white peer-checked:border-blue-950 dark:peer-checked:bg-blue-800 dark:peer-checked:text-white dark:peer-checked:border-blue-950 hover:peer-checked:bg-blue-900"
                        >
                            <div className="block">
                                <div className="w-full text-sm font-semibold">PESSOA JURÍDICA</div>
                            </div>
                        </label>
                    </li>
                </ul>
            </div>
            <div className="rounded-lg bg-blue-50 p-4 lg:p-5">
                <div className="flex space-x-3 items-center">
                    <Menu>
                        {({ open }) => (
                            <div className="relative w-1/4 md:w-1/4 mr-1">
                                <Menu.Button className="w-full shadow-sm flex items-center space-x-2 justify-center text-blue-900 bg-white py-3 md:py-4 rounded-lg md:text-lg font-bold uppercase font-semibold">
                                    <img
                                        src={`/images/flags/${coin.img_rounded}`}
                                        className="rounded-full h-6 w-6"
                                        alt=""
                                    />
                                    <span>{coin.currency}</span>
                                    <HiChevronRight
                                        className={`transition-all duration-300 ${
                                            open ? "rotate-90" : ""
                                        } h-6 w-6`}
                                    />
                                </Menu.Button>

                                <Transition
                                    enter="transition duration-100 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-75 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0"
                                >
                                    <Menu.Items>
                                        <div className="absolute right-0 z-10 px-2 py-1 mt-1 w-44 text-gray-600 bg-white border rounded-md shadow">
                                            {moedas.map((item, index) => (
                                                <Menu.Item
                                                    key={index}
                                                    as={"button"}
                                                    className="flex items-center space-x-3 px-3 py-2 text-sm hover:text-blue-600"
                                                    onClick={() => setCoin(item)}
                                                >
                                                    <img
                                                        src={`/images/flags/${item.img_rounded}`}
                                                        className="rounded-full h-6 w-6"
                                                        alt=""
                                                    />
                                                    <span>{item.currency}</span>
                                                </Menu.Item>
                                            ))}
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </div>
                        )}
                    </Menu>
                    <div className="w-2/3 relative">
                        <h6 className="text-sm text-blue-900 mb-2 font-medium">
                            {operacao == operacoes[0]
                                ? `Você envia (${coin.currency}):`
                                : `Você recebe (${coin.currency}):`}
                        </h6>
                        <CurrencyInput
                            id="quantia-input"
                            name="quantia"
                            className={`p-0 w-full bg-blue-50 border-0 border-b-2 ${
                                errorMessage.quantia.length
                                    ? "border-red-600 focus:border-red-600"
                                    : "border-gray-300 focus:border-blue-800"
                            } text-blue-800 placeholder-blue-900 placeholder-opacity-25 font-bold text-xl lg:text-2xl focus:ring-0`}
                            placeholder="0,00"
                            defaultValue=""
                            value={quantia}
                            decimalsLimit={2}
                            decimalSeparator=","
                            groupSeparator="."
                            maxLength={13}
                            required
                            intlConfig={{ locale: "pt-BR" }}
                            onValueChange={handleQuantiaChange}
                        />
                        {errorMessage.quantia.length > 0 && (
                            <p className="absolute text-sm text-red-600">{errorMessage.quantia}</p>
                        )}
                    </div>

                    <input type="hidden" name="currency" value={coin.currency} />

                    {errorMessage.coin.length > 0 && (
                        <p className="absolute text-sm text-red-600">{errorMessage.coin}</p>
                    )}
                </div>
            </div>

            {/* <div className="space-y-3 lg:space-y-5 text-blue-900">
                <div className="flex items-center space-x-4 text-blue-600 text-sm">
                    <IoMdSync className="w-5 h-5" />
                    <span>Show calculations</span>
                </div>

                <div className="flex items-center">
                    <div className="w-1/2">
                        <span>1 XOF = 1 XOF</span>
                    </div>
                    <div className="w-1/2">Currency</div>
                </div>

                <div className="flex items-center">
                    <div className="w-1/2">
                        <span>
                            {formatToCurrency(180)} /{" "}
                            {formatToCurrency(parseFloat(quantia || 0) + 180)}
                        </span>
                    </div>
                    <div className="w-1/2">TVA / Total</div>
                </div>

                <div className="flex items-center">
                    <div className="w-1/2">
                        <span>{formatToCurrency(parseFloat(quantia || 0))}</span>
                    </div>
                    <div className="w-1/2">Recipient gets</div>
                </div>
            </div> */}

            <div className="rounded-lg bg-blue-50 p-4 lg:p-5">
                <label htmlFor="motivos" className="block mb-2 text-sm font-medium text-blue-900">
                    Selecione o motivo da remessa:
                </label>
                <select
                    id="motivos-remessa"
                    className="bg-blue-50 border border-gray-300 text-blue-800 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-blue-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-blue-800 dark:focus:ring-blue-500 dark:focus:border-blue-500 pr-8"
                    onChange={handleMotivoRemessaChange}
                    value={motivo}
                    required
                >
                    {motivos[operacao][tipoPessoa].map(motivo => (
                        // AJUSTAR PARA MOTIVO VALOR
                        <option key={motivo.valor} value={motivo.texto}>
                            {motivo.texto}
                        </option>
                    ))}
                </select>

                {errorMessage.motivo.length > 0 && (
                    <p className="absolute text-sm text-red-600">{errorMessage.motivo}</p>
                )}
            </div>
            {tipoPessoa == tipos_pessoa[1] && (
                <div className="rounded-lg bg-blue-50 p-4 lg:p-5">
                    <label
                        htmlFor="nome_empresa"
                        className="block mb-4 text-sm font-medium text-blue-900"
                    >
                        Informações da sua empresa
                    </label>
                    <div className="mb-6">
                        <label htmlFor="nome_empresa" className="text-sm text-blue-900">
                            Nome da Empresa:
                        </label>
                        <input
                            id="nome_empresa"
                            type="text"
                            className="p-0 w-full bg-blue-50 border-0 border-b-2 text-blue-800 placeholder-blue-900 placeholder-opacity-25 font-bold text-sm lg:text-lg focus:ring-0"
                            placeholder="Acme Ltda"
                            required
                            value={nomeEmpresa}
                            onChange={e => setNomeEmpresa(e.target.value)}
                        />

                        {errorMessage.nomeEmpresa.length > 0 && (
                            <p className="absolute text-sm text-red-600">
                                {errorMessage.nomeEmpresa}
                            </p>
                        )}
                    </div>
                </div>
            )}
            <div className="rounded-lg bg-blue-50 p-4 lg:p-5">
                <label htmlFor="motivos" className="block mb-4 text-sm font-bold text-blue-900">
                    Informações para contato
                </label>
                <div className="mb-6">
                    <label htmlFor="first_name" className="text-sm font-medium text-blue-900">
                        Nome:
                    </label>
                    <input
                        id="nome"
                        type="text"
                        className="p-0 w-full bg-blue-50 border-0 border-b-2 text-blue-800 placeholder-blue-900 placeholder-opacity-25 font-bold text-sm lg:text-lg focus:ring-0"
                        placeholder="John Doe"
                        required
                        value={nome}
                        onChange={e => setNome(e.target.value)}
                    />

                    {errorMessage.nome.length > 0 && (
                        <p className="absolute text-sm text-red-600">{errorMessage.nome}</p>
                    )}
                </div>
                <div className="mb-6">
                    <label htmlFor="email" className="text-sm font-medium text-blue-900">
                        Endereço de e-mail
                    </label>
                    <input
                        type="email"
                        id="email"
                        className="p-0 w-full bg-blue-50 border-0 border-b-2 text-blue-800 placeholder-blue-900 placeholder-opacity-25 font-bold text-sm lg:text-lg focus:ring-0"
                        placeholder="john.doe@exemplo.com"
                        required
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />

                    {errorMessage.email.length > 0 && (
                        <p className="absolute text-sm text-red-600">{errorMessage.email}</p>
                    )}
                </div>

                <div className="mb-6">
                    <label htmlFor="phone" className="text-sm font-medium text-blue-900">
                        WhatsApp:
                    </label>
                    <PhoneInput
                        international={true}
                        countryCallingCodeEditable={true}
                        defaultCountry="BR"
                        value={telefone}
                        onChange={setTelefone}
                        error={
                            telefone
                                ? isValidPhoneNumber(telefone)
                                    ? undefined
                                    : "Telefone inválido"
                                : "Número de telefone obrigatório"
                        }
                        className="p-0 w-full bg-blue-50 border-0 border-b-2 text-blue-800 placeholder-blue-900 placeholder-opacity-25 font-bold text-sm lg:text-lg focus:ring-0"
                        id="phone"
                        name="phone"
                        required
                        placeholder="Digite seu número de telefone"
                    />

                    {errorMessage.telefone.length > 0 && (
                        <p className="absolute text-sm text-red-600">{errorMessage.telefone}</p>
                    )}
                </div>
            </div>

            <button
                onClick={submit}
                className="w-full flex items-center justify-center space-x-3 transition-all block text-center duration-300 px-3 lg:px-4 xl:px-8 font-medium lg:text-lg py-3 bg-blue-950 text-white rounded-md focus:outline-none hover:bg-blue-700 focus:ring focus:border-blue-500 focus:ring-blue-500/50"
            >
                {loading && <Loader color={"white"} />}
                <span>Solicitar Remessa</span>
            </button>
        </div>
    );
};

export default ContactForm;
