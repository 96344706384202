import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { HiHome, HiInformationCircle } from "react-icons/hi";

import Form from "./Form";

async function checkFileExists(fileName) {
    const userToken = process.env.REACT_APP_API_TOKEN;
    const url = `${process.env.REACT_APP_API_HOST}/formularios/bancobs2/pj/verify/${fileName}`;
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${userToken}`
            },
            credentials: "include"
        });
        if (response.ok) {
            const data = await response.json();
            return data;
        }
        throw new Error("Não foi possível verificar a existência do arquivo");
    } catch (error) {
        console.error("Erro na verificação do arquivo:", error);
        return false;
    }
}

function AlteracaoParceiroCambialPJ() {
    const [data, setData] = useState(null);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fileData = Cookies.get("fileExistsAlteracaoParceiroCambialPJ");
        setLoading(true);
        if (fileData) {
            const { fileName } = JSON.parse(fileData);
            if (fileName) {
                checkFileExists(fileName).then(data => {
                    if (data.fileName) {
                        setData(JSON.parse(fileData));
                    } else {
                        Cookies.remove("fileExistsAlteracaoParceiroCambialPJ");
                        window.location.reload();
                    }
                });
            }
        }
        setLoading(false);
    }, []);

    return (
        <>
            {loading ? (
                <div></div>
            ) : (
                <div className="flex flex-col justify-center items-center w-full">
                    <div className="flex justify-center items-center mt-4">
                        <img
                            src="/images/logo/suzancambio-transparente.png"
                            alt="logo"
                            className="mb-8 h-16"
                        />
                        <hr />
                    </div>
                    {Cookies.get("fileExistsAlteracaoParceiroCambialPJ") || data ? (
                        <div className="bg-white p-8 rounded-lg shadow-lg min-w-[400px] max-w-3xl">
                            <div
                                id="alert-additional-content-3"
                                className="p-4 mb-4 text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800"
                                role="alert"
                            >
                                <div className="flex items-center">
                                    <HiInformationCircle className="w-4 h-4 mr-2" />
                                    <span className="sr-only">Info</span>
                                    <h3 className="text-lg font-medium">
                                        Documento enviado com sucesso!
                                    </h3>
                                </div>
                                <div className="mt-2 mb-4 text-sm">
                                    Agradecemos por preencher a ficha cadastral. Em breve você
                                    receberá por email o documento preenchido, por favor, confira as
                                    informações e assine online.
                                </div>
                                <div className="flex">
                                    <a
                                        type="button"
                                        href="/"
                                        className="text-white bg-green-800 hover:bg-green-900 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 me-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                                    >
                                        <HiHome />
                                        Voltar para página inicial
                                    </a>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Form setData={setData} setError={setError} />
                    )}
                    <div>{error && <p>Erro: {error}</p>}</div>
                </div>
            )}
        </>
    );
}

export default AlteracaoParceiroCambialPJ;
