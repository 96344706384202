import { Menu, Transition } from "@headlessui/react";
import { Button } from "@nextui-org/react";
import Cookies from "js-cookie";
import { InputNumber } from "primereact/inputnumber";
import React, { useState, useEffect, useCallback } from "react";
import { HiChevronRight } from "react-icons/hi";
import InputMask from "react-input-mask";
import "react-phone-number-input/style.css";

import { Loader } from "../../../../../../components/utils";
import { toast, moedas } from "../../../../../../helpers";

// import "./Form.css";

console.log("NODE_ENV:", process.env.NODE_ENV);

const defaultFormDataTeste = Cookies.get("formDataDeclaracaoManutencaoResidente")
    ? JSON.parse(Cookies.get("formDataDeclaracaoManutencaoResidente"))
    : {
          nome_cliente: "",
          email: "",
          cpf: "",
          identidade: "",
          cep: "",
          cidade: "",
          estado: "",
          nome_parente: "",
          identidade_parente: "",
          valor_remessa: "",
          moeda_remessa: ""
      };

console.log(defaultFormDataTeste);

const Form = ({ setData, setError }) => {
    const [formErrors, setFormErrors] = useState({
        nome_cliente: "",
        email: "",
        cpf: "",
        identidade: "",
        cep: "",
        cidade: "",
        estado: "",
        nome_parente: "",
        identidade_parente: "",
        valor_remessa: "",
        moeda_remessa: ""
    });
    const [loading, setLoading] = useState(false);
    const [coin, setCoin] = useState(moedas[0]);

    //Form
    const [formData, setFormData] = useState(defaultFormDataTeste);

    const validateForm = useCallback(() => {
        const errors = {};
        // Exemplo de validação
        if (!formData.nome_cliente?.trim()) errors.nome_cliente = "Campo obrigatório";
        if (!formData.email?.trim()) errors.email = "Campo obrigatório";
        if (!formData.cpf?.trim()) errors.cpf = "Campo obrigatório";
        if (!formData.identidade?.trim()) errors.identidade = "Campo obrigatório";
        if (!formData.cep?.trim()) errors.cep = "Campo obrigatório";
        if (!formData.cidade?.trim()) errors.cidade = "Campo obrigatório";
        if (!formData.estado?.trim()) errors.estado = "Campo obrigatório";
        if (!formData.nome_parente?.trim()) errors.nome_parente = "Campo obrigatório";
        if (!formData.identidade_parente?.trim()) errors.identidade_parente = "Campo obrigatório";
        if (!(formData.valor_remessa && String(formData.valor_remessa).trim()))
            errors.valor_remessa = "Campo obrigatório";
        if (!coin.currency?.trim()) errors.moeda_remessa = "Campo obrigatório";
        // Incluir outras validações conforme necessário...
        setFormErrors(errors);
        console.log(errors);
        console.log(formData);
    }, [formData, coin]);

    useEffect(() => {
        // Chamada da função de validação
        validateForm();
    }, [validateForm]);

    const handleChange = async e => {
        const name = e.target.name;
        const value = e.target.value;
        // Para todos os outros campos, atualiza o estado normalmente.
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleCepChange = async e => {
        const novoCep = e.target.value;
        setFormData({
            ...formData,
            ["cep"]: novoCep
        });

        if (novoCep.length === 8) {
            try {
                const response = await fetch(`https://viacep.com.br/ws/${novoCep}/json/`);
                const data = await response.json();

                if (!data.erro) {
                    setFormData({
                        ...formData,
                        ["cidade"]: data.localidade,
                        ["estado"]: data.uf,
                        ["cep"]: novoCep
                    });
                    document.getElementsByName("cep")[0].blur();
                } else {
                    alert("CEP não encontrado.");
                }
            } catch (error) {
                console.error("Erro ao buscar o CEP:", error);
            }
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        const errors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            const userToken = process.env.REACT_APP_API_TOKEN;
            const url = `${process.env.REACT_APP_API_HOST}/formularios/bancobs2/pf/declaracao_manutencao_residente`;

            const formDataTemp = {
                nome_cliente: formData.nome_cliente,
                email: formData.email,
                cpf: formData.cpf,
                identidade: formData.identidade,
                cep: formData.cep,
                cidade: formData.cidade,
                estado: formData.estado,
                nome_parente: formData.nome_parente,
                identidade_parente: formData.identidade_parente,
                valor_remessa: formData.valor_remessa,
                moeda_remessa: coin.currency
            };

            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${userToken}`
                    },
                    body: JSON.stringify(formDataTemp),
                    credentials: "include"
                });

                if (response.status === 200) {
                    const jsonData = await response.json();
                    setData(jsonData);

                    Cookies.set(
                        "fileExistsDeclaracaoManutencaoResidente",
                        JSON.stringify(jsonData),
                        { expires: 7 }
                    );
                    Cookies.set(
                        "formDataDeclaracaoManutencaoResidente",
                        JSON.stringify(formDataTemp),
                        {
                            expires: 30
                        }
                    );

                    setError(null);
                } else {
                    toast(
                        "error",
                        `Erro ao enviar resposta\n${response.status} ${response.statusText}\nDúvidas? Entre em contato no WhatsApp +55 11 93211-4516`,
                        true
                    );
                }
            } catch (error) {
                console.error("Erro ao fazer a requisição:", error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        } else {
            setFormErrors(errors);
            setLoading(false);
            console.log(errors);
            if (errors) {
                toast("error", "Erro ao enviar resposta\nPreencha os campos obrigatórios.");
            } else {
                toast(
                    "error",
                    "Erro ao enviar resposta\nDúvidas? Entre em contato no WhatsApp +55 11 93211-4516",
                    true
                );
            }
        }
    };

    const Required = () => {
        return <span className="text-red-500 font-bold">*</span>;
    };
    return (
        <form
            className="bg-white p-8 rounded-lg shadow-lg w-full md:min-w-[400px] md:max-w-3xl mx-auto"
            autoComplete="off"
        >
            <p className="text-gray-500 mb-8 text-center">
                O Banco BS2 é a instituição financeira parceira da SuzanCâmbio na realização de
                transferências internacionais. Por isso, após o preenchimento desta declaração você
                receberá um e-mail com a declaração do BS2 devidamente preenchida para conferência e
                assinatura digital.
                <br />
                Todos os dados informados neste formulário serão mantidos em total sigilo pela
                SuzanCâmbio e BS2, seguindo rigorosamente as diretrizes da lei de proteção geral de
                dados - LGPD.
            </p>
            <h2 className="text-2xl font-bold mb-6 text-gray-800 text-center">Declaração</h2>
            <div className="mb-4">
                <label htmlFor="nome_cliente" className="block text-gray-700">
                    Nome completo <Required />
                </label>
                <input
                    id="nome_cliente"
                    name="nome_cliente"
                    type="text"
                    value={formData.nome_cliente ?? ""}
                    onChange={handleChange}
                    autoComplete="nope"
                    aria-label="nome_cliente"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors?.nome_cliente ? "border-red-500" : ""}`}
                />
                {formErrors.nome_cliente && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.nome_cliente}</p>
                )}
            </div>
            <div className="mb-4 w-full">
                <label htmlFor="cpf" className="block text-gray-700">
                    CPF <Required />
                </label>
                <InputMask
                    mask={"999.999.999-99"}
                    value={formData.cpf}
                    onChange={handleChange}
                    autoComplete="nope"
                    id="cpf"
                    name="cpf"
                    aria-label="cpf"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.cpf ? "border-red-500" : ""}`}
                >
                    {inputProps => <input {...inputProps} type="tel" />}
                </InputMask>
                {formErrors.cpf && <p className="text-red-500 text-xs mt-1">{formErrors.cpf}</p>}
            </div>
            <div className="mb-6">
                <label htmlFor="email" className="block text-gray-700">
                    Email: <Required />
                </label>
                <input
                    id="email"
                    name="email"
                    type="text"
                    value={formData.email}
                    onChange={handleChange}
                    autoComplete="nope"
                    placeholder=" "
                    aria-label="email"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.email ? "border-red-500" : ""}`}
                />
                {formErrors.email && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.email}</p>
                )}
            </div>
            <div className="mb-6">
                <label htmlFor="identidade" className="block text-gray-700">
                    Número do documento de identidade: <Required />
                </label>
                <input
                    id="identidade"
                    name="identidade"
                    type="text"
                    value={formData.identidade}
                    onChange={handleChange}
                    autoComplete="nope"
                    placeholder=" "
                    aria-label="identidade"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.identidade ? "border-red-500" : ""}`}
                />
                {formErrors.identidade && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.identidade}</p>
                )}
            </div>
            <hr className="mt-8 mb-8" />
            <div className="mb-6 flex flex-cols space-x-4">
                <div>
                    <label htmlFor="cep" className="block text-gray-700">
                        CEP: <Required />
                    </label>
                    <InputMask
                        mask={"99999999"}
                        maskChar={null}
                        value={formData.cep}
                        onChange={handleCepChange}
                        onBlur={handleCepChange}
                        id="cep"
                        name="cep"
                        className={`mt-2 p-2 w-50% border rounded-md ${formErrors.cep ? "border-red-500" : ""}`}
                    >
                        {inputProps => <input {...inputProps} type="tel" />}
                    </InputMask>
                    {formErrors.cep && (
                        <p className="text-red-500 text-xs mt-1">{formErrors.cep}</p>
                    )}
                </div>
                <div>
                    <label htmlFor="cidade" className="block text-gray-700">
                        Cidade residencial: <Required />
                    </label>
                    <input
                        type="text"
                        name="cidade"
                        value={formData.cidade}
                        onChange={handleChange}
                        autoComplete="nope"
                        className={`mt-2 p-2 w-50% border rounded-md ${formErrors.cidade ? "border-red-500" : ""}`}
                    />
                    {formErrors.cidade && (
                        <p className="text-red-500 text-xs mt-1 mb-2">{formErrors.cidade}</p>
                    )}
                </div>
                <div>
                    <label htmlFor="estado" className="block text-gray-700">
                        Estado (UF) residencial: <Required />
                    </label>
                    <input
                        type="text"
                        name="estado"
                        value={formData.estado}
                        onChange={handleChange}
                        autoComplete="nope"
                        className={`mt-2 p-2 w-50% border rounded-md ${formErrors.estado ? "border-red-500" : ""}`}
                    />
                    {formErrors.estado && (
                        <p className="text-red-500 text-xs mt-1 mb-2">{formErrors.estado}</p>
                    )}
                </div>
            </div>
            <hr className="mb-6" />
            <div className="mb-6">
                <h2>Informações do Parente</h2>
            </div>
            <div className="mb-6">
                <label htmlFor="nome_parente" className="block text-gray-700">
                    Nome do(a) parente: <Required />
                </label>
                <input
                    type="text"
                    name="nome_parente"
                    value={formData.nome_parente}
                    onChange={handleChange}
                    autoComplete="nope"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.nome_parente ? "border-red-500" : ""}`}
                />
                {formErrors.nome_parente && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.nome_parente}</p>
                )}
            </div>
            <div className="mb-6">
                <label htmlFor="identidade_parente" className="block text-gray-700">
                    Documento de identidade do(a) parente: <Required />
                </label>
                <input
                    id="identidade_parente"
                    name="identidade_parente"
                    type="text"
                    value={formData.identidade_parente}
                    onChange={handleChange}
                    autoComplete="nope"
                    placeholder=" "
                    aria-label="identidade_parente"
                    className={`mt-2 p-2 w-full border rounded-md ${formErrors.identidade_parente ? "border-red-500" : ""}`}
                />
                {formErrors.identidade_parente && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.identidade_parente}</p>
                )}
            </div>
            <div className="mb-6 grid grid-cols-8 space-x-4">
                <div className="col-span-3">
                    <label htmlFor="moeda_remessa" className="block text-gray-700">
                        Moeda: <Required />
                    </label>
                    <Menu>
                        {({ open }) => (
                            // <div className="relative w-1/4 md:w-1/4 mr-1">
                            <div className="">
                                <Menu.Button className="w-full shadow-sm flex items-center space-x-2 justify-center text-blue-900 bg-white py-3 md:py-2 border rounded-lg md:text-lg font-bold uppercase font-semibold">
                                    <img
                                        src={`/images/flags/${coin.img_rounded}`}
                                        className="rounded-full h-6 w-6"
                                        alt=""
                                    />
                                    <span>{coin.currency}</span>
                                    <HiChevronRight
                                        className={`transition-all duration-300 ${
                                            open ? "rotate-90" : ""
                                        } h-6 w-6`}
                                    />
                                </Menu.Button>

                                <Transition
                                    enter="transition duration-100 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-75 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0"
                                >
                                    <Menu.Items>
                                        <div className="right-0 z-10 px-2 py-1 mt-1 w-44 text-gray-600 bg-white border rounded-md shadow">
                                            {moedas.map((item, index) => (
                                                <Menu.Item
                                                    key={index}
                                                    as={"button"}
                                                    type="button"
                                                    className="flex items-center space-x-3 px-3 py-2 text-sm hover:text-blue-600"
                                                    onClick={() => setCoin(item)}
                                                >
                                                    <img
                                                        src={`/images/flags/${item.img_rounded}`}
                                                        className="rounded-full h-6 w-6"
                                                        alt=""
                                                    />
                                                    <span>{item.currency}</span>
                                                </Menu.Item>
                                            ))}
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </div>
                        )}
                    </Menu>
                    {formErrors.moeda_remessa && (
                        <p className="text-red-500 text-xs mt-1">{formErrors.moeda_remessa}</p>
                    )}
                </div>
                <div className="col-span-5">
                    <label htmlFor="valor_remessa" className="block text-gray-700">
                        Valor Remessa: <Required />
                    </label>
                    <InputNumber
                        id="valor_remessa"
                        value={formData.valor_remessa}
                        name="valor_remessa"
                        onValueChange={handleChange}
                        inputMode="text"
                        type="text"
                        autoComplete="nope"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        className="w-full"
                        inputClassName={`mt-2 p-2 w-full border rounded-md ${formErrors.renda ? "border-red-500" : ""}`}
                    />
                    {formErrors.valor_remessa && (
                        <p className="text-red-500 text-xs mt-1">{formErrors.valor_remessa}</p>
                    )}
                </div>
            </div>
            <hr className="mt-8 mb-2" />
            <Button
                type="button"
                onClick={handleSubmit}
                color="primary"
                className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-200"
            >
                {loading && <Loader color={"white"} />}
                Enviar respostas
            </Button>
        </form>
    );
};

export default Form;
