import ContactForm from "../components/ContactForm";
import { Container } from "../components/utils";

const Banner = ({ parceiro }) => {
    return (
        <section className="w-full overflow-hidden bg-[#f2f3f9] wow fadeInUp">
            <div
                className="relative bg-auto bg-no-repeat bg-[top_120px_right] md:bg-[top_70px_right] lg:bg-[top_100px_right]"
                style={{ backgroundImage: "url('/images/banner-map.png')" }}
            >
                <div>
                    <img
                        className="w-[15%] lg:w-[25%] xl:max-w-full h-auto align-middle absolute left-[calc(12%)] bottom-[2%] animate-ripple2"
                        src="/images/banner-box.png"
                        alt=""
                    />
                    <img
                        className="hidden md:block w-[25%] lg:w-[15%] xl:w-[19%] h-auto align-middle absolute top-0 left-0"
                        src="/images/banner-clock.png"
                        alt=""
                    />
                    <img
                        className="w-[8%] xl:w-[11%] h-auto align-middle absolute left-[calc(23%)] bottom-[40%] animate-ripple2"
                        src="/images/banner-wallet.png"
                        alt=""
                    />
                    <img
                        className="block w-[12%] xl:w-[20%] h-auto align-middle absolute left-[calc(45%)] bottom-[calc(22%)] animate-ripple"
                        src="/images/banner-human.png"
                        alt=""
                    />
                </div>

                <div className="pt-[150px] pb-[250px] xl:pb-[200px]">
                    <Container className="pt-5 flex flex-col md:flex-row md:items-top space-y-5 md:space-y-0 md:space-x-4 lg:space-x-0 lg:justify-between relative z-10">
                        <img
                            src="/images/banner-rocket.png"
                            className="hidden z-1 lg:block lg:w-[11%] opacity-70 absolute top-[15%] left-[40%] xl:left-[50%] animate-ripple"
                            alt=""
                        />
                        <div className="z-50 pr-5 lg:pr-10 xl:pr-20">
                            {/* <h5 className="font-bold lg:text-xl xl:text-2xl text-blue-700 mb-3">
                                Solução completa para câmbio e remessa online
                            </h5> */}
                            <h1 className="text-4xl lg:text-5xl xl:text-6xl text-blue-900">
                                <span className="font-bold">Envie e Receba</span>
                                <br className="hidden lg:block" /> dinheiro do exterior{" "}
                                <br className="hidden lg:block" />
                                <span className="font-bold">
                                    com a menor
                                    <br className="hidden xl:block" /> cotação do mercado!
                                </span>
                            </h1>
                            <p className="text-xl xl:text-2xl text-blue-900 mt-8 mb-8">
                                Solução completa para
                                <span className="font-bold"> câmbio </span> e
                                <span className="font-bold"> remessa online.</span>
                            </p>

                            <div className="flex items-center space-x-4 mb-8">
                                {/* {!checkUser() && (
                                    <Link
                                        to="/register"
                                        className="lg:text-lg truncate transition-all duration-300 px-3 lg:px-4 xl:px-8 font-medium py-3 bg-blue-950 text-white rounded-md focus:outline-none hover:bg-blue-700 focus:ring focus:border-blue-500 focus:ring-blue-500/50"
                                    >
                                        Abrir Conta 100% Gratuita
                                    </Link>
                                )} */}

                                <a
                                    href="#about-us"
                                    className="text-base truncate transition-all duration-300 px-3 py-1 font-medium text-blue-400 border border-blue-200 rounded-md"
                                >
                                    Saiba mais
                                </a>
                            </div>
                        </div>

                        <div className="bg-white z-10 border border-gray-100 rounded-lg shadow-xl p-4 md:p-5 w-full md:w-1/2 lg:w-[50%] xl:w-[40%]">
                            <ContactForm parceiro={parceiro} />
                            {/* <TransferForm /> */}
                        </div>
                    </Container>
                </div>
            </div>
        </section>
    );
};

export default Banner;
