import config from "../config";

export function getOffset(el) {
    const rect = el.getBoundingClientRect();
    return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY
    };
}

export const toast = (type, message, confirmButton = false, timer = 3000) => {
    (function () {
        const Toast = window.Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: confirmButton,
            timer: timer,
            timerProgressBar: true,
            didOpen: toast => {
                toast.addEventListener("mouseenter", window.Swal.stopTimer);
                toast.addEventListener("mouseleave", window.Swal.resumeTimer);
            }
        });
        Toast.fire({
            icon: type,
            title: message
        });
    })();
};

export const confirmAlert = (executing, confirmButtonText = "Yes, delete!") => {
    window.Swal.fire({
        title: "Are you sure?",
        text: "You can't go back !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ef4444",
        cancelButtonColor: "#52525b",
        confirmButtonText: confirmButtonText,
        cancelButtonText: "Cancel",
        showLoaderOnConfirm: true,
        preConfirm: executing
    }).then(result => {
        if (result.isConfirmed) {
            // toast('success', "Succès de la suppression");
        }
    });
};

export const checkPermissions = (permissions = []) => {
    let found = false;
    const userPermissions = ["add", "update", "delete"];
    if (userPermissions) {
        permissions.forEach(item => {
            found = found || userPermissions.includes(item);
        });
    }
    return found;
};

export const scrollTop = () => {
    const element = document.getElementById("top-page");
    if (element) {
        const position = getOffset(element);
        window.scrollTo(position.left, 0);
    }
};

export const formatToCurrency = value => {
    return new Intl.NumberFormat("de-DE", { style: "currency", currency: "XOF" }).format(
        parseFloat(value)
    );
};

export const checkUser = () => {
    return !!config.AUTH.DRIVER.getItem("user");
};

export const logout = navigate => {
    config.AUTH.DRIVER.removeItem("user");
    navigate("/", { replace: true });
    toast("success", "Successful logout");
};

export const moedas = [
    {
        text: "United_States",
        img: "United_States.svg",
        img_rounded: "United_States_Rounded.png",
        currency: "USD"
    },
    {
        text: "Australia",
        img: "Australia.svg",
        img_rounded: "Australia_Rounded.png",
        currency: "AUD"
    },
    {
        text: "Canada",
        img: "Canada.svg",
        img_rounded: "Canada_Rounded.png",
        currency: "CAD"
    },
    {
        text: "European_Union",
        img: "European_Union.svg",
        img_rounded: "European_Union_Rounded.png",
        currency: "EUR"
    },
    {
        text: "Japan",
        img: "Japan.svg",
        img_rounded: "Japan_Rounded.png",
        currency: "JPY"
    },
    {
        text: "United_Kingdom",
        img: "United_Kingdom.svg",
        img_rounded: "United_Kingdom_Rounded.png",
        currency: "GBP"
    },
    {
        text: "Mexico",
        img: "Mexico.svg",
        img_rounded: "Mexico_Rounded.png",
        currency: "MXN"
    }
];

export const motivos = {
    ENVIAR: {
        FISICA: [
            { valor: "DISP", texto: "Transferência para minha própria conta no exterior" },
            { valor: "MNRE", texto: "Transferência para um familiar ou amigo" },
            { valor: "IMPO", texto: "Pagamento de um produto importado" },
            { valor: "SERV", texto: "Pagamento de serviço, curso ou tratamento de saúde" }
        ],
        JURIDICA: [
            { valor: "DISP", texto: "Envio para a conta da empresa no exterior" },
            { valor: "IMPO", texto: "Pagamento de um produto importado" },
            { valor: "SERV", texto: "Pagamento de serviço contratado" },
            { valor: "CAPI", texto: "Aumento de capital no exterior" }
        ]
    },
    RECEBER: {
        FISICA: [
            { valor: "DISP", texto: "Transferência para minha própria conta no Brasil" },
            { valor: "MNRE", texto: "Transferência de um familiar ou amigo" },
            { valor: "SERV", texto: "Recebimento de Ads ou outros serviços" },
            { valor: "EXPO", texto: "Recebimento de um produto exportado" }
        ],
        JURIDICA: [
            { valor: "DISP", texto: "Disponibilidade no Brasil" },
            { valor: "EXPO", texto: "Recebimento de um produto exportado" },
            { valor: "SERV", texto: "Recebimento por um serviço prestado" }
        ]
    }
};

export const operacoes = ["ENVIAR", "RECEBER"];

export const tipos_pessoa = ["FISICA", "JURIDICA"];

export const msg_padrao = formDataTemp => {
    const operacao = formDataTemp.operacao;
    const tipoPessoa = formDataTemp.tipoPessoa;
    const moeda = formDataTemp.moeda;
    const quantia = formDataTemp.quantia;
    const motivo = formDataTemp.motivo;
    const nomeEmpresa = formDataTemp.nomeEmpresa;
    const nome = formDataTemp.nome;
    const email = formDataTemp.email;
    const telefone = formDataTemp.telefone;

    return `Olá, meu nome é ${nome}. Gostaria de solicitar a cotação da remessa abaixo:

*Tipo de Remessa:* ${operacao}
Remessa será feita por uma pessoa *${tipoPessoa}*
*Moeda:* ${moeda} | *Quantidade:* ${quantia}
*Motivo:* ${motivo}

*Informações de Contato*
Empresa: ${nomeEmpresa}
Email: ${email}
Telefone: ${telefone}        
`;
};

export const msg_parceiro = formDataTemp => {
    const operacao = formDataTemp.operacao;
    const tipoPessoa = formDataTemp.tipoPessoa;
    const moeda = formDataTemp.moeda;
    const quantia = formDataTemp.quantia;
    const motivo = formDataTemp.motivo;
    const nomeEmpresa = formDataTemp.nomeEmpresa;
    const nome = formDataTemp.nome;
    const email = formDataTemp.email;
    const telefone = formDataTemp.telefone;
    const parceiro = formDataTemp.parceiro;

    return `Olá, meu nome é ${nome}.

${parceiro.mensagem}

*Tipo de Remessa:* ${operacao}
Remessa será feita por uma pessoa *${tipoPessoa}*
*Moeda:* ${moeda} | *Quantidade:* ${quantia}
*Motivo:* ${motivo}

*Informações de Contato*
Empresa: ${nomeEmpresa}
Email: ${email}
Telefone: ${telefone}
`;
};

export function CamelCase({ className, text }) {
    // Função que converte o texto para CamelCase
    const toCamelCase = str => {
        return str
            .split(" ") // Divide a string em um array de palavras
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitaliza a primeira letra de cada palavra
            .join(" "); // Junta as palavras de volta em uma string, sem espaços
    };

    // Aplica a função de conversão ao texto passado como prop
    const camelCaseText = toCamelCase(text);

    return <span className={className}>{camelCaseText}</span>;
}

export const maskDocument = (v, type) => {
    if (type == "CPF") {
        v = v.replace(/\D/g, "");
        v = v.substring(0, 11);
        v = v.replace(/(\d{3})(\d)/, "$1.$2");
        v = v.replace(/(\d{3})(\d)/, "$1.$2");
        v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
        v = v.replace(/(-\d{2})\d+?$/, "$1");
    }
    if (type == "CNPJ") {
        v = v.replace(/\D/g, "");
        v = v.substring(0, 14);
        v = v.replace(/^(\d{2})(\d)/, "$1.$2");
        v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
        v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
        v = v.replace(/(\d{4})(\d)/, "$1-$2");
    }
    // Remove tudo que não é dígito ou X (para RG)
    if (type === "RG") {
        v = v.substring(0, 12).toUpperCase(); // Converte para maiúsculas
        // Remove caracteres inválidos, mantendo dígitos e 'X'
        v = v.replace(/[^0-9X]/gi, "");
        // Trata a inserção do 'X' para que ele só possa ser inserido como último caractere
        if (v.includes("X")) {
            const parts = v.split("X");
            v = parts[0]; // Pega tudo antes do 'X'
            if (parts[0].length === 8) {
                // Se já tem 8 dígitos, pode adicionar 'X' no final
                v += "X";
            }
        }

        // Aplica a máscara progressivamente
        v = v.replace(/(\d{2})(\d)/, "$1.$2"); // Insere o primeiro ponto
        v = v.replace(/(\d{2})\.(\d{3})(\d)/, "$1.$2.$3"); // Insere o segundo ponto
        v = v.replace(/(\d{2})\.(\d{3})\.(\d{3})([0-9X])/, "$1.$2.$3-$4"); // Insere o traço antes do dígito verificador

        // Aplica a máscara enquanto digita
        v = v.replace(/(\d{2})(\d{3})(\d{3})([0-9X]?)/, "$1.$2.$3-$4").substring(0, 12);
    } else if (type === "CNH") {
        v = v.replace(/\D/g, "");
        // Para CNH, remove tudo que não é dígito
        v = v.replace(/\D/g, "");
        // Limita o tamanho para se adequar ao padrão da CNH
        v = v.substring(0, 11);
    }

    return v;
};

export const maskDate = value => {
    // Remove tudo o que não é dígito
    value = value.replace(/\D/g, "");

    // Limita o tamanho máximo da string para 8 caracteres (ddmmyyyy)
    value = value.substring(0, 8);

    // Aplica a máscara de formatação dd/mm/aaaa
    // Insere a primeira barra após o dia (dois dígitos)
    value = value.replace(/(\d{2})(\d)/, "$1/$2");
    // Insere a segunda barra após o mês (quatro dígitos no total, incluindo o dia e a primeira barra)
    // Ajuste para evitar a inserção de barras extras
    value = value.replace(/(\d{2}\/\d{2})(\d)/, "$1/$2");

    return value;
};
